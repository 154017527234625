import React, { useContext } from "react";
import { SnackbarContext } from "../Context/SnackbarContext";

const Snackbar = () => {
  const { submitMessage } = useContext(SnackbarContext);
  console.log();
  return (
    <>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Snackbar;
