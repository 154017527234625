import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import secureLocalStorage from "react-secure-storage";

export const RequestedJobOpeningContext = createContext(null);

function RequestedJobOpeningProvider({ children }) {
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  // requested requisition for the particular user to display.
  const [isRequisitionByUserData, setIsRequisitionByUserData] = useState(false);
  const [requisitionByUserData, setRequisitionByUserData] = useState([]);
  const [requisitionByUserEntries, setRequisitionByUserEntries] = useState(5);
  const [requisitionByUserCount, setRequisitionByUserCount] = useState(null);
  const [requisitionByUserPageCount, setRequisitionByUserPageCount] =
    useState(0);
  const [isRequisitionByUserLoading, setIsRequisitionByUserLoading] =
    useState(true);

  // requisition to display.
  const [isData, setIsData] = useState(false);
  const [rawRequestedJob, setRawRequestedJob] = useState([]);
  const [requestedEntries, setRequestedEntries] = useState(5);
  const [requestedCount, setRequestedCount] = useState(null);
  const [requestPageCount, setRequestedPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // all requisition data to display user
  const [isRequisitionData, setIsRequisitionData] = useState(false);
  const [rawRequisitionData, setRawRequisitionData] = useState([]);
  console.log(rawRequisitionData);
  const [requisitionEntries, setRequisitionEntries] = useState(5);
  const [requisitionCount, setRequisitionCount] = useState(null);
  const [requisitionPageCount, setRequisitionPageCount] = useState(0);
  const [isRequisitionLoading, setIsRequisitionLoading] = useState(true);

  // requisition data to display to RC
  const [rawRCRequisitionData, setRawRCRequisitionData] = useState([]);
  console.log(rawRCRequisitionData);
  const [isRCRequisitionData, setIsRCRequisitionData] = useState(false);
  const [RCRequisitionEntries, setRCRequisitionEntries] = useState(5);
  const [RCRequisitionCount, setRCRequisitionCount] = useState(0);
  const [RCRequisitionpageCount, setRCRequisitionpageCount] = useState(0);
  const [isRCRequisitionLoading, setisRCRequisitionLoading] = useState(true);

  // To view all requisition and with filter of accepted/rejected
  const [selectedRequisitionStatus, setSelectedRequisitionStatus] =
    useState(null);

  const [isAllRequistionData, setIsAllRequistionData] = useState(false);
  const [rawAllRequistionData, setRawAllRequistionData] = useState([]);
  const [allRequisitionEntries, setAllRequistionEntries] = useState(5);
  const [allRequisitionCount, setAllRequisitionCount] = useState(null);
  const [allRequistionPageCount, setAllRequisitionPageCount] = useState(0);
  const [isAllRequisitionLoading, setIsAllRequisitionLoading] = useState(false);

  const [allRequisitionData, setAllRequisitionData] = useState([]);

  // "getRequisitionByUserData" API call to get requisition of that particular user
  const getRequisitionByUserData = async (page, searchRequest) => {
    setIsRequisitionByUserLoading(true);
    console.log("page", page);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requested_user_id=${userID}&entries=${requisitionByUserEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requested_user_id=${userID}&entries=${requisitionByUserEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRequisitionByUserLoading(false);
      if (response.length !== 0) {
        setIsRequisitionByUserData(false);
        setRequisitionByUserData(res.data.data);
      } else {
        setIsRequisitionByUserData(true);
        setRequisitionByUserData(res.data.data);
      }
      const total = res.data.count;
      setRequisitionByUserCount(total);
      setRequisitionByUserPageCount(
        Math.ceil(total / requisitionByUserEntries)
      );
    } catch (error) {
      console.log(error);
      setIsRequisitionByUserLoading(false);
      setIsRequisitionByUserData(true);
    }
  };

  const handleRequisitionByUserPageClick = async (data, searchRequest) => {
    console.log(data);
    console.log(searchRequest);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRequisitionByUserData(
      currentPage,
      searchRequest
    );
  };

  // "getRawRequestedJobData" API call to get requisitions data
  const getRawRequestedJobData = async (page, searchRequest) => {
    setIsLoading(true);
    console.log("page", page);
    console.log(searchRequest);
    let url;
    if (searchRequest) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${requestedEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${requestedEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsLoading(false);
      if (response.length !== 0) {
        setIsData(false);
        setRawRequestedJob(res.data.data);
      } else {
        setIsData(true);
        setRawRequestedJob(res.data.data);
      }
      const total = res.data.count;
      setRequestedCount(total);
      setRequestedPageCount(Math.ceil(total / requestedEntries));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsData(true);
    }
  };

  const handlePageClick = async (data, searchRequest) => {
    console.log(data);
    console.log(searchRequest);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRawRequestedJobData(
      currentPage,
      searchRequest
    );
  };

  // "getRequestedRequisitionData" API call to get requisition of all managers
  const getRequestedRequisitionData = async (page, searchRequest) => {
    setIsRequisitionLoading(true);
    console.log(searchRequest);
    let url;
    if (searchRequest !== undefined) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requisition_acceptance=null&entries=${requisitionEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?requisition_acceptance=null&entries=${requisitionEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRequisitionLoading(false);
      if (response.length !== 0) {
        setIsRequisitionData(false);
        setRawRequisitionData(res.data.data);
      } else {
        setIsRequisitionData(true);
        setRawRequisitionData(res.data.data);
      }
      const total = res.data.count;
      setRequisitionCount(total);
      setRequisitionPageCount(Math.ceil(total / requisitionEntries));
    } catch (error) {
      console.log(error);
      setIsRequisitionLoading(false);
      setIsRequisitionData(true);
    }
  };

  const handleRequisitionPageClick = async (data, searchRequest) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRequestedRequisitionData(
      currentPage,
      searchRequest
    );
  };

  // getRCRequisitionData API call to get requisition for RC
  const getRCRequisitionData = async (page, searchRequest) => {
    setisRCRequisitionLoading(true);
    console.log(searchRequest);
    let url;
    if (searchRequest !== undefined) {
      url =
        VARIABLES.url +
        `/api/associateuser/?user=${userID}&associated_type=rc&entries=${RCRequisitionEntries}&page=${page}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/associateuser/?user=${userID}&associated_type=rc&entries=${RCRequisitionEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      console.log(response);
      setisRCRequisitionLoading(false);
      if (response.length !== 0) {
        setIsRCRequisitionData(false);
        setRawRCRequisitionData(res.data.data);
      } else {
        setIsRCRequisitionData(true);
        setRawRCRequisitionData(res.data.data);
      }
      const total = res.data.count;
      setRCRequisitionCount(total);
      setRCRequisitionpageCount(Math.ceil(total / RCRequisitionEntries));
    } catch (error) {
      console.log(error);
      setisRCRequisitionLoading(false);
      setIsRCRequisitionData(true);
    }
  };

  const handleRCRequisitionPageClick = async (data, searchRequest) => {
    console.log(data.selected);

    let page = data.selected + 1;

    const commentsFormServer = await getRCRequisitionData(page, searchRequest);
  };

  // To view all requisition and with filter of accepted/rejected
  const getAllRequistion = async (page) => {
    setIsAllRequisitionLoading(true);
    let url;
    if (selectedRequisitionStatus && selectedRequisitionStatus.value !== null) {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${allRequisitionEntries}&page=${page}&requisition_acceptance=${selectedRequisitionStatus.value}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requestrequisition/?entries=${allRequisitionEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsAllRequisitionLoading(false);
      if (response.length !== 0) {
        setIsAllRequistionData(false);
        setRawAllRequistionData(res.data.data);
      } else {
        setIsAllRequistionData(true);
      }
      const total = res.data.count;
      setAllRequisitionCount(total);
      setAllRequisitionPageCount(Math.ceil(total / allRequisitionEntries));
    } catch (error) {
      console.log(error);
      setIsAllRequisitionLoading(false);
      setIsAllRequistionData(true);
    }
  };

  const handleQuestionClick = async (data) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAllRequistion(currentPage);
  };

  const getRequisition = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/requestrequisition/");
      console.log(res.data.data);
      setAllRequisitionData(
        res.data.data.map((pro) => ({
          value: pro.id,
          label: pro.job_opening?.job_title,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   secureLocalStorage.setItem("pagination",page)
  // },[page])

  return (
    <div>
      <RequestedJobOpeningContext.Provider
        value={{
          requisitionByUserData,
          getRequisitionByUserData,
          requisitionByUserEntries,
          requisitionByUserPageCount,
          setRequisitionByUserEntries,
          requisitionByUserCount,
          handleRequisitionByUserPageClick,
          isRequisitionByUserData,
          isRequisitionByUserLoading,

          rawRequestedJob,
          getRawRequestedJobData,
          requestedEntries,
          requestPageCount,
          setRequestedEntries,
          requestedCount,
          handlePageClick,
          isData,
          isLoading,

          rawRequisitionData,

          rawRCRequisitionData,
          getRCRequisitionData,
          RCRequisitionEntries,
          RCRequisitionpageCount,
          setRCRequisitionEntries,
          RCRequisitionCount,
          handleRCRequisitionPageClick,
          isRCRequisitionData,
          isRCRequisitionLoading,

          getRequestedRequisitionData,
          requisitionEntries,
          requisitionPageCount,
          setRequisitionEntries,
          requisitionCount,
          handleRequisitionPageClick,
          isRequisitionData,
          isRequisitionLoading,

          setSelectedRequisitionStatus,
          selectedRequisitionStatus,
          isAllRequistionData,
          rawAllRequistionData,
          getAllRequistion,
          allRequisitionEntries,
          allRequistionPageCount,
          setAllRequistionEntries,
          allRequisitionCount,
          handleQuestionClick,
          isAllRequisitionLoading,

          getRequisition,
          allRequisitionData,
        }}
      >
        {children}
      </RequestedJobOpeningContext.Provider>
    </div>
  );
}

export default RequestedJobOpeningProvider;
