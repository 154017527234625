import React, { useState } from 'react'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SequenceContext } from './SequenceContextProvider';

const DefaultForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

    const { level, sequenceList, setSequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

    const submitForm = () => {
      console.log("submited");
      navigate("/")
    };

  return (
    <>
    <div>Form not rendered due to privilege restrictions.</div>
    <div className="container-fluid mt-5">
                {/* Render Prev button only if there are tabs before */}
                {level !== 0 && (
                  <button className="btn btn-secondary me-3" id='button-button-prevlevel' onClick={() => prevLevel()}>
                    Prev
                  </button>
                )}

                {/* If there are more tabs then show next
         If it is the final tab then show submit button */}
                {sequenceList.length - 1 > level ? (
                  <button className="btn btn-secondary" id='button-button-nextlevel' onClick={() => nextLevel()}>
                    Next
                  </button>
                ) : (
                  <button className="btn btn-secondary" id='submit-button-defaultform' onClick={submitForm}>
                    Submit
                  </button>
                )}
              </div>
    </>
  )
}

export default DefaultForm