import React, { useEffect, useState, useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { CandidateEntriesContext } from "../../Context/CandidateEntriesContext";
import { useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import moment from "moment";

const CandidateOtherDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location)
  const userID = secureLocalStorage.getItem("userID");

  const {
    getCandidateOtherData,
    candidateOtherDetails,
    isDetailsLoading,
    isDetailsData
  } = useContext(CandidateEntriesContext);


  const handleResumeCount = () => {
    viewedResume()
  }

  const viewedResume = async () => {
    let url = VARIABLES.url +
      `/update-views?candidate_job_id=${location?.state?.editpost?.id}&user_id=${userID}`;

    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {},
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {

    getCandidateOtherData(location?.state?.editpost?.id);
  }, [location]);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            {isDetailsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                <div className="card-inner">
                  <div className="nk-block">
                    <div className="nk-block-head">
                      <h5 className="title">
                        Personal Details
                      </h5>
                    </div>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Candidate Name</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.first_name && candidateOtherDetails?.personal_details?.first_name}
                            {" "}

                            {candidateOtherDetails?.personal_details?.middle_name && candidateOtherDetails?.personal_details?.middle_name}
                            {" "}

                            {candidateOtherDetails?.personal_details?.last_name &&
                              candidateOtherDetails?.personal_details?.last_name}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Email</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.email || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Mobile Number</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.mobile || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Gender</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.gender || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Current Location</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.current_location || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Date of Birth</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.date_of_birth || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Attachment</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.attachment ? <a href={candidateOtherDetails?.personal_details?.attachment} onClick={() => handleResumeCount()} class="caption-text text-break"> View Attachment </a> : "-"}
                          </span>
                        </div>
                        {
                          candidateOtherDetails?.other_details?.[0]?.resume_viewed_count > 0 ?
                            <>
                              <p className="text-muted small">
                                Last viewed by: {candidateOtherDetails?.other_details?.[0]?.resume_viewed_by}
                              </p>
                              <p className="text-muted small">
                                Last viewed at: {moment(candidateOtherDetails?.other_details?.[0]?.resume_viewed_at).format(
                                  "YYYY-MM-DD hh:mm"
                                )}
                              </p>
                            </> : ""
                        }

                        <p className="text-muted small">
                          Resume viewed {candidateOtherDetails?.other_details?.[0]?.resume_viewed_count} {candidateOtherDetails?.other_details?.[0]?.resume_viewed_count == 1 ? "time" : "times"}
                        </p>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Skills</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.skills || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Instagram Url</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.instagram_url !== "null" ? <a href={candidateOtherDetails?.personal_details?.instagram_url} class="caption-text text-break"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>

                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Twitter Link
                          </span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.twitter_url !== "null" ? <a href={candidateOtherDetails?.personal_details?.twitter_url} class="caption-text text-break"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Facebook Link
                          </span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.facebook_url !== "null" ? <a href={candidateOtherDetails?.personal_details?.facebook_url} class="caption-text text-break"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Linkedin Link</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.personal_details?.linkedin_url ? <a href={candidateOtherDetails?.personal_details?.linkedin_url} class="caption-text text-break"> Link </a> : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-bottom my-4" />


                  <div className="nk-block">
                    <div className="nk-block-head">
                      <h5 className="title">
                        Professional Details
                      </h5>
                    </div>
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Notice Period</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.notice_period + " " + "days" || "-"}
                          </span>
                        </div>
                      </div>
                      {/* <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Current/ Previous Company</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.current_previous_company || "-"}
                          </span>
                        </div>
                      </div> */}
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Last Drawn CTC</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.current_salary === null ? "No input from user" : candidateOtherDetails?.other_details?.[0]?.current_salary + " " + "lakhs per annum"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Expected CTC</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.expected_salary === null ? "No input from user" : + " " + "lakhs per annum"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Total Work Experience</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.experience + " " + "years" || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Total Relevant Experience</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.relevant_experience ? candidateOtherDetails?.other_details?.[0]?.relevant_experience + " " + "years" : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom my-4" />
                    {candidateOtherDetails?.professional_details?.length > 0 && (
                      candidateOtherDetails?.professional_details?.map((prof, index) => (
                        <>
                          <div className="py-4 border-css">
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Previously Worked
                                  </span>
                                  <span className="profile-ud-value">
                                    {prof?.previously_worked || "-"}
                                  </span>
                                </div>
                              </div>
                              {prof?.previously_worked !== "No" && (
                                <>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Designation
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.profile_name || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Organization Name
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.organization_name || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Date of Joining
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.start_date || "-"}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Last Working Day
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.end_date || "-"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )))}
                  </div>

                  <div className="border-bottom my-4" />


                  <div className="nk-block">
                    <div className="nk-block-head">
                      <h5 className="title">
                        Educational Details
                      </h5>
                    </div>
                    {candidateOtherDetails?.educational_details?.length == 0 && (
                      <div className="profile-ud-list ">
                        <div className="profile-ud-item">
                          <span className="profile-ud-label">
                            Educational Details Not Found
                          </span>
                        </div>
                      </div>
                    )}
                    {candidateOtherDetails?.educational_details?.length > 0 && (
                      candidateOtherDetails?.educational_details?.map((edu, index) => (
                        <div className="py-4 border-css">
                          <div className="profile-ud-list ">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Board Name
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.board_name || "-"}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Passing Year
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.passing_year || "-"}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Percentage
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.percentage || "-"}
                                </span>
                              </div>
                            </div>


                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Marksheet
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.marksheet ? <a target="_blank" href={`https://fsc-rms-prod.s3.ap-southeast-1.amazonaws.com/media/${edu?.marksheet}`} class="caption-text text-break"> View Marksheet </a> : "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Qualification
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.education_type.label || "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Specialization
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.specialization || "-"}
                                </span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  Comments
                                </span>
                                <span className="profile-ud-value">
                                  {edu?.comments || "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )))}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CandidateOtherDetails