import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
// import { BrowserRouter } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import Base from './Components/Base/Base'
import Login from './Components/Login/Login'
import { useNavigate } from 'react-router-dom';

const App = () => {
  const [isLoggedIn,setIsLoggedIn] = useState(false)
  console.log(isLoggedIn)

  const userID = secureLocalStorage.getItem('userID');
  console.log("userID:", userID)

  const navigate = useNavigate();

    // Set values in localstorage, userData and isLoggedIn after successful login
    const loginHandler = (data) => {
      console.log(data)
      secureLocalStorage.setItem('isLoggedIn','USER_LOGGED_IN');
      secureLocalStorage.setItem('userID',data.user.id);
      secureLocalStorage.setItem('username',data.user.username);
      secureLocalStorage.setItem('firstName',data.user.first_name);
      secureLocalStorage.setItem('lastName',data.user.last_name);
      secureLocalStorage.setItem('email',data.user.email);
      const loginType = data.login_types.map((ty) => {return {value : ty.id ,label : ty.name}})
      secureLocalStorage.setItem('login_type',loginType);
      const role = data.role[0]
      secureLocalStorage.setItem('role',role);
      secureLocalStorage.setItem('privileges',data.privilege_list);
      // secureLocalStorage.setItem('access',data.access);
      // secureLocalStorage.setItem('refresh',data.refresh);
      setIsLoggedIn(true);

      const userRole = role?.role_name

      if (userRole) {
        // Redirect the user to the appropriate landing page based on role
        switch (userRole) {
          case 'Superadmin':
            navigate('/');
            break;
          case 'MD':
            navigate('/');
            break;
          case 'Departmental Manager':
            navigate('/');
            break;
          case 'HR Director':
            navigate('/requestacceptance');
            break;
          case 'TAM':
            navigate('/tam');
            break;
          case 'RE1':
            navigate('/re1');
            break;
          case 'RE2':
            navigate('/re2');
            break;
          case 'RE3':
            navigate('/re3');
            break;
          case 'RC/HR Exec. Manager':
            navigate('/recruitmentcordinator');
            break;
          case 'Interviewer/SME':
            navigate('/interviewersme');
            break;
          case 'SEO':
            navigate('/seo');
            break;
          case 'Master':
            navigate('/usermanagement');
            break;
          default:
            navigate('/')
            break;
        }
      }
    };

    const logoutHandler = () => {
      secureLocalStorage.removeItem('RWFcandidate_job_id');
      secureLocalStorage.removeItem('isLoggedIn', 'USER_LOGGED_OUT')
      secureLocalStorage.removeItem('loggedId');
      secureLocalStorage.removeItem('userID');
      secureLocalStorage.removeItem('username');
      secureLocalStorage.removeItem('firstName');
      secureLocalStorage.removeItem('lastName');
      secureLocalStorage.removeItem('email');
      secureLocalStorage.removeItem('login_type')
      secureLocalStorage.removeItem('privileges')
      secureLocalStorage.removeItem('workflowid')
      secureLocalStorage.removeItem('jobOpeningid')
      secureLocalStorage.removeItem('role')
      // secureLocalStorage.removeItem('access')
      // secureLocalStorage.removeItem('refresh')
      
      setIsLoggedIn(false);
    };
  
  useEffect(()=>{
    const userStatus = secureLocalStorage.getItem('isLoggedIn');
    if(userStatus==='USER_LOGGED_IN'){
      setIsLoggedIn(true);
    }else if(userStatus==='USER_LOGGED_OUT'){
      setIsLoggedIn(false);
    }
  },[])

  return (
    <>
    {/* <BrowserRouter> */}
      {isLoggedIn && <Base logoutHandler={logoutHandler} />}
      {!isLoggedIn && <Login loginHandler={loginHandler} />}
    {/* </BrowserRouter> */}
    </>
  )
}

export default App
