import axios from "axios";
import React, { useState, createContext } from "react";
import { VARIABLES } from "../Constants";

export const CandidateResumeContext = createContext(null);

function CandidateResumeProvider({ children }) {
  // To store Candidate Resume Data.
  const [isResumeData, setIsResumeData] = useState(false);
  const [rawCandidateResume, setRawCandidateResume] = useState([]);
  console.log(rawCandidateResume)
  const [candidateResumeEntries, setCandidateResumeEntries] = useState(5);
  const [candidateResumeCount, setCandidateResumeCount] = useState(null);
  const [candidateResumePageCount, setCandidateResumePageCount] = useState(0);
  const [isResumeLoading, setIsResumeLoading] = useState(false);

  // To store Pending candidate Resume Data.
  const [isPendingResumeData, setIsPendingResumeData] = useState(false);
  const [rawPendingCandidateResume, setRawPendingCandidateResume] = useState(
    []
  );
  const [pendingCandidateResumeEntries, setPendingCandidateResumeEntries] =
    useState(5);
  const [pendingCandidateResumeCount, setPendingCandidateResumeCount] =
    useState(null);
  const [pendingCandidateResumePageCount, setPendingCandidateResumePageCount] =
    useState(0);
  const [isPendingResumeLoading, setIsPendingResumeLoading] = useState(false);

  // candidate selected By RC are stored in "rawAcceptedCandidateDetails"
  const [isCandidateData, setIsCandidateData] = useState(false);
  const [rawAcceptedCandidateDetails, setRawAcceptedCandidateDetails] =
    useState([]);
  console.log(rawAcceptedCandidateDetails);
  const [candidateEntries, setCandidateEntries] = useState(5);
  const [candidateCount, setCandidateCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);

  // "getCandidateResume" API call to get resume data
  const getCandidateResume = async (page, searchResume) => {
    setIsResumeLoading(true);
    console.log(searchResume);
    let url;
    if (searchResume) {
      url =
        VARIABLES.url +
        `/api/candidateresume/?entries=${candidateResumeEntries}&page=${page}&event=${searchResume}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?entries=${candidateResumeEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsResumeLoading(false);
      if (response.length !== 0) {
        setIsResumeData(false);
        setRawCandidateResume(res.data.data);
      } else {
        setRawCandidateResume(res.data.data);
        setIsResumeData(true);
      }
      const total = res.data.count;
      setCandidateResumeCount(total);
      setCandidateResumePageCount(Math.ceil(total / candidateResumeEntries));
    } catch (error) {
      console.log(error);
      setIsResumeLoading(false);
      setIsResumeData(true);
    }
  };

  const handleCandidateResumeClick = async (data, searchResume) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateResume(
      currentPage,
      searchResume
    );
  };

  // "getPendingCandidateResume" API call to get resume data
  const getPendingCandidateResume = async (page, jobID, searchResume) => {
    setIsPendingResumeLoading(true);
    console.log(searchResume);
    let url;
    if (searchResume) {
      url =
        VARIABLES.url +
        `/api/candidateresume/?requisition_id=${jobID}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}&event=${searchResume}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?requisition_id=${jobID}&shortlisted_type=not_shortlisted&entries=${pendingCandidateResumeEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsPendingResumeLoading(false);
      if (response.length !== 0) {
        setIsPendingResumeData(false);
        setRawPendingCandidateResume(res.data.data);
      } else {
        setIsPendingResumeData(true);
        setRawPendingCandidateResume(res.data.data);
      }
      const total = res.data.count;
      setPendingCandidateResumeCount(total);
      setPendingCandidateResumePageCount(
        Math.ceil(total / pendingCandidateResumeEntries)
      );
    } catch (error) {
      console.log(error);
      setIsPendingResumeLoading(false);
      setIsPendingResumeData(true);
    }
  };

  const handlePendingCandidateResumeClick = async (data, searchResume) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getPendingCandidateResume(
      currentPage,
      searchResume
    );
  };

  const getAcceptedCandidateDetails = async (page, reqId, searchCandidate) => {
    setIsCandidateLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate) {
      url =
        VARIABLES.url +
        `/api/candidateresume/?shortlisted_type=rc_approved&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}&event=${searchCandidate}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?shortlisted_type=rc_approved&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsCandidateLoading(false);
      if (response.length !== 0) {
        setIsCandidateData(false);
        setRawAcceptedCandidateDetails(res.data.data);
      } else {
        setIsCandidateData(true);
        setRawAcceptedCandidateDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateLoading(false);
      setIsCandidateData(true);
    }
  };

  const handleAcceptCandidateClick = async (data, searchCandidate) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAcceptedCandidateDetails(
      currentPage,
      searchCandidate
    );
  };

  return (
    <div>
      <CandidateResumeContext.Provider
        value={{
          rawCandidateResume,
          getCandidateResume,
          candidateResumeEntries,
          candidateResumePageCount,
          setCandidateResumeEntries,
          candidateResumeCount,
          handleCandidateResumeClick,
          isResumeData,
          isResumeLoading,

          rawPendingCandidateResume,
          getPendingCandidateResume,
          pendingCandidateResumeEntries,
          pendingCandidateResumePageCount,
          setPendingCandidateResumeEntries,
          pendingCandidateResumeCount,
          handlePendingCandidateResumeClick,
          isPendingResumeData,
          isPendingResumeLoading,

          rawAcceptedCandidateDetails,
          getAcceptedCandidateDetails,
          candidateEntries,
          candidatePageCount,
          setCandidateEntries,
          candidateCount,
          handleAcceptCandidateClick,
          isCandidateData,
          isCandidateLoading,
        }}
      >
        {children}
      </CandidateResumeContext.Provider>
    </div>
  );
}

export default CandidateResumeProvider;
