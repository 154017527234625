import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { UserDetailsContext } from "../../Context/UserDetailsContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const AssignUserRole = (props) => {
  const { getUserDetailsData } = useContext(UserDetailsContext);
  const { roleDropdownData, isGenricDropdownDataLoading } = useContext(
    GenericDropdownContext
  );

  console.log(props?.roleData);
  console.log(props.roleData.id);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const onSubmit = (data) => {
    const roleIds = data.role.map((val) => val.value).join(",");
    console.log(roleIds);
    let user = new FormData();
    user.append("role_id", roleIds);
    user.append("updated_by_id", userID);

    const url = VARIABLES.url + `/api/userdetails/${props.roleData.user.id}/`;
    console.log(url);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      data: user,
    };

    console.log(user);

    setIsLoading(true);
    axios(config)
      .then((response) => {
        setIsLoading(false);
        console.log(JSON.stringify(response.data));
        getUserDetailsData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Role assigned successfully",
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to assign role",
        });
      });
    reset();
  };

  useEffect(() => {
    if (props.roleData.role) {
      var defaultValues = {};
      if (props.roleData.role.length !== 0) {
        const rawRoleData = props.roleData?.role?.map((info) => {
          return {
            value: info.id,
            label: info.role_name,
          };
        });
        defaultValues.role = rawRoleData;
      }
      reset(defaultValues);
    }
  }, [props.roleData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="roleedit"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {props?.roleData?.user?.first_name}
                {props?.roleData?.user?.last_name}
              </h5>
              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Role <span className="text-danger">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={roleDropdownData?.map((info) => ({
                              value: info.id,
                              label: info.role_name,
                            }))}
                            isLoading={isGenricDropdownDataLoading}
                            isClearable={true}
                            isMulti
                            id="select-forms-role"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="role"
                        control={control}
                      />
                      {errors.role && (
                        <span className="fs-8 text-danger">
                          Role is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {isLoading ? (
                  <button
                    id="button-loading"
                    className="btn text-dark"
                    disabled
                  >
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="submit-button-editrole"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AssignUserRole;
