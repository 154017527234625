import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const JobOpeningDetails = () => {
  let location = useLocation();
  console.log(location.state.editpost);

  const navigate = useNavigate();

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-end">
          <div class="nk-block-head-content">
            <a
              className="btn btn-light d-none d-sm-inline-flex"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                {/* <!-- .nav-tabs --> */}

                <div className="card-inner">
                  <div className="nk-block">
                    <div className="nk-block-head">
                      <h5 className="title">
                        {location.state?.editpost?.job_opening?.job_title ||
                          "-"}
                      </h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Job Type</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_type || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Department</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.department
                              ?.department_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Team</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.team
                              ?.team_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Position</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.position
                              ?.position_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Seniority</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.seniority
                              ?.seniority_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Job Location</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_location || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Priority</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.priority || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            No of Vancancy
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.no_of_vacancy || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Required Experience
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.required_experience ||
                              "-"}
                          </span>
                        </div>
                      </div>

                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Minimum Salary
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.min_salary || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Maximum Salary
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.max_salary || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Deadline</span>
                          {location.state.editpost?.deadline !== null ? (
                            <span className="profile-ud-value">
                              {moment(
                                location.state?.editpost?.deadline
                              ).format("D MMMM YYYY, h:mm a")}
                            </span>
                          ) : (
                            <span className="profile-ud-value">-</span>
                          )}
                        </div>
                      </div>
                      {/* <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Depute Status</span>
                          <span className="profile-ud-value">{location.state?.editpost?.depute_status || '-'}</span>
                        </div>
                      </div> */}
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Status</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.status === 1 ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">InActive</span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Workflow</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.workflow
                              ?.workflow_name || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <!-- .profile-ud-list --> */}
                  </div>

                  {/* <!-- .nk-block --> */}
                  <div className="nk-divider divider md"></div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Description</h5>
                    </div>
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_opening
                                  ?.job_description || "-",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Requirement</h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_opening
                                  ?.job_requirement || "-",
                            }}
                          />
                        </div>
                      </div>
                      {/* <!-- .bq-note-item --> */}
                    </div>
                    {/* <!-- .bq-note --> */}
                  </div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Comments</h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{location.state?.editpost?.comments || "-"} </p>
                        </div>
                      </div>
                      {/* <!-- .bq-note-item --> */}
                    </div>
                    {/* <!-- .bq-note --> */}
                  </div>
                </div>
                {/* <!-- .card-inner --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobOpeningDetails;
