import React, { useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { ScheduleCandidateInterviewContext } from "../../Context/ScheduleCandidateInterviewContext";
import { useForm, useFormState } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";

const localizer = momentLocalizer(moment);

const ScheduleCalendar = () => {
  const userID = secureLocalStorage.getItem("userID");
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const { isValid } = useFormState({ control });

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [isScheduleData, setIsScheduleData] = useState(null);

  const { rawInterviewSlots, getInterviewSlots } = useContext(
    ScheduleCandidateInterviewContext
  );
  console.log(rawInterviewSlots);

  const EventComponent = ({ event }) => (
    <div>
      <div>Free Slot</div>
      <div>
        Start: {moment(event.slot_start_time).format("MMMM Do YYYY, h:mm:ss a")}
      </div>
      <div>
        End: {moment(event.slot_end_time).format("MMMM Do YYYY, h:mm:ss a")}
      </div>
    </div>
  );

  const handleClosed = () => {
    setShowModal(false);
    reset();
  };

  const handleSelectSlot = (slotInfo) => {
    console.log(slotInfo);
    setIsScheduleData(null);
    setShowModal(true);
    setSelectedStartDate(slotInfo.start);
  };

  const handleSelectedEvent = (event) => {
    setShowModal(true);
    console.log(event);
    if (event) {
      setIsScheduleData(event);
      var defaultValues = {};
      defaultValues.start_at = moment(event.slot_start_time).format(
        "YYYY-MM-DDThh:mm"
      );
      reset(defaultValues);
    }
  };

  const onSubmit = (data) => {
    const start_date = moment(data.start_at)
      .utcOffset("+05:30")
      .format()
      .split("+");
    const end_date = moment(data.start_at)
      .add(1, "hours")
      .utcOffset("+05:30")
      .format()
      .split("+");
    console.log(start_date);
    console.log(end_date);
    if (isScheduleData) {
      var edit_free_slot = JSON.stringify({
        slot_start_time: start_date[0],
        slot_end_time: end_date[0],
        interviewer_id: userID,
        requisition_id: location?.state?.editpost?.requisition?.id,
        updated_by_id: userID,
      });

      console.log(edit_free_slot);
      const url = VARIABLES.url + `/api/interview-slots/${isScheduleData.id}/`;
      console.log(url);
      var config = {
        method: "patch",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: edit_free_slot,
      };

      setSubmitLoading(true);
      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Interview Schedule updated successfully",
          });
          reset();
          getInterviewSlots(location?.state?.editpost?.requisition?.id);
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to update schedule interview ",
          });
        });
    } else {
      var free_slot = JSON.stringify({
        slot_start_time: start_date[0],
        slot_end_time: end_date[0],
        interviewer_id: userID,
        requisition_id: location?.state?.editpost?.requisition?.id,
        created_by_id: userID,
      });

      console.log(free_slot);
      const url = VARIABLES.url + `/api/interview-slots/`;
      console.log(url);
      var config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: free_slot,
      };

      setSubmitLoading(true);
      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Interview Schedule successfully",
          });
          reset();
          getInterviewSlots(location?.state?.editpost?.requisition?.id);
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to schedule interview ",
          });
        });
    }
  };

  useEffect(() => {
    if (selectedStartDate !== null) {
      console.log(selectedStartDate);
      var defaultValues = {};
      defaultValues.start_at =
        moment(selectedStartDate).format("YYYY-MM-DDThh:mm");
      reset(defaultValues);
    }
  }, [selectedStartDate]);

  useEffect(() => {
    getInterviewSlots(location?.state?.editpost?.requisition?.id);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div></div>
          <div className="nk-block-head-content d-flex">
            <div class="nk-block-head-content me-3">
              <a
                id="back-button"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="back-button"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <di className="card-inner">
          <div style={{ height: "500px" }}>
            <Calendar
              events={rawInterviewSlots}
              localizer={localizer}
              startAccessor={(event) => new Date(event.slot_start_time)}
              endAccessor={(event) => new Date(event.slot_end_time)}
              style={{ margin: "50px" }}
              selectable={true}
              views={[Views.MONTH, Views.DAY, Views.AGENDA]}
              components={{
                event: EventComponent, // Use custom event component
              }}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleSelectedEvent}
            />
          </div>
        </di>
      </div>

      {showModal && (
        <div
          className="modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {location?.state?.editpost?.job_opening?.job_title}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => handleClosed()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="form-label">
                      Event Start Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="text-forms-start_at"
                      placeholder="Describe your event title"
                      {...register("start_at", { required: true })}
                    />
                    {errors.start_at && (
                      <span className="fs-8 text-danger">
                        Event start date is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  {submitLoading ? (
                    <button
                      id="loading-button"
                      className="btn text-dark"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary"
                      id="submit-button-free_slot"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary"
                      id="submit-button-free_slot"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScheduleCalendar;
