import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { StepperCandidate } from "../../../Context/StepperCandidate";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";

const SmartHireFormReview = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present

  const {
    level,
    sequenceList,
    setSequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);

  const { isSHFLoading, SHFData, getSHFData } = useContext(StepperCandidate);

  console.log(SHFData);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");
  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  //Submission of form
  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      interview_id: 1,
      candidate_job_id: candidate_job_id,
      requisition_id: requisition_id,
      candidate_status: data.is_selected,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_job_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    console.log(user);

    const url = VARIABLES.url + `/api/smarthireform/${SHFData[0]?.id}/`;
    console.log(url);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        nextLevel();
        getWorkflowSession();
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Success",
        });
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed",
        });
      });
  };

  useEffect(() => {
    setValue("is_selected", "Draft"); //sets default value as OTHER
  }, []);

  useEffect(() => {
    getSHFData(candidate_job_id);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Smart Hire Form Review</h4>
            {isSHFLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4 ">
                {isSHFLoading ? (
                  ""
                ) : (
                  <>
                    {SHFData?.length !== 0 ? (
                      <>
                        {SHFData?.map((post, index) => (
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                {post?.question}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={post?.answer}
                                disabled
                              />
                            </div>
                          </div>
                        ))}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <ul className="row g-3">
                            {interviewActionDropdownData.map(
                              (action, index) => (
                                <li className="col-sm-4" key={index}>
                                  <div className="custom-control custom-radio me-3">
                                    <input
                                      type="radio"
                                      id={`checkbox-form-is-selected-SHF-${action.value.toLowerCase()}`}
                                      className="custom-control-input"
                                      value={action.value}
                                      {...register("is_selected", {
                                        required: true,
                                      })}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for={`checkbox-form-is-selected-SHF-${action.value.toLowerCase()}`}
                                    >
                                      {action.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                          {errors.is_selected && (
                            <span className="fs-8 text-danger">
                              Please select any one
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        No Data Found
                      </div>
                    )}
                  </>
                )}
                <div className="col-12 d-flex">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    {submitLoading ? (
                      <button className="btn text-dark" disabled>
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-submitshf"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-submitshf"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Snackbar />
    </>
  );
};

export default SmartHireFormReview;
