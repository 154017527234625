import React, { Component, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Document, Page } from "react-pdf";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import RejectCandidate from "../CandidatePoolResumes/RejectCandidate";

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      // className="btn btn-white btn-dim resumebtn"
      className="btn btn-outline-dark"
      id="rightarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      {/* <h4 style={{ marginBottom: "0px" }}>next</h4> */}
      <em className="icon ni ni-forward-ios resume-icon"></em>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      // className="btn btn-white btn-dim resumebtn"
      className="btn btn-outline-dark "
      id="leftarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <em className="icon ni ni-back-ios resume-icon"></em>
      {/* <h4>back</h4> */}
    </button>
  );
}

const Slide = ({ document }) => {
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    if (document) {
      setNumPages(null);
    }
  }, [document]);

  const handleLoadSuccess = ({ numPages }) => {
    console.log("PDF loaded successfully:", numPages, "pages");
    setNumPages(numPages);
  };

  const handleError = (error) => {
    console.error("PDF Error:", error);
  };

  const renderPages = () => {
    const pages = [];
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      pages.push(
        <div key={pageNumber} className="pdf-page">
          <Page pageNumber={pageNumber} scale={2} />
        </div>
      );
    }
    return pages;
  };

  console.log("Document path:", document);
  console.log("Number of pages:", numPages);

  return (
    <div className="landscape-pdf-container">
      <Document
        file={document}
        onLoadSuccess={handleLoadSuccess}
        onError={handleError}
      >
        {numPages && renderPages()}
      </Document>
    </div>
  );
};

const ViewAllResume = () => {
  // const access = secureLocalStorage.getItem("access");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [currentData, setCurrentData] = useState([]);
  const privileges = secureLocalStorage.getItem("privileges");
  const location = useLocation();
  const navigate = useNavigate();

  const { rawAllResumeData, isAllResumeLoading } =
    useContext(ViewResumeContext);
  console.log("rawAllResumeData:", rawAllResumeData);
  console.log(rawAllResumeData.length);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const handleSliderChange = (index) => {
    console.log(index);
    console.log(rawAllResumeData[index]);
    setCurrentData(rawAllResumeData[index]);
  };

  const handleSubmit = (is_shortlisting, data) => {
    var user = JSON.stringify({
      requisition_id: location.state.jobID,
      candidate_status: is_shortlisting,
      updated_by_id: userID,
    });
    console.log(user);

    var config = {
      method: "patch",
      url:
        location.state.shortlisting_type === "RC"
          ? `${VARIABLES.url} + /api/candidateresume/${data.id}/?shortlisting_type=RC`
          : `${VARIABLES.url} + /api/candidateresume/${data.id}/?shortlisting_type=SME`,

      headers: {
        "Content-Type": "application/json",
        // "Authorization" : `Bearer ${access}`
      },
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Candidate sorted successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        // if(error.response.status === 403) {
        //   RefreshToken()
        // } else {
        setSubmitLoading(false);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to sort candidate",
        });
      // }
      });
  };

  useEffect(() => {
    if (rawAllResumeData.length > 0) {
      handleSliderChange(0);
    }
  }, [rawAllResumeData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between d-flex justify-end">
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      {isAllResumeLoading ? (
        <div> loading... </div>
      ) : rawAllResumeData.length === 0 ? (
        <p>No candidate data available.</p>
      ) : (
        <div className="resume-container">
          <div className="resume-result">
            {privileges.includes("shortlist_resume") && (
              <div>
                {submitLoading ? (
                  <button className="btn text-dark" disabled>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger"
                    id="submit-button-rejectresume"
                    data-bs-toggle="modal"
                    data-bs-target="#rejectcandidate"
                  >
                    REJECT
                  </button>
                )}
              </div>
            )}

            <div>
              {privileges.includes("shortlist_resume") &&
                (submitLoading ? (
                  <button className="btn text-dark" disabled>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    id="submit-button-acceptresume"
                    onClick={() => handleSubmit("accept", currentData)}
                  >
                    ACCEPT
                  </button>
                ))}
            </div>
          </div>
          <Slider {...settings} onChange={handleSliderChange}>
            {rawAllResumeData.map((data, dataIndex) => (
              <div key={dataIndex}>
                <div>
                  <Slide key={dataIndex} document={data?.resume} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}

      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <RejectCandidate setSubmitMessage={setSubmitMessage} />
    </>
  );
};

export default ViewAllResume;
