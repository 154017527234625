import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import { JobAssignmentContext } from "../../Context/JobAssignment";

const AddJobAssignment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const { isJobAssignmentLoading, jobAssignmentData, getJobAssignmentData } =
    useContext(JobAssignmentContext);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const [assignmentDocument, setAssignmentDocument] = useState(null);
  console.log("assignmentDocument:", assignmentDocument);

  const [assignmentCheck, setAssignmentCheck] = useState([]);
  console.log(assignmentCheck);
  console.log(jobAssignmentData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let user = new FormData();
    user.append("job_opening_id", location.state.editpost.id);
    user.append("assignment_title", data.assignment_title);
    user.append("assignment_document", data.assignment_document);
    user.append("updated_by_id", userID);
    user.append("created_by_id", userID);

    let config = {
      method: "post",
      url: VARIABLES.url + `/api/jobassignment/`,
      maxBodyLength: Infinity,
      data: user,
    };

    console.log(config.url);

    for (var pair of user.entries()) {
      console.log(pair);
    }

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getJobAssignmentData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "successfully created assignment",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to create assignment",
        });
      });
  };

  useEffect(() => {
    const handleAssignmentCheck = jobAssignmentData.filter(
      (info) => info.job_opening.id === location.state.editpost.id
    );
    setAssignmentCheck(handleAssignmentCheck);
    console.log(location.state.editpost);
  }, []);

  useEffect(() => {
    getJobAssignmentData();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Job Assignment</h3>
            {isJobAssignmentLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-assignment_title"
                      placeholder="Enter assignment title"
                      {...register("assignment_title", {
                        required: true,
                        pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.assignment_title && (
                      <span className="fs-8 text-danger">
                        Assignment title is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Document
                      <span className="fs-8 text-danger">*</span>
                    </label>
                    <Controller
                      name="assignment_document"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          className="form-control"
                          id="file-forms-assignment_document"
                          type="file"
                          onChange={(e) => {
                            setAssignmentDocument(e.target.files[0]);
                            field.onChange(e.target.files[0]);
                          }}
                        />
                      )}
                    />
                    {errors.assignment_document && (
                      <span className="fs-8 text-danger">
                        Assignment document is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="table-responsive">
                    <table className="table">
                      <div className="nk-tb-list">
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col">
                            <span>Assignment Title</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Job Title</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Document</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Edit</span>
                          </div>
                        </div>
                      
                      {assignmentCheck.length == 0 && (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                No Data Found
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                        {assignmentCheck.map((post, index) => (
                          <div className="nk-tb-item">
                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">
                                  {post?.assignment_title || "-"}
                                </span>
                              </span>
                            </div>

                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">
                                  {post?.job_opening?.job_title || "-"}
                                </span>
                              </span>
                            </div>

                            <div className="nk-tb-col">
                              <span className="tb-sub">
                                {post?.assignment_document ? (
                                  <a
                                    className="btn btn-primary"
                                    id="button-button-assignment_doc"
                                    href={post?.assignment_document}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <em
                                      className="icon ni ni-file-pdf"
                                      style={{ color: "white" }}
                                    ></em>
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>

                            {privileges.includes("job_assignment_update") ? (
                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/editjobassignment"}
                                  state={{ editpost: post }}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-editassignment"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>
                            ) : (
                              " "
                            )}

                          </div>
                        ))}
                        </div>
                    </table>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button className="btn text-dark" disabled>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createscheduleinterview"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddJobAssignment;
