import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { MasterTableContext } from "../../Context/MasterTableContext";
import Swal from "sweetalert2";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const EditTeam = () => {
  const userID = secureLocalStorage.getItem("userID");

  const { getTeamData, getDepartmentData } = useContext(MasterTableContext);

  const {
    isGenricDropdownDataLoading,
    isGenricParamsDropdownDataLoading,
    getGenericDropdownData,
    departmentDropdownData,
    usersDropdownData,
  } = useContext(GenericDropdownContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let location = useLocation();
  let navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);
  const privileges = secureLocalStorage.getItem("privileges");

  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      team_name: data.team_name,
      team_display_name: data.team_display_name,
      team_incharge_id: data.team_incharge.value,
      department_id: data.department.value,
      updated_by_id: userID,
    });

    const url = VARIABLES.url + `/api/team/${location.state.editpost.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Team updated successfully",
        });
        getGenericDropdownData();
        getTeamData();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update team",
        });
      });
  };

  const deleteTeam = () => {
    const url =
      VARIABLES.url +
      `/api/team/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {},
    };
    axios(config)
      .then((response) => {
        getTeamData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the team",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setActiveStatus(1);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to delete the team",
        });
        console.log(error);
      });
  };

  const deleteTeamAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTeam();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedTeam = location.state.editpost;
    if (editedTeam) {
      var defaultValues = {};
      defaultValues.team_name = editedTeam.team_name;
      defaultValues.team_display_name = editedTeam.team_display_name;
      if (editedTeam.team_incharge) {
        const getTeamIncharge = {
          value: editedTeam.team_incharge.id,
          label:
            editedTeam.team_incharge.first_name +
            " " +
            editedTeam.team_incharge.last_name,
        };
        defaultValues.team_incharge = getTeamIncharge;
      }
      const getDepartment = {
        value: editedTeam.department?.id,
        label: editedTeam.department?.department_name,
      };
      defaultValues.department = getDepartment;
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    getDepartmentData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Team </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          {privileges.includes("team_delete") ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-deleteteam"
                  checked={activeStatus}
                  onChange={(e) => {
                    deleteTeamAlert(location.state?.editpost?.id);
                  }}
                />
                <label
                  className="custom-control-label"
                  id="button-button-status"
                  htmlFor="submit-button-deleteteam"
                ></label>
              </div>
            </div>
          ) : (
            ""
          )}
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Department
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={departmentDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.department_display_name,
                          }))}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-department"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="department"
                      control={control}
                    />
                    {errors.department && (
                      <p className="fs-8 text-danger">Department is required</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Team name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter team name"
                    id="text-forms-team_name"
                    {...register("team_name", { required: true })}
                  />
                  {errors.team_name && (
                    <span className="fs-8 text-danger">
                      Team name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Team display name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter team display name"
                    id="text-forms-team_display_name"
                    {...register("team_display_name", { required: true })}
                  />
                  {errors.team_display_name && (
                    <span className="fs-8 text-danger">
                      Team display name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Team incharge</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={usersDropdownData?.map((info) => ({
                            value: info.user.id,
                            label: `${info.user.first_name} ${info.user.last_name}`,
                          }))}
                          isClearable={true}
                          isLoading={isGenricParamsDropdownDataLoading}
                          id="select-forms-team_incharge"
                        />
                      )}
                      defaultValue=""
                      name="team_incharge"
                      control={control}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editteam"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditTeam;
