import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";

export const TAMRE1RE2RE3Context = createContext(null);

function TAMRE1RE2RE3Provider({ children }) {
  const userID = secureLocalStorage.getItem("userID");
  // To store TAM Data.
  const [isTamData, setIsTamData] = useState(false);
  const [rawTamData, setRawTamData] = useState([]);
  console.log(rawTamData)
  const [tamEntries, setTamEntries] = useState(5)
  const [tamCount, setTamCount] = useState(null)
  const [tamPageCount, setTamPageCount] = useState(0)
  const [isTamLoading, setIsTamLoading] = useState(false);

   // To store RE1 Data.
   const [isRE1Data, setIsRE1Data] = useState(false);
   const [rawRE1Data, setRawRE1Data] = useState([]);
   const [re1Entries, setRE1Entries] = useState(5)
   const [re1Count, setRE1Count] = useState(null)
   const [re1PageCount, setRE1PageCount] = useState(0)
   const [isRE1Loading, setIsRE1Loading] = useState(false);

   // To store RE2 Data.
   const [isRE2Data, setIsRE2Data] = useState(false);
   const [rawRE2Data, setRawRE2Data] = useState([]);
   const [re2Entries, setRE2Entries] = useState(5)
   const [re2Count, setRE2Count] = useState(null)
   const [re2PageCount, setRE2PageCount] = useState(0)
   const [isRE2Loading, setIsRE2Loading] = useState(false);

   // To store RE3 Data.
   const [isRE3Data, setIsRE3Data] = useState(false);
   const [rawRE3Data, setRawRE3Data] = useState([]);
   const [re3Entries, setRE3Entries] = useState(5)
   const [re3Count, setRE3Count] = useState(null)
   const [re3PageCount, setRE3PageCount] = useState(0)
   const [isRE3Loading, setIsRE3Loading] = useState(false);

  // "GetTAM" API call to get TAM data
  const getTamData = async (page, searchTam) => {
    setIsTamLoading(true);
    console.log(searchTam)
    let url;
    if (searchTam !== undefined) {
      url = VARIABLES.url + `/api/requestrequisition/?entries=${tamEntries}&page=${page}&requisition_acceptance=True&event=${searchTam}`;
    } else {
      url = VARIABLES.url + `/api/requestrequisition/?entries=${tamEntries}&page=${page}&requisition_acceptance=True`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsTamLoading(false);
      if (response.length !== 0) {
        setIsTamData(false);
        setRawTamData(res.data.data);
      } else {
        setRawTamData(res.data.data);
        setIsTamData(true);
      }
      const total = res.data.count;
      setTamCount(total);
      setTamPageCount(Math.ceil(total / tamEntries));
    } catch (error) {
      console.log(error);
      setIsTamLoading(false);
      setIsTamData(true);
    }
  };

  const handleTamClick = async (data,searchTam) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getTamData(currentPage,searchTam);
  };

  // "GetRE1" API call to get RE1 data
  const getRE1Data = async (page, searchRE1) => {
    setIsRE1Loading(true);
    console.log(searchRE1)
    let url;
    if (searchRE1 !== undefined) {
      url = VARIABLES.url + `/api/associateuser/?entries=${re1Entries}&page=${page}&user=${userID}&associated_type=re1&event=${searchRE1}`;
    } else {
      url = VARIABLES.url + `/api/associateuser/?entries=${re1Entries}&page=${page}&user=${userID}&associated_type=re1`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRE1Loading(false);
      if (response.length !== 0) {
        setIsRE1Data(false);
        setRawRE1Data(res.data.data);
      } else {
        setIsRE1Data(true);
        setRawRE1Data(res.data.data);
      }
      const total = res.data.count;
      setRE1Count(total);
      setRE1PageCount(Math.ceil(total / re1Entries));
    } catch (error) {
      console.log(error);
      setIsRE1Loading(false);
      setIsRE1Data(true);
    }
  };

  const handleRE1Click = async (data,searchRE1) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRE1Data(currentPage,searchRE1);
  };

   // "GetRE2" API call to get RE2 data
   const getRE2Data = async (page, searchRE2) => {
    setIsRE2Loading(true);
    console.log(searchRE2)
    let url;
    if (searchRE2 !== undefined) {
      url = VARIABLES.url + `/api/associateuser/?entries=${re2Entries}&page=${page}&user=${userID}&associated_type=re2&event=${searchRE2}`;
    } else {
      url = VARIABLES.url + `/api/associateuser/?entries=${re2Entries}&page=${page}&user=${userID}&associated_type=re2`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRE2Loading(false);
      if (response.length !== 0) {
        setIsRE2Data(false);
        setRawRE2Data(res.data.data);
      } else {
        setIsRE2Data(true);
        setRawRE2Data(res.data.data);
      }
      const total = res.data.count;
      setRE2Count(total);
      setRE2PageCount(Math.ceil(total / re2Entries));
    } catch (error) {
      console.log(error);
      setIsRE2Loading(false);
      setIsRE2Data(true);
    }
  };

  const handleRE2Click = async (data,searchRE2) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRE2Data(currentPage,searchRE2);
  };

  // "GetRE3" API call to get RE3 data
  const getRE3Data = async (page, searchRE3) => {
    setIsRE3Loading(true);
    console.log(searchRE3)
    let url;
    if (searchRE3 !== undefined) {
      url = VARIABLES.url + `/api/associateuser/?entries=${re3Entries}&page=${page}&user=${userID}&associated_type=re3&event=${searchRE3}`;
    } else {
      url = VARIABLES.url + `/api/associateuser/?entries=${re3Entries}&page=${page}&user=${userID}&associated_type=re3`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRE3Loading(false);
      if (response.length !== 0) {
        setIsRE3Data(false);
        setRawRE3Data(res.data.data);
      } else {
        setIsRE3Data(true);
        setRawRE3Data(res.data.data);
      }
      const total = res.data.count;
      setRE3Count(total);
      setRE3PageCount(Math.ceil(total / re3Entries));
    } catch (error) {
      console.log(error);
      setIsRE3Loading(false);
      setIsRE3Data(true);
    }
  };

  const handleRE3Click = async (data,searchRE3) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRE3Data(currentPage,searchRE3);
  };

  return (
    <div>
      <TAMRE1RE2RE3Context.Provider
        value={{
          rawTamData,
          getTamData,
          tamEntries,
          tamPageCount,
          setTamEntries,
          tamCount,
          handleTamClick,
          isTamData,
          isTamLoading,

          rawRE1Data,
          getRE1Data,
          re1Entries,
          re1PageCount,
          setRE1Entries,
          re1Count,
          handleRE1Click,
          isRE1Data,
          isRE1Loading,

          rawRE2Data,
          getRE2Data,
          re2Entries,
          re2PageCount,
          setRE2Entries,
          re2Count,
          handleRE2Click,
          isRE2Data,
          isRE2Loading,

          rawRE3Data,
          getRE3Data,
          re3Entries,
          re3PageCount,
          setRE3Entries,
          re3Count,
          handleRE3Click,
          isRE3Data,
          isRE3Loading,
        }}
      >
        {children}
      </TAMRE1RE2RE3Context.Provider>
    </div>
  );
}

export default TAMRE1RE2RE3Provider;
