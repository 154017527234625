import React, { Component, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Document, Page } from "react-pdf";
import secureLocalStorage from "react-secure-storage";
import { NavLink } from "react-router-dom";

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      // className="btn btn-white btn-dim resumebtn"
      className="btn btn-outline-dark"
      id="rightarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      {/* <h4 style={{ marginBottom: "0px" }}>next</h4> */}
      <em className="icon ni ni-forward-ios resume-icon"></em>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      // className="btn btn-white btn-dim resumebtn"
      className="btn btn-outline-dark "
      id="leftarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <em className="icon ni ni-back-ios resume-icon"></em>
      {/* <h4>back</h4> */}
    </button>
  );
}

const Slide = ({ document }) => {
  console.log("Document data:", document);

  const handleLoadSuccess = ({ numPages, ...otherInfo }) => {
    console.log(`Number of pages: ${numPages}`);
    console.log("Other PDF info:", otherInfo);
  };

  const handleError = (error) => {
    console.error("PDF Error:", error);
  };

  return (
    <div className="landscape-pdf-container">
      <Document
        file={document}
        onLoadSuccess={handleLoadSuccess}
        onError={handleError}
      >
        <Page pageNumber={1} scale={2} />
      </Document>
    </div>
  );
};

const ViewResume = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const privileges = secureLocalStorage.getItem("privileges");

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const handleSubmit = (is_shortlisting, data) => {
    var user = JSON.stringify({
      is_shortlisted: is_shortlisting,
      updated_by_id: userID,
    });
    console.log(user);

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/candidateJob/${data.id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        props.setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Candidate selected sorted",
        });
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        props.setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to sort candidate",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      props.setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [props.submitMessage]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between d-flex justify-end">
          <div className="nk-block-head-content">
            <NavLink
              to={"/viewallresume"}
              onClick={() => props.getResumeData(props.jobID)}
              className="toggle btn btn-icon btn-primary d-md-none"
              id="button-button-viewallresume"
            >
              <em className="icon ni ni-plus"></em>
            </NavLink>

            <NavLink
              to={"/viewallresume"}
              onClick={() => props.getResumeData(props.jobID)}
              className="toggle btn btn-primary d-none d-md-inline-flex"
              id="button-button-viewallresume"
            >
              <em className="icon ni ni-eye"></em>
              <span>View all resumes</span>
            </NavLink>
          </div>
        </div>
      </div>
      {props.isLoading ? (
        <div> loading... </div>
      ) : props.rawResumeData.length === 0 ? (
        <p>No candidate data available.</p>
      ) : (
        <div className="resume-container">
          <Slider {...settings}>
            {props.rawResumeData.map((data, dataIndex) => (
              <div key={dataIndex}>
                <div>
                  <Slide
                    key={dataIndex}
                    document={data.user_details.attachment}
                  />
                </div>

                <div className="resume-result">
                  {privileges.includes("shortlist_resume") && (
                    <div>
                      {submitLoading ? (
                        <button className="btn text-dark" disabled>
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-danger"
                          id="submit-button-rejectresume"
                          onClick={() => handleSubmit(false, data)}
                        >
                          REJECT
                        </button>
                      )}
                    </div>
                  )}

                  <div>
                    {privileges.includes("shortlist_resume") &&
                      (submitLoading ? (
                        <button className="btn text-dark" disabled>
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success"
                          id="submit-button-acceptresume"
                          onClick={() => handleSubmit(true, data)}
                        >
                          ACCEPT
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}

      <div>
        {props.submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${props.submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${props.submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{props.submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewResume;
