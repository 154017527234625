import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";

const MettleTest = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const { candidateExam, getCandidateExamData } = useContext(MDApprovalContext);
  console.log(candidateExam); //stores the test data of the candidate

  const { level, sequenceList, setSequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false); //loading state while submitting data
  const [selectedExam, setSelectedExam] = useState([]);
  console.log(selectedExam);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  //checks that is there an test already present to this candidate or not
  //if yes : then Patch will work
  //if no : then Post will work
  const handleExamCheck = () => {
    const existingExam = candidateExam.find(
      (examItem) =>
        examItem.candidate_job?.candidate_resume?.id === candidate_job_id
    );
    setSelectedExam(existingExam);
  };

  //Submission of form
  const onSubmit = (data) => {
    console.log(data);
    if (selectedExam) {
      var user = JSON.stringify({
        candidate_job_id: candidate_job_id,
        requisition_id: requisition_id,
        test_conducted_user_id: userID,
        test_link: data.test_link,
        test_name: data.test_name,
        updated_by_id: userID,
        workflow_session: {
          candidate_job_id: candidate_job_id,
          tab_id: formId.id,
          workflow_id: formId.WF,
          user: userID,
          created_by_id: userID,
          updated_by_id: userID,
        },
      });
      const url = VARIABLES.url + `/api/candidateexam/${candidateExam[0].id}/`;
      console.log(url);
      var config = {
        method: "patch",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: user,
      };

      console.log(user);
      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          // workflowSession();
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "successfully created mettle test",
          });
          getCandidateExamData(candidate_job_id);
          reset();
          nextLevel();
          getWorkflowSession(1);
          // setTimeout(() => {
          //   navigate('/')
          // }, 2000);
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to create mettle test",
          });
        });
    } else {
      var user = JSON.stringify({
        candidate_job_id: candidate_job_id,
        requisition_id: requisition_id,
        test_link: data.test_link,
        test_name: data.test_name,
        test_conducted_user_id: userID,
        created_by_id: userID,
        updated_by_id: userID,
        workflow_session: {
          candidate_job_id: candidate_job_id,
          tab_id: formId.id,
          workflow_id: formId.WF,
          user: userID,
          created_by_id: userID,
          updated_by_id: userID,
        },
      });

      var config = {
        method: "post",
        url: VARIABLES.url + "/api/candidateexam/",
        headers: {
          "Content-Type": "application/json",
        },
        data: user,
      };

      console.log(user);
      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          getCandidateExamData(candidate_job_id);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Test assigned successful",
          });
          reset();
          nextLevel();
          getWorkflowSession(1);
          // workflowSession();
          // setTimeout(() => {
          //   navigate('/')
          // }, 2000);
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to assign mettle test",
          });
        });
    }
  };

  //Pre-populating data
  useEffect(() => {
    const editedData = candidateExam[0];
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      defaultValues.test_name = editedData.test_name;
      defaultValues.test_link = editedData.test_link;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    handleExamCheck();
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Psychometric Test </h4>
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Test Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-test_name"
                      placeholder="Enter test name"
                      {...register("test_name", {
                        required: true,
                      })}
                    />
                    {errors.test_name && (
                      <span className="fs-8 text-danger">
                        Test name is required
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      Test Link <span className="text-danger">*</span>
                    </label>
                    <input
                      type="url"
                      className="form-control"
                      id="text-forms-test_link"
                      placeholder="Enter test link"
                      {...register("test_link", {
                        required: true,
                      })}
                    />
                    {errors.test_link && (
                      <span className="fs-8 text-danger">
                        Test link is required
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="table-responsive">
                    <table className="table">
                      <h6>Table</h6>
                    </table>
                  </div>
                </div> */}

                <div className="col-12">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 1 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-mettletest"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-mettletest"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default MettleTest;
