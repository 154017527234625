import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import secureLocalStorage from "react-secure-storage";

export const SendOfferContext = createContext(null);

function SendOfferContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [sendOffer, setSendOffer] = useState([]);

  const [candidate_job_id, setcandidate_job_id] = useState(null);
  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);

  const [candidateIDS, setCandidateIDS] = useState(null);

  const [candidateStatus, setCandidateStatus] = useState([]);
  console.log(candidateStatus);

  const getCandidateJobOfferData = async (candidate_job_id) => {
    setIsLoading(true);
    try {
      const url = `/api/candidatejob/?candidate_job=${candidate_job_id}`;
      console.log(url);
      const res = await axios.get(VARIABLES.url + url);
      setCandidateStatus(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getSendOfferData = async (candidate_job_id) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/sendoffer/?candidate_job=${candidate_job_id}`
      );
      setSendOffer(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("first");
    setcandidate_job_id(secureLocalStorage.getItem("RWFcandidate_job_id"));
  }, [candidateIDS]);

  const value = {
    sendOffer,
    candidateStatus,
    isLoading,
    setCandidateIDS,
    getSendOfferData,
    getCandidateJobOfferData,
  };
  return (
    <div>
      <SendOfferContext.Provider value={value}>
        {children}
      </SendOfferContext.Provider>
    </div>
  );
}

export default SendOfferContextProvider;
