import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { VARIABLES } from "../Constants";
export const TypemasterDropdownContext = createContext(null);

function TypemasterDropdownContextProvider({ children }) {
  const [isDropdownDataLoading, setIsDropdownDataLoading] = useState(true);
  console.log(isDropdownDataLoading);
  const [parentCategory, setParentCategory] = useState(null);

  //DROPDOWN STATES
  const [jobTypeDropdownData, setJobTypeDropdownData] = useState([]);
  const [priorityDropdownData, setPriorityDropdownData] = useState([]);
  const [interviewTypeDropdownData, setInterviewTypeDropdownData] = useState(
    []
  );
  const [interviewModeDropdownData, setInterviewModeDropdownData] = useState(
    []
  );
  const [interviewCategoryDropdownData, setInterviewCategoryDropdownData] =
    useState([]);
  const [agencyTypeDropdownData, setAgencyTypeDropdownData] = useState([]);
  const [parentValueDropdownData, setParentValueDropdownData] = useState([]);
  const [interviewActionDropdownData, setInterviewActionDropdownData] =
    useState([]);
  const [RE2PortalsDropdownData, setRE2PortalsDropdownData] = useState([]);
  const [RECollegesDropdownData, setRECollegesDropdownData] = useState([]);
  const [KYCDocuments, setKYCDocuments] = useState([]);
  const [requisitionID, setRequisitionID] = useState(null);

  let dropdownData;
  const getDropdownData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/typemasterdetails/");
      dropdownData = res.data.data;
      setJobTypeDropdownData(
        dropdownData?.filter((post) => post.category_name == "job_type")
      );
      setPriorityDropdownData(
        dropdownData?.filter((post) => post.category_name == "priority")
      );
      setInterviewTypeDropdownData(
        dropdownData?.filter((post) => post.category_name == "interview_type")
      );
      setInterviewModeDropdownData(
        dropdownData?.filter((post) => post.category_name == "interview_mode")
      );
      setInterviewCategoryDropdownData(
        dropdownData?.filter((post) => post.category_name == "category")
      );
      setAgencyTypeDropdownData(
        dropdownData?.filter((post) => post.category_name == "agency_type")
      );
      setInterviewActionDropdownData(
        dropdownData?.filter((post) => post.category_name == "interview-action")
      );
      setRE2PortalsDropdownData(
        dropdownData?.filter((post) => post.category_name == "re2_portals")
      );
      setRequisitionID(
        dropdownData?.filter((post) => post.category_name == "requisition_id")
      );
      setRECollegesDropdownData(
        dropdownData?.filter((post) => post.category_name == "re1_college")
      );
      setKYCDocuments(
        dropdownData?.filter((post) => post.category_name == "document_name")
      );
      setIsDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setIsDropdownDataLoading(false);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    console.log("parentCategory: ", parentCategory);
    setParentValueDropdownData(
      dropdownData?.filter((post) => post.category_name == { parentCategory })
    );
  }, [parentCategory]);

  const value = {};
  return (
    <div>
      <TypemasterDropdownContext.Provider
        value={{
          isDropdownDataLoading,
          setParentCategory,

          jobTypeDropdownData,
          priorityDropdownData,
          interviewTypeDropdownData,
          interviewModeDropdownData,
          interviewCategoryDropdownData,
          agencyTypeDropdownData,
          parentValueDropdownData,
          interviewActionDropdownData,
          RE2PortalsDropdownData,
          RECollegesDropdownData,
          KYCDocuments,
          requisitionID,
        }}
      >
        {children}
      </TypemasterDropdownContext.Provider>
    </div>
  );
}

export default TypemasterDropdownContextProvider;
