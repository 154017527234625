import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import { CandidateDetailsContext } from "../../Context/CandidateDetailsContext";
import { WorkflowContext } from "../../Context/WorkflowContext";
import Swal from "sweetalert2";
import { SendOfferContext } from "../../Context/SendOfferContext";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";

const CandidateApplied = () => {
  // const access = secureLocalStorage.getItem("access");
  const [searchRequest, setSearchRequest] = useState("");
  const {
    candidateJob,
    isLoading,
    setCandidateEntries,
    handleCandidatePageClick,
    CandidateEntryCount,
    candidateEntries,
    candidatePageCount,
    getCandidateJobData,
    isData,
  } = useContext(CandidateDetailsContext);

  const { getCandidateResume } = useContext(ViewResumeContext);

  const { getRequisitionByUserData } = useContext(RequestedJobOpeningContext);

  console.log(candidateJob);

  let location = useLocation();
  console.log(location.state?.editpost);
  const navigate = useNavigate();

  const { getSendOfferData, getCandidateJobOfferData } =
    useContext(SendOfferContext);
  const { getCandidateStepperFlow, getWorkflowSession } =
    useContext(WorkflowContext);

  const privileges = secureLocalStorage.getItem("privileges");

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const [submitMessage, setSubmitMessage] = useState(null);

  const [filterAppliedCandidate, setFilterAppliedCandidate] = useState([]);

  const handleResumeCount = (candidateJobID, count) => {
    var resumeCount = JSON.stringify({
      view_resume_count: count,
      updated_by_id: userID,
    });
    console.log(candidateJobID);
    const url = VARIABLES.url + `/api/candidatejob/${candidateJobID.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
        // "Authorization" : `Bearer ${access}`
      },
      user: resumeCount,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.user));
      })
      .catch((error) => {
        console.log(error);
        // if(error.response.status === 403) {
        //   RefreshToken()
        // } else {
          console.log("Error")
        // }
      });
  };

  const handleEditSubmit = async (is_shortlisting, data) => {
    console.log(data);
    console.log(is_shortlisting);
    if (data?.job_opening?.workflow) {
      var user = JSON.stringify({
        is_shortlisted: is_shortlisting,
        workflow_id: data.job_opening.workflow,
        copy_workflow: true,
        shortlisted_user_id: userID,
        updated_by_id: userID,
      });
      console.log(user);

      var config = {
        method: "patch",
        url: VARIABLES.url + `/api/candidatejob/${data.id}/`,
        headers: {
          "Content-Type": "application/json",
          // "Authorization" : `Bearer ${access}`
        },
        data: user,
      };
      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          getCandidateJobData(1, location.state?.editpost?.id);
          getRequisitionByUserData();
          if (is_shortlisting === true) {
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: "Candidate shortlisted successfuly",
            });
          } else {
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: "Candidate rejected successfuly",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // if(error.response.status === 403) {
          //   RefreshToken()
          // } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to sort candidate",
          });
        // }
        });
    } else {
      Swal.fire({
        title: "Oops!",
        text: "Workflow is not associated with seleted candidate. Please assign a workflow to a Job Opening first",
        icon: "Oops",
        confirmButtonText: "OK",
      });
    }
  };

  const handleEntries = (event) => {
    setCandidateEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getCandidateJobData(1, location.state?.editpost?.id, searchRequest);
    } else {
      getCandidateJobData(1, location.state?.editpost?.id);
      setSearchRequest("");
    }
  };

  const handleViewStepper = (id) => {
    secureLocalStorage.setItem("RWFcandidate_job_id", id);
    secureLocalStorage.setItem(
      "RWFrequisition_id",
      location.state?.editpost?.id
    );
    getCandidateStepperFlow(id);
    getCandidateJobOfferData(id);
    getSendOfferData(id);
    getWorkflowSession(0);
    console.log(id);
  };

  useEffect(() => {
    setFilterAppliedCandidate(candidateJob);
  }, [candidateJob]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    getCandidateJobData(1, location.state?.editpost?.id);
  }, [location, candidateEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Candidate applied for {location.state?.editpost?.job_title || "-"}
            </h3>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex">
            {privileges.includes("view_applied_resume") ? (
              <div className="nk-block-head-content me-3">
                <NavLink
                  to={"/viewresume"}
                  onClick={() => {
                    getCandidateResume(location.state?.editpost?.id);
                  }}
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-viewresume"
                >
                  <em className="icon ni ni-eye"></em>
                </NavLink>

                <NavLink
                  to={"/viewresume"}
                  onClick={() => {
                    getCandidateResume(location.state?.editpost?.id);
                  }}
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-viewresume"
                >
                  <em className="icon ni ni-eye"></em>
                  <span>Shortlist Resume</span>
                </NavLink>
              </div>
            ) : (
              " "
            )}

            <div class="nk-block-head-content">
              <a
                id="button-back"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="button-back"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>

            {/* <div className="nk-block-head-content">
              <NavLink
                to={"/accept_resume"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-accept_resume"
              >
                <em className="icon ni ni-file"></em>
              </NavLink>

              <NavLink
                to={"/accept_resume"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-accept_resume"
              >
                <em className="icon ni ni-file"></em>
                <span>Accept</span>
              </NavLink>
            </div> */}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search by candidate name..."
                      onChange={onChangeHandler}
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      {/* <div className="nk-tb-col tb-col-sm">
                            <span>ID</span>
                          </div> */}
                      <div className="nk-tb-col tb-col-sm">
                        <span>Candidate name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Current/Previous Company</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Excepted Salary</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Experience</span>
                      </div>
                      <div className="nk-tb-col tb-col-md">
                        <span>Notice Period</span>
                      </div>
                      <div className="nk-tb-col tb-col-md">
                        <span>Resume</span>
                      </div>

                      <div className="nk-tb-col tb-col-md">
                        <span>Accept/ Reject</span>
                      </div>

                      <div className="nk-tb-col tb-col-md">
                        <span>View Progress</span>
                      </div>
                    </div>

                    {filterAppliedCandidate.length !== 0 ? (
                      filterAppliedCandidate.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          {/* <div className="nk-tb-col">
                                <span className="tb-lead">
                                  {post?.id || "-"}
                                </span>
                              </div> */}
                          <div className="nk-tb-col tb-col-sm">
                            <span className="tb-product">
                              <span className="title">
                                {post.user?.first_name} {post.user?.last_name}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.current_previous_company || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.expected_salary || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">{post?.experience}</span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.notice_period || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            {post?.user_details?.attachment ? (
                              <a
                                className="btn btn-primary"
                                id="button-button-viewresume"
                                href={post?.user_details?.attachment || "-"}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => handleResumeCount(post, 1)}
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div className="nk-tb-col">
                            {post?.is_shortlisted !== null ? (
                              <>
                                {post.is_shortlisted === true ? (
                                  <span class="badge bg-success">Accept</span>
                                ) : (
                                  <span class="badge bg-danger">Reject</span>
                                )}
                              </>
                            ) : (
                              <div className="d-flex">
                                {/* {privileges.includes(
                                      "shortlist_applied_candidate"
                                    ) ? ( */}
                                <>
                                  <button
                                    onClick={() => handleEditSubmit(true, post)}
                                    className="btn btn-white btn-dim btn-outline-success"
                                    id="button-button-acceptresume"
                                  >
                                    <em className="icon ni ni-check"></em>
                                  </button>

                                  <button
                                    onClick={() =>
                                      handleEditSubmit(false, post)
                                    }
                                    className="btn btn-white btn-dim btn-outline-danger"
                                    id="button-button-rejectresume"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </button>
                                </>
                                {/* ) : (
                                      " "
                                    )} */}
                              </div>
                            )}
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              <NavLink
                                to={"/stepper"}
                                onClick={() => handleViewStepper(post.id)}
                                // state={{ editpost: post.id }}
                                className="btn btn-primary"
                                id="button-button-viewstepper"
                              >
                                <em className="icon ni ni-eye"></em>
                              </NavLink>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidatePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidatePageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleCandidatePageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:{" "}
                      <span className="fw-bold">
                        {CandidateEntryCount === null ? 0 : CandidateEntryCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateEntries}
                        onChange={handleEntries}
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateApplied;
