import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { googleLogout } from "@react-oauth/google";
import { LoginContext } from "../Context/LoginContext";

const Header = (props) => {
  const { setUser, setProfile, user } = useContext(LoginContext);
  const username = secureLocalStorage.getItem("username");
  const email = secureLocalStorage.getItem("email");
  const first_name = secureLocalStorage.getItem("firstName");
  const last_name = secureLocalStorage.getItem("lastName");
  const navigate = useNavigate();
  // Calling logout function in app.js. State Pull up
  const handleLogout = () => {
    googleLogout();
    props.logoutHandler();
    // setProfile(null);
    navigate("/");
  };
  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div
              className="nk-menu-trigger d-xl-none ms-n1 me-3"
              id="button-button-sidebar_open"
            >
              <a
                href="javascript:void(0)"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-menu"></em>
              </a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a
                href="http://www.fluidscapes.in/"
                className="logo-link"
                id="logo-fluidscapes"
              >
                <img
                  className="logo-light logo-img"
                  src="./assets/images/logo.jpg"
                  srcset="./assets/images/logo.jpg 2x"
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img"
                  src="./assets/images/logo.jpg"
                  srcset="./assets/images/logo.jpg 2x"
                  alt="logo-dark"
                />
              </a>
            </div>
            {/* <!-- .nk-header-brand --> */}
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li
                  className="dropdown user-dropdown"
                  id="button-button-dropdown"
                >
                  <a
                    href="javascript:void(0)"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div className="user-card">
                        <div className="user-avatar">
                          <span>
                            {first_name?.charAt(0) + last_name?.charAt(0)}
                          </span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">{username}</span>
                          <span className="sub-text">{email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li id="button-button-signout">
                          <a href="javascript:void(0)" onClick={handleLogout}>
                            <em className="icon ni ni-signout"></em>
                            <span>Sign out</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <!-- .nk-header-tools --> */}
          </div>
          {/* <!-- .nk-header-wrap --> */}
        </div>
        {/* <!-- .container-fliud --> */}
      </div>
    </>
  );
};

export default Header;
