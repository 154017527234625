import React, { useContext, useEffect } from "react";
import { CandidateDetailsContext } from "../../../Context/CandidateDetailsContext";
import secureLocalStorage from "react-secure-storage";

const PersonalDetails = () => {
  const {
    candidatePersonalDetails,
    isCandidatePersonalDataLoading,
    getCandidatePersonalData,
  } = useContext(CandidateDetailsContext);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");

  console.log(candidatePersonalDetails);

  useEffect(() => {
    getCandidatePersonalData(candidate_job_id);
  }, [candidate_job_id]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Candidate's Personal Details</h4>
            {isCandidatePersonalDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isCandidatePersonalDataLoading ? (
        <div></div>
      ) : (
        <div>
          {candidatePersonalDetails == 0 ? (
            <div>Personal details not found</div>
          ) : (
            <div className="row">
              {candidatePersonalDetails?.map((info, index) => (
                <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
                  <div className="card">
                    <div className="card-aside-wrap">
                      <div className="card-content">
                        <div className="card-inner">
                          <div className="nk-block">
                            <div className="nk-block-head">
                              <h5 className="title">
                                {info?.user?.first_name || "-"}{" "}
                                {info?.user?.last_name || "-"}
                              </h5>
                            </div>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Username
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.user?.username || "-"}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    E-mail
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.user?.email || "-"}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Gender
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.gender || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Date of Birth
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.date_of_birth || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Contact no.
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.mobile || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Current Location
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.current_location || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Skills
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.skills || "-"}
                                  </span>
                                </div>
                              </div>

                              <div class="profile-ud-item">
                                <div class="profile-ud wider">
                                  <span class="profile-ud-label">Resume</span>
                                  <span class="profile-ud-value">
                                    {info?.attachment ? (
                                      <a
                                        className="btn btn-primary"
                                        id="button-button-viewresume"
                                        href={info?.attachment}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <em
                                          className="icon ni ni-file-pdf"
                                          style={{ color: "white" }}
                                        ></em>
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Linkedin
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.linkedin_url || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Facebook
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.facebook_url || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Instagram
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.instagram_url || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Twitter
                                  </span>
                                  <span className="profile-ud-value">
                                    {info?.twitter_url || "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PersonalDetails;
