import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import secureLocalStorage from "react-secure-storage";

const RCCandidateResume = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const [searchResume, setSearchResume] = useState("");
  console.log(searchResume);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    rawPendingCandidateResume,
    getPendingCandidateResume,
    pendingCandidateResumeEntries,
    pendingCandidateResumePageCount,
    setPendingCandidateResumeEntries,
    pendingCandidateResumeCount,
    handlePendingCandidateResumeClick,
    isPendingResumeData,
    isPendingResumeLoading,
  } = useContext(CandidateResumeContext);

  const { getRCCandidateResume } = useContext(ViewResumeContext);

  console.log(rawPendingCandidateResume);

  const handleEntries = (event) => {
    setPendingCandidateResumeEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchResume(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getPendingCandidateResume(
        1,
        location.state.editpost.requisition.id,
        searchResume
      );
    } else {
      getPendingCandidateResume(1, location.state.editpost.requisition.id);
      setSearchResume("");
    }
  };

  useEffect(() => {
    getPendingCandidateResume(1, location.state.editpost.requisition.id);
  }, [pendingCandidateResumeEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Candidate applied for{" "}
              {location.state?.editpost?.job_opening?.job_title || "-"}
            </h3>
            {isPendingResumeLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchResume}
                      className="form-control"
                      placeholder="Search by candidate name,email and tag..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Email-ID</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile no.</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Tags</span>
                      </div>

                      {privileges.includes("rc_candidate_resume_update") ? (
                        <div className="nk-tb-col">
                          <span>Resume</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                    {rawPendingCandidateResume.length !== 0 ? (
                      rawPendingCandidateResume.map((data, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.candidate_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.email || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {data?.mobile || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">{data?.tags || "-"}</span>
                            </span>
                          </div>

                          {privileges.includes("rc_candidate_resume_update") ? (
                            <div className="nk-tb-col">
                              <span className="tb-sub">
                                {data?.resume ? (
                                  <NavLink
                                    to="/viewrccandidateresume"
                                    className="btn btn-primary"
                                    id="button-button-viewresume"
                                    href={data?.resume}
                                    onClick={() =>
                                      getRCCandidateResume(
                                        location.state.editpost.requisition.id
                                      )
                                    }
                                    state={{
                                      jobID:
                                        location.state.editpost.requisition.id,
                                      candidateID: data.id,
                                    }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ color: "white" }}
                                    ></em>
                                  </NavLink>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isPendingResumeData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={pendingCandidateResumePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pendingCandidateResumePageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handlePendingCandidateResumeClick(data, searchResume)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {pendingCandidateResumeCount === null
                          ? 0
                          : pendingCandidateResumeCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={pendingCandidateResumeEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RCCandidateResume;
