import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserDetailsContext } from "../../Context/UserDetailsContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const UserManagementCreate = (props) => {
  const { getUserDetailsData } = useContext(UserDetailsContext);

  const {
    roleDropdownData,
    isGenricDropdownDataLoading,
    getGenericParamsDropdownData,
  } = useContext(GenericDropdownContext);

  const userID = secureLocalStorage.getItem("userID");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const privileges = secureLocalStorage.getItem("privileges");

  const navigate = useNavigate();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPassToggle, setConfirmPassToggle] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isUsernameValid, setIsUsernameValid] = useState(true);
  console.log(isUsernameValid);

  const validatePasswordMatch = (value) => {
    const password = watch("password");
    return value === password || "Password does not match";
  };

  const handleUserNameChange = (event) => {
    const value = event.target.value;
    setIsUsernameValid(value === value.toLowerCase());
  };

  const onSubmit = (data) => {
    let user = new FormData();
    user.append("first_name", data.first_name);
    user.append("last_name", data.last_name);
    user.append("username", data.username);
    user.append("email", data.email);
    user.append("mobile", data.mobile_number);
    user.append("password", data.password);
    user.append("confirm_password", data.confirm_pwd);
    user.append("configurable_privileges", "");
    user.append(
      "role",
      data.role.map((val) => val.value)
    );
    user.append("portal_unique_id", VARIABLES.rms_login);
    user.append("created_by_id", userID);

    console.log(user);
    for (var pair of user.entries()) {
      console.log(pair);
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/signup/",
      // headers: {
      //   'Content-Type': 'application/octet-stream',
      // },
      data: user,
    };

    setIsLoading(true);
    axios(config)
      .then((response) => {
        setIsLoading(false);
        console.log(response.status);
        console.log(JSON.stringify(response.data));
        getGenericParamsDropdownData();
        getUserDetailsData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "User created successfully",
        });
        setTimeout(() => {
          navigate("/usermanagement");
        }, 2000);
        reset();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        const resStatus = error.response.status;
        if (resStatus == 403) {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: error.response?.data?.message,
          });
        } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to create user",
          });
        }
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> User Management </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-first_name"
                      placeholder="Enter First Name"
                      {...register("first_name", {
                        required: true,
                        pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.first_name && (
                      <span className="fs-8 text-danger">
                        First Name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-last_name"
                      placeholder="Enter Last Name"
                      {...register("last_name", {
                        required: true,
                        pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.last_name && (
                      <span className="fs-8 text-danger">
                        Last Name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Username <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-username"
                      placeholder="Enter Username"
                      {...register("username", {
                        required: true,
                        pattern: /^[^\s]+$/,
                      })}
                      onChange={(e) => handleUserNameChange(e)}
                    />
                    {isUsernameValid == false && (
                      <span className="fs-8 text-danger">
                        Username should only contain lowercase letters
                      </span>
                    )}
                    {errors.username && errors.username.type === "required" && (
                      <span className="fs-8 text-danger">
                        Username is required
                      </span>
                    )}
                    {errors.username && errors.username.type === "pattern" && (
                      <span className="fs-8 text-danger">
                        Username should not contain spaces
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email-forms-email"
                      placeholder="Enter Email"
                      {...register("email", { required: true })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span className="fs-8 text-danger">
                        Email is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number-forms-mobile_number"
                      placeholder="Enter Mobile Number"
                      {...register("mobile_number", {
                        required: true,
                        maxLength: 10,
                        minLength: 10,
                      })}
                    />
                    {errors.mobile_number &&
                      errors.mobile_number.type === "required" && (
                        <span className="fs-8 text-danger">
                          Mobile number is required
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "minLength" && (
                        <span className="fs-8 text-danger">
                          Mobile number should be at least 10 digits
                        </span>
                      )}
                    {errors.mobile_number &&
                      errors.mobile_number.type === "maxLength" && (
                        <span className="fs-8 text-danger">
                          Mobile number should not exceed 10 digits
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Password<span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password-forms-password"
                      placeholder="Enter Password"
                      {...register("password", { required: true })}
                    />
                    {errors.password && (
                      <span className="fs-8 text-danger">
                        Password is required
                      </span>
                    )}
                    {watch("password") && watch("password").length < 8 && (
                      <span className="fs-8 text-danger">
                        Password must be at least 8 characters
                      </span>
                    )}
                    {watch("password") &&
                      watch("password").length > 8 &&
                      /^\d+$/.test(watch("password")) && (
                        <span className="fs-8 text-danger">
                          Password must be alpha-numeric
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Confirm Password<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type={`${
                          confirmPassToggle === true ? "text" : "password"
                        }`}
                        className="form-control"
                        id="password-forms-confirm_pwd"
                        placeholder="Enter Password"
                        {...register("confirm_pwd", {
                          required: true,
                          validate: validatePasswordMatch,
                        })}
                      />
                      <span
                        className="input-group-text"
                        onClick={() => setConfirmPassToggle(!confirmPassToggle)}
                        id="basic-addon2"
                      >
                        <i
                          className={`${
                            confirmPassToggle === true
                              ? "icon ni ni-eye"
                              : "icon ni ni-eye-off"
                          }`}
                        ></i>
                      </span>
                    </div>
                    {/* {errors.confirm_pwd && (
                      <span className="fs-8 text-danger">
                        Confirm Password is required
                      </span>
                    )} */}
                    {errors.confirm_pwd && (
                      <span className="fs-8 text-danger">
                        {errors.confirm_pwd.message ||
                          "Confirm Password is required"}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Role <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={roleDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.role_name,
                          }))}
                          isClearable={true}
                          isLoading={isGenricDropdownDataLoading}
                          isMulti
                          id="select-forms-role"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="role"
                      control={control}
                    />
                    {errors.role && (
                      <span className="fs-8 text-danger">Role is required</span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {isLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createuser"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserManagementCreate;
