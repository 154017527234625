import axios from "axios";
import React, { useState, createContext } from "react";
import { VARIABLES } from "../Constants";

export const ActivityLogsContext = createContext(null);

function ActivityLogsProvider({ children }) {
  // To store Candidate Resume Data for Activity Logs.
  const [isResumePerReqData, setIsResumePerReqData] = useState(false);
  const [rawCandidateResumePerReq, setRawCandidateResumePerReq] = useState([]);
  const [candidateResumePerReqEntries, setCandidateResumePerReqEntries] =
    useState(5);
  const [candidateResumePerReqCount, setCandidateResumePerReqCount] =
    useState(null);
  const [candidateResumePerReqPageCount, setCandidateResumePerReqPageCount] =
    useState(0);
  const [isResumePerReqLoading, setIsResumePerReqLoading] = useState(false);

  // To store Candidate Logs .
  const [rawCandidateTimeline, setRawCandidateTimeline] = useState([]);
  const [isCandidateTimelineLoading, setIsCandidateTimelineLoading] =
    useState(false);
  const [isCandidateTimelineData, setIsCandidateTimelineData] = useState(false);
  const [candidateTimelineEntries, setCandidateTimelineEntries] = useState(5);
  const [candidateTimelineCount, setCandidateTimelineCount] = useState(null);
  const [candidateTimelinePageCount, setCandidateTimelinePageCount] =
    useState(0);

  // To store Requisition Logs .
  const [rawRequisitionTimeline, setRawRequisitionTimeline] = useState([]);
  const [isRequisitionTimelineLoading, setIsRequisitionTimelineLoading] =
    useState(false);
  const [isRequisitionTimelineData, setIsRequisitionTimelineData] =
    useState(false);
  const [requisitionTimelineEntries, setRequisitionTimelineEntries] =
    useState(5);
  const [requisitionTimelineCount, setRequisitionTimelineCount] =
    useState(null);
  const [requisitionTimelinePageCount, setRequisitionTimelinePageCount] =
    useState(0);

  // "getCandidateResumePerReq" API call to get resume data Activity Logs
  const getCandidateResumePerReq = async (page, JobID, searchResume) => {
    setIsResumePerReqLoading(true);
    console.log(searchResume);
    let url;
    if (searchResume) {
      url =
        VARIABLES.url +
        `/api/candidateresume/?entries=${candidateResumePerReqEntries}&page=${page}&event=${searchResume}&requisition_id=${JobID}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?entries=${candidateResumePerReqEntries}&page=${page}&requisition_id=${JobID}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsResumePerReqLoading(false);
      if (response.length !== 0) {
        setIsResumePerReqData(false);
        setRawCandidateResumePerReq(res.data.data);
      } else {
        setRawCandidateResumePerReq(res.data.data);
        setIsResumePerReqData(true);
      }
      const total = res.data.count;
      setCandidateResumePerReqCount(total);
      setCandidateResumePerReqPageCount(
        Math.ceil(total / candidateResumePerReqEntries)
      );
    } catch (error) {
      console.log(error);
      setIsResumePerReqLoading(false);
      setIsResumePerReqData(true);
    }
  };

  const handleCandidateResumePerReqClick = async (
    data,
    JobID,
    searchResume
  ) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateResumePerReq(
      currentPage,
      JobID,
      searchResume
    );
  };

  const getRequisitionTimelineData = async (page, JobID, searchStage) => {
    setIsRequisitionTimelineLoading(true);
    let url;
    if (searchStage) {
      url =
        VARIABLES.url +
        `/api/requisition-activity-log/?requisition_id=${JobID}&entries=${requisitionTimelineEntries}&page=${page}&event=${searchStage}`;
    } else {
      url =
        VARIABLES.url +
        `/api/requisition-activity-log/?requisition_id=${JobID}&entries=${requisitionTimelineEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRequisitionTimelineLoading(false);
      if (response.length !== 0) {
        setIsRequisitionTimelineData(false);
        setRawRequisitionTimeline(res.data.data);
      } else {
        setRawRequisitionTimeline(res.data.data);
        setIsRequisitionTimelineData(true);
      }
      const total = res.data.count;
      setRequisitionTimelineCount(total);
      setRequisitionTimelinePageCount(
        Math.ceil(total / requisitionTimelineEntries)
      );
    } catch (error) {
      console.log(error);
      setIsRequisitionTimelineLoading(false);
      setIsRequisitionTimelineData(true);
    }
  };

  const handleRequisitionTimelineClick = async (data, JobID, searchStage) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRequisitionTimelineData(
      currentPage,
      JobID,
      searchStage
    );
  };

  const getCandidateTimelineData = async (page, ResumeID, searchStage) => {
    setIsCandidateTimelineLoading(true);
    let url;
    if (searchStage !== undefined) {
      url =
        VARIABLES.url +
        `/api/candidatestatus/?resume_master_id=${ResumeID}&entries=${requisitionTimelineEntries}&page=${page}&event=${searchStage}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidatestatus/?resume_master_id=${ResumeID}&entries=${requisitionTimelineEntries}&page=${page}`;
    }
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsCandidateTimelineLoading(false);
      if (response.length !== 0) {
        setIsCandidateTimelineData(false);
        setRawCandidateTimeline(res.data.data);
      } else {
        setRawCandidateTimeline(res.data.data);
        setIsCandidateTimelineData(true);
      }
      const total = res.data.count;
      setCandidateTimelineCount(total);
      setCandidateTimelinePageCount(
        Math.ceil(total / candidateTimelineEntries)
      );
    } catch (error) {
      console.log(error);
      setIsCandidateTimelineLoading(false);
      setIsCandidateTimelineData(true);
    }
  };

  const handleCandidateTimelineClick = async (data, ResumeID, searchStage) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateTimelineData(
      currentPage,
      ResumeID,
      searchStage
    );
  };

  return (
    <div>
      <ActivityLogsContext.Provider
        value={{
          rawCandidateResumePerReq,
          getCandidateResumePerReq,
          candidateResumePerReqEntries,
          candidateResumePerReqPageCount,
          setCandidateResumePerReqEntries,
          candidateResumePerReqCount,
          handleCandidateResumePerReqClick,
          isResumePerReqData,
          isResumePerReqLoading,

          rawCandidateTimeline,
          isCandidateTimelineLoading,
          isCandidateTimelineData,
          candidateTimelineEntries,
          candidateTimelineCount,
          candidateTimelinePageCount,
          getCandidateTimelineData,
          setCandidateTimelineEntries,
          handleCandidateTimelineClick,

          rawRequisitionTimeline,
          isRequisitionTimelineData,
          isRequisitionTimelineLoading,
          requisitionTimelineEntries,
          requisitionTimelineCount,
          requisitionTimelinePageCount,
          getRequisitionTimelineData,
          setRequisitionTimelineEntries,
          handleRequisitionTimelineClick,
        }}
      >
        {children}
      </ActivityLogsContext.Provider>
    </div>
  );
}

export default ActivityLogsProvider;
