import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { VARIABLES } from "../../Constants";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import Swal from "sweetalert2";

const RequestAcceptance = () => {
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawRequisitionData,
    getRequestedRequisitionData,
    requisitionEntries,
    requisitionPageCount,
    setRequisitionEntries,
    requisitionCount,
    handleRequisitionPageClick,
    isRequisitionData,
    isRequisitionLoading,
  } = useContext(RequestedJobOpeningContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const [submitMessage, setSubmitMessage] = useState(null);

  const handleEntries = (event) => {
    setRequisitionEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRequestedRequisitionData(1, searchRequest);
    } else {
      getRequestedRequisitionData(1);
      setSearchRequest("");
    }
  };

  const handleAcceptReject = (is_accepted, post) => {
    let date = new Date();
    console.log(date);
    console.log(post);
    var user = JSON.stringify({
      requisition_acceptance: is_accepted,
      requisition_acceptance_by_id: userID,
      requisition_acceptance_on: date,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/requestrequisition/${post.id}/?type=requisition_approval`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        getRequestedRequisitionData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: `Requisition ${
            is_accepted ? "accepted" : "rejected"
          } successfully`,
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: `Failed to ${is_accepted ? "accept" : "reject"} requisition`,
        });
      });
  };

  const AcceptRejectAlert = (is_accepted, post) => {
    Swal.fire({
      text: `Are you sure you want to ${
        is_accepted ? "accept" : "reject"
      } this requisition?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleAcceptReject(is_accepted, post);
      }
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    getRequestedRequisitionData(1);
  }, [requisitionEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Requisition Approval</h3>
            {isRequisitionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("requisition_approval_filter") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/requestacceptance/viewallrequisition"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-viewallrequisition"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/requestacceptance/viewallrequisition"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-viewallrequisition"
              >
                <em className="icon ni ni-eye"></em>
                <span>View all requisition</span>
              </NavLink>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search by requisition id, job title..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Vacancy</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Active/ Inactive</span>
                      </div>

                      {privileges.includes("requisition_approval_update") ? (
                        <div className="nk-tb-col tb-col-md">
                          <span>Accept/ Reject</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {["job_opening_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <div className="nk-tb-col">
                          <span>Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawRequisitionData.length !== 0 ? (
                      rawRequisitionData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name + post?.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post.job_opening?.department
                                ?.department_display_name || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.job_type || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.no_of_vacancy || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post.status === 1 ? (
                                <span class="badge bg-success">Active</span>
                              ) : (
                                <span class="badge bg-danger">Inactive</span> ||
                                "-"
                              )}
                            </span>
                          </div>

                          {privileges.includes(
                            "requisition_approval_update"
                          ) ? (
                            <div className="nk-tb-col">
                              {post?.requisition_acceptance !== null ? (
                                <>
                                  {post.requisition_acceptance === true ? (
                                    <span class="badge bg-success">
                                      Accepted
                                    </span>
                                  ) : (
                                    <span class="badge bg-danger">
                                      Rejected
                                    </span>
                                  )}
                                </>
                              ) : (
                                <div className="d-flex">
                                  <button
                                    onClick={(e) => {
                                      AcceptRejectAlert(true, post);
                                    }}
                                    className="btn btn-white btn-dim btn-outline-success"
                                    id="button-button-acceptresume"
                                  >
                                    <em className="icon ni ni-check"></em>
                                  </button>

                                  <button
                                    onClick={(e) => {
                                      AcceptRejectAlert(false, post);
                                    }}
                                    className="btn btn-white btn-dim btn-outline-danger"
                                    id="button-button-rejectresume"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            " "
                          )}

                          {["job_opening_retrieve"].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="me-n1">
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0)"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                      id="list-dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        {privileges.includes(
                                          "job_opening_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/jobopeningdetails"}
                                              state={{ editpost: post }}
                                              id="button-button-jobopeningdetails"
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>
                                                View Requisition Details
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isRequisitionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={requisitionPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={requisitionPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleRequisitionPageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {requisitionCount === null ? 0 : requisitionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={requisitionEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestAcceptance;
