import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const LoginContext = createContext(null);

function LoginContextProvider({ children }) {
  // Google authentication
  const [user, setUser] = useState([]);
  console.log(user)
  const [profile, setProfile] = useState([]);
  console.log(profile)
  const [privilege, setPrivilege] = useState([]);

  const getPrivileges = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/privileges/");
      console.log(res.data.data);
      setPrivilege(
        res.data.data.map((pro) => ({
          value: pro.id,
          label: pro.privilege_name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const value = { privilege, getPrivileges,user,setUser,profile,setProfile };
  return (
    <div>
      <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
    </div>
  );
}

export default LoginContextProvider;
