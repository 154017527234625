import React, { useContext, useEffect, useState } from "react";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import CandidatePoolResumes from "../CandidatePoolResumes/CandidatePoolResumes";
import { useLocation } from "react-router-dom";

const ViewSMECandidatesResume = () => {
  const { rawSMEResumeData, isSMEResumeLoading, getAllCandidateResume } =
    useContext(ViewResumeContext);
  console.log("rawSMEResumeData:", rawSMEResumeData);

  const location = useLocation();

  const [submitMessage, setSubmitMessage] = useState(null);

  const [sortedSMEResumeData, setSortedSMEResumeData] = useState([null]);
  console.log("sortedSMEResumeData: ", sortedSMEResumeData);

  useEffect(() => {
    const objectWithID4 = rawSMEResumeData.find((obj) => obj.id === location.state.candidateID);

    const reorderedResponse = rawSMEResumeData.filter((obj) => obj.id !== location.state.candidateID);

    reorderedResponse.unshift(objectWithID4);

    console.log(reorderedResponse);
    setSortedSMEResumeData(reorderedResponse);
  }, [rawSMEResumeData, location.state.candidateID]);

  return (
    <>
      <CandidatePoolResumes
        rawResumeData={sortedSMEResumeData}
        isLoading={isSMEResumeLoading}
        getResumeData={getAllCandidateResume}
        jobID={location.state.jobID}
        setSubmitMessage={setSubmitMessage}
        submitMessage={submitMessage}
        shortlisting_type = {"SME"}
      />
    </>
  );
};

export default ViewSMECandidatesResume;

