import React, { useEffect } from "react";
import { Route, Routes, Redirect, Navigate } from "react-router-dom";
import AddRequisition from "../Pages/RequestedJobOpening/AddRequisition";
import RequestedJobOpening from "../Pages/RequestedJobOpening/RequestedJobOpening";
import EditRequested from "../Pages/RequestedJobOpening/EditRequested";
import WriteRequestedJD from "../Pages/RequestedJD/WriteRequestedJD";
import EditSeo from "../Pages/SEO/EditSeo";
import UserManagement from "../Pages/UserManagement/UserManagement";
import CandidateApplied from "../Pages/CandidateApplied/CandidateApplied";
import ViewResume from "../Pages/CandidateApplied/ViewResume";
import EducationalDetails from "../Pages/Stepper/CandidateDetails/EducationalDetails";
import ProfessionalDetails from "../Pages/Stepper/CandidateDetails/ProfessionalDetails";
import ShortlistedCandidate from "../Pages/CandidateApplied/ShortlistedCandidate";
import ScheduleInterviewCreate from "../Pages/ScheduleInterview/ScheduleInterviewCreate";
import InterviewQuestionnaire from "../Pages/InterviewQuestionnaire/InterviewQuestionnaire";
import MettleTest from "../Pages/Stepper/MettleTest/MettleTest";
import AssignAssignment from "../Pages/Stepper/AssignAssignment/AssignAssignment";
import AssignmentReview from "../Pages/Stepper/AssignmentReview/AssignmentReview";
import MettleTestReview from "../Pages/Stepper/MettleTestReview/MettleTestReview";
import MDApproval from "../Pages/Stepper/MDApproval/MDApproval";
import SendOffer from "../Pages/Stepper/SendOffer/SendOffer";
// import WorkFlowCreate from '../Pages/Workflow/WorkFlowCreate'
import TechnicalInterviewRound from "../Pages/Stepper/TechnicalInterviewRound/TechnicalInterviewRound";
import JobAssignmentCreate from "../Pages/JobAssignment/JobAssignmentCreate";
import JobAssignment from "../Pages/JobAssignment/JobAssignment";
import JobAssignmentEdit from "../Pages/JobAssignment/JobAssignmentEdit";
import InterviewQuestionnaireCreate from "../Pages/InterviewQuestionnaire/InterviewQuestionnaireCreate";
import InterviewQuestionnaireEdit from "../Pages/InterviewQuestionnaire/InterviewQuestionnaireEdit";
import UserManagementCreate from "../Pages/UserManagement/UserManagementCreate";
import Roles from "../Pages/Settings/Roles/Roles";
import RolesCreate from "../Pages/Settings/Roles/RolesCreate";
import RolesEdit from "../Pages/Settings/Roles/RolesEdit";
import TypeMaster from "../Pages/Settings/TypeMaster/TypeMaster";
import TypeMasterCreate from "../Pages/Settings/TypeMaster/TypeMasterCreate";
import TypeMasterEdit from "../Pages/Settings/TypeMaster/TypeMasterEdit";
import ScheduleInterviewDetails from "../Pages/ScheduleInterview/ScheduleInterviewDetails";
import StepperCandidateDetails from "../Pages/StepperCandidateDetails/StepperCandidateDetails";
import WorkFlowDemo from "../Pages/Workflow/WorflowDemo";
import WorkFlowCreate from "../Pages/Workflow/WorkFlowCreate";
import WorkFlowEdit from "../Pages/Workflow/WorkflowEdit";
import CandidateResumeCreate from "../Pages/CandidateResume/CandidateResumeCreate";
import CandidateResumeEdit from "../Pages/CandidateResume/CandidateResumeEdit";
import RequestedJDNew from "../Pages/RequestedJD/RequestedJDNew";
import AddJobAssignment from "../Pages/RequestedJD/AddJobAssignment";
import ViewAllResume from "../Pages/CandidateApplied/ViewAllResume";

import Department from "../Pages/Department/Department";
import CreateDepartment from "../Pages/Department/CreateDepartment";
import EditDepartment from "../Pages/Department/EditDepartment";
import Team from "../Pages/Team/Team";
import CreateTeam from "../Pages/Team/CreateTeam";
import EditTeam from "../Pages/Team/EditTeam";
import CreatePosition from "../Pages/Position/CreatePosition";
import EditPosition from "../Pages/Position/EditPosition";
import Position from "../Pages/Position/Position";
import Privilege from "../Pages/Settings/Privileges/Privilege";
import EditPrivilege from "../Pages/Settings/Privileges/EditPrivilege";
import JobWorkflowDemo from "../Pages/JobWorkflow/JobWorkflowDemo";
import Seniority from "../Pages/Seniority/Seniority";
import CreateSeniority from "../Pages/Seniority/CreateSeniority";
import EditSeniority from "../Pages/Seniority/EditSeniority";
import CandidateResumeTable from "../Pages/CandidateResume/CandidateResume";

import BackgroundVerification from "../Pages/Stepper/BackgroundVerification/BackgroundVerificationForm";
import AgencyMaster from "../Pages/AgencyMaster/AgencyMaster";
import CreateAgencyMaster from "../Pages/AgencyMaster/CreateAgencyMaster";
import EditAgencyMaster from "../Pages/AgencyMaster/EditAgencyMaster";
import ResumePoolAdvanceFilter from "../Pages/ResumePoolAdvanceFilter/ResumePoolAdvanceFilter";
import Tag from "../Pages/Tag/Tag";
import CreateTag from "../Pages/Tag/CreateTag";
import EditTag from "../Pages/Tag/EditTag";
import TagCategory from "../Pages/TagCategory/TagCategory";
import CreateTagCategory from "../Pages/TagCategory/CreateTagCategory";
import EditTagCategory from "../Pages/TagCategory/EditTagCategory";
import RequestAcceptance from "../Pages/RequestAcceptance/RequestAcceptance";
import Seo from "../Pages/SEO/Seo";
import Tam from "../Pages/TAM/Tam";
import RequisitionAssignedRE from "../Pages/TAM/RequisitionAssignedRE";
import RecruitmentCordinator from "../Pages/Recruitment Cordinator/RecruitmentCordinator";
import InterviewerSME from "../Pages/InterviewerSME/InterviewerSME";
import RE1 from "../Pages/RE-1/RE1";
import RE3 from "../Pages/RE-3/RE3";
import RE2Create from "../Pages/RE-2/RE2Create";
import RE2 from "../Pages/RE-2/RE2";
import secureLocalStorage from "react-secure-storage";
import JobOpeningDetails from "../Pages/JobOpeningDetails/JobOpeningDetails";
import ViewRCCandidatesResume from "../Pages/Recruitment Cordinator/ViewRCCandidatesResume";
import ViewSMECandidatesResume from "../Pages/InterviewerSME/ViewSMECandidatesResume";
import NotFound from "../Pages/ErrorPage/NotFound";
import StepperForm from "../Pages/WorkflowStepperForm/StepperForm";
import RERequisitionDetails from "../Pages/JobOpeningDetails/RERequisitionDetails";
import ScheduleCandidateInterview from "../Pages/ScheduleCandidateInterview/ScheduleCandidateInterview";
import ScheduleCandidateView from "../Pages/ScheduleCandidateInterview/ScheduleCandidateView";
import ScheduleCalendar from "../Pages/ScheduleCandidateInterview/ScheduleCalendar";
import ViewAllRequisition from "../Pages/RequestAcceptance/ViewAllRequisition";
import HRInterviewRound from "../Pages/Stepper/HRInterviewRound/HRInterviewRound";
import SmartHireForm from "../Pages/Stepper/SmartHireForm/SmartHireForm";
import RCandidateApplied from "../Pages/Recruitment Cordinator/RCCandidateApplied";
import SMECandidateResume from "../Pages/InterviewerSME/SMECandidteApplied";
import TelephonicRound from "../Pages/Stepper/TelephonicInterviewRound/TelephonicRound";
import KYC from "../Pages/Stepper/KYC/KYC";
import HRD from "../Pages/HRDApproval/HRD";
import HRDApproval from "../Pages/Stepper/HRDApproval/HRDApproval";
import ResumeDownload from "../Pages/ResumeDownload/ResumeDownload";
import ActivityLogs from "../Pages/ActivityLogs/ActivityLogs";
import CandidatesAppliedForReq from "../Pages/ActivityLogs/CandidatesAppliedForReq";
import CandidateTimeline from "../Pages/ActivityLogs/CandidateTimeline";
import RequisitionTimeline from "../Pages/ActivityLogs/RequisitionTimeline";
import PersonalDetails from "../Pages/Stepper/CandidateDetails/PersonalDetails";
import TechnicalInterviewReview from "../Pages/Stepper/TechnicalInterviewRound/TechnicalInterviewReview";
import SmartHireFormReview from "../Pages/Stepper/SmartHireForm/SmartHireFormReview";
import TelephonicInterviewReview from "../Pages/Stepper/TelephonicInterviewRound/TelephonicInterviewReview";
import CandidateEntries from "../Pages/CandidateEntries/CandidateEntries";
import CandidateOtherDetails from "../Pages/CandidateEntries/CandidateOtherDetails";

const Content = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const role = secureLocalStorage.getItem("role");
  console.log(role);

  return (
    <>
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <Routes>
              <Route path="*" element={<NotFound />} />

              {/* Dashboard/ Landing Page Starts */}
              {privileges.includes("requisition_retrieve") ? (
                <Route exact path="/" element={<RequestedJobOpening />} />
              ) : (
                <Route path="/" element={<NotFound />} />
              )}

              {privileges.includes("requisition_create") ? (
                <Route
                  exact
                  path="/addrequestrequisition"
                  element={<AddRequisition />}
                />
              ) : (
                <Route path="/addrequestrequisition" element={<NotFound />} />
              )}

              {privileges.includes("requisition_update") ? (
                <Route path="/editrequest" element={<EditRequested />} />
              ) : (
                <Route path="/editrequest" element={<NotFound />} />
              )}

              {privileges.includes("job_opening_retrieve") ? (
                <Route
                  path="/jobopeningdetails"
                  element={<JobOpeningDetails />}
                />
              ) : (
                <Route path="/jobopeningdetails" element={<NotFound />} />
              )}

              {privileges.includes("candidate_details_retrieve") ? (
                <Route
                  path="/candidateapplied"
                  element={<CandidateApplied />}
                />
              ) : (
                <Route path="/candidateapplied" element={<NotFound />} />
              )}

              {privileges.includes("candidate_entries_retrieve") ? (
                <Route path="/candidateentries" element={<CandidateEntries />} />
              ) : (
                <Route path="/candidateentries" element={<NotFound />} />
              )}

              {privileges.includes("candidate_entries_details") ? (
                <Route path="/candidateotherdetails" element={<CandidateOtherDetails />} />
              ) : (
                <Route path="/candidateotherdetails" element={<NotFound />} />
              )}

              {privileges.includes("shortlist_details_retrieve") ? (
                <Route
                  path="/viewshortlisted"
                  element={<ShortlistedCandidate />}
                />
              ) : (
                <Route path="/viewshortlisted" element={<NotFound />} />
              )}

              {privileges.includes("requisition_approval_retrieve") ? (
                <Route
                  path="/requestacceptance"
                  element={<RequestAcceptance />}
                />
              ) : (
                <Route path="/requestacceptance" element={<NotFound />} />
              )}

              {/* NEW-S */}
              {privileges.includes("requisition_approval_filter") ? (
                <Route
                  path="/requestacceptance/viewallrequisition"
                  element={<ViewAllRequisition />}
                />
              ) : (
                <Route path="/viewallrequisition" element={<NotFound />} />
              )}
              {/* NEW-E */}

              {privileges.includes("requisition_tam_retrieve") ? (
                <Route path="/tam" element={<Tam />} />
              ) : (
                <Route path="/tam" element={<NotFound />} />
              )}

              {privileges.includes("requisition_user_assign_update") ? (
                <Route path="/assignedrc" element={<RequisitionAssignedRE />} />
              ) : (
                <Route path="/assignedrc" element={<NotFound />} />
              )}

              {privileges.includes("requisition_re1_retrieve") ? (
                <Route path="/re1" element={<RE1 />} />
              ) : (
                <Route path="/re1" element={<NotFound />} />
              )}

              {privileges.includes("requisition_re2_retrieve") ? (
                <Route path="/re2" element={<RE2 />} />
              ) : (
                <Route path="/re2" element={<NotFound />} />
              )}

              {privileges.includes("requisition_re2_resume_upload") ? (
                <Route path="/re2create" element={<RE2Create />} />
              ) : (
                <Route path="/re2create" element={<NotFound />} />
              )}

              {privileges.includes("requisition_re3_retrieve") ? (
                <Route path="/re3" element={<RE3 />} />
              ) : (
                <Route path="/re3" element={<NotFound />} />
              )}

              {privileges.includes("seo_retrieve") ? (
                <Route path="/seo" element={<Seo />} />
              ) : (
                <Route path="/seo" element={<NotFound />} />
              )}

              {privileges.includes("seo_update") ? (
                <Route exact path="/editseo" element={<EditSeo />} />
              ) : (
                <Route path="/editseo" element={<NotFound />} />
              )}

              {privileges.includes("rc_shortlisting_retrieve") ? (
                <Route
                  path="/recruitmentcordinator"
                  element={<RecruitmentCordinator />}
                />
              ) : (
                <Route path="/recruitmentcordinator" element={<NotFound />} />
              )}

              {privileges.includes("rc_candidate_resume_update") ? (
                <Route
                  path="/viewrccandidateresume"
                  element={<ViewRCCandidatesResume />}
                />
              ) : (
                <Route path="/viewrccandidateresume" element={<NotFound />} />
              )}

              {privileges.includes("candidate_details_retrieve") ? (
                <Route
                  path="/rccandidateapplied"
                  element={<RCandidateApplied />}
                />
              ) : (
                <Route path="/rccandidateapplied" element={<NotFound />} />
              )}

              {privileges.includes("view_all_resume") ? (
                <Route path="/viewallresume" element={<ViewAllResume />} />
              ) : (
                <Route path="/viewallresume" element={<NotFound />} />
              )}

              {privileges.includes("sme_shortlisting_retrieve") ? (
                <Route path="/interviewersme" element={<InterviewerSME />} />
              ) : (
                <Route path="/interviewersme" element={<NotFound />} />
              )}

              {privileges.includes("sme_candidate_resume_update") ? (
                <Route
                  path="/viewsmecandidateresume"
                  element={<ViewSMECandidatesResume />}
                />
              ) : (
                <Route path="/viewsmecandidateresume" element={<NotFound />} />
              )}

              {privileges.includes("candidate_details_retrieve") ? (
                <Route
                  path="/smecandidateapplied"
                  element={<SMECandidateResume />}
                />
              ) : (
                <Route path="/smecandidateapplied" element={<NotFound />} />
              )}

              {privileges.includes("resume_pool_advance_filter") ? (
                <Route
                  path="/resumepooladvancefilter"
                  element={<ResumePoolAdvanceFilter />}
                />
              ) : (
                <Route path="/resumepooladvancefilter" element={<NotFound />} />
              )}

              {privileges.includes("hrd_eagle_eye_view") ? (
                <Route path="/hrdapproval" element={<HRD />} />
              ) : (
                <Route path="/hrdapproval" element={<NotFound />} />
              )}

              {privileges.includes("activity_logs") ? (
                <Route path="/activitylogs" element={<ActivityLogs />} />
              ) : (
                <Route path="/activitylogs" element={<NotFound />} />
              )}

              {privileges.includes("requisition_activity_logs") ? (
                <Route
                  path="/requisitiontimeline"
                  element={<RequisitionTimeline />}
                />
              ) : (
                <Route path="/requisitiontimeline" element={<NotFound />} />
              )}

              {privileges.includes("candidate_activity_logs") ? (
                <Route
                  path="/candidatetimeline"
                  element={<CandidateTimeline />}
                />
              ) : (
                <Route path="/candidatetimeline" element={<NotFound />} />
              )}

              {privileges.includes("candidate_details_retrieve") ? (
                <Route
                  path="/candidateappliedforreq"
                  element={<CandidatesAppliedForReq />}
                />
              ) : (
                <Route path="/candidateappliedforreq" element={<NotFound />} />
              )}

              {privileges.includes("schedule_candidate_interview") ? (
                <Route
                  path="/schedulecandidateinterview"
                  element={<ScheduleCandidateInterview />}
                />
              ) : (
                <Route
                  path="/schedulecandidateinterview"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("accepted_candidate_details") ? (
                <Route
                  path="/schedulecandidateview"
                  element={<ScheduleCandidateView />}
                />
              ) : (
                <Route path="/schedulecandidateview" element={<NotFound />} />
              )}

              {privileges.includes("view_calendar") ? (
                <Route
                  path="/schedulecalendar"
                  element={<ScheduleCalendar />}
                />
              ) : (
                <Route path="/schedulecalendar" element={<NotFound />} />
              )}

              {privileges.includes("schedule_interview") ? (
                <Route
                  path="/scheduleinterviewcreate"
                  element={<ScheduleInterviewCreate />}
                />
              ) : (
                <Route path="/scheduleinterviewcreate" element={<NotFound />} />
              )}

              {privileges.includes("schedule_interview_retrieve") ? (
                <Route
                  path="/scheduleinterviewdetails"
                  element={<ScheduleInterviewDetails />}
                />
              ) : (
                <Route
                  path="/scheduleinterviewdetails"
                  element={<NotFound />}
                />
              )}

              {/* Dashboard/ Landing Page Ends */}

              {/* Masters Route starts */}
              {privileges.includes("usermanagement_retrieve") ? (
                <Route path="/usermanagement" element={<UserManagement />} />
              ) : (
                <Route path="/usermanagement" element={<NotFound />} />
              )}

              {privileges.includes("usermanagement_create") ? (
                <Route path="/createuser" element={<UserManagementCreate />} />
              ) : (
                <Route path="/createuser" element={<NotFound />} />
              )}

              {privileges.includes("department_retrieve") ? (
                <Route path="/department" element={<Department />} />
              ) : (
                <Route path="/department" element={<NotFound />} />
              )}

              {privileges.includes("department_create") ? (
                <Route path="/adddepartment" element={<CreateDepartment />} />
              ) : (
                <Route path="/adddepartment" element={<NotFound />} />
              )}

              {privileges.includes("department_update") ? (
                <Route path="/editDepartment" element={<EditDepartment />} />
              ) : (
                <Route path="/editDepartment" element={<NotFound />} />
              )}

              {privileges.includes("team_retrieve") ? (
                <Route path="/team" element={<Team />} />
              ) : (
                <Route path="/team" element={<NotFound />} />
              )}

              {privileges.includes("team_create") ? (
                <Route path="/addteam" element={<CreateTeam />} />
              ) : (
                <Route path="/addteam" element={<NotFound />} />
              )}

              {privileges.includes("team_update") ? (
                <Route path="/editteam" element={<EditTeam />} />
              ) : (
                <Route path="/editteam" element={<NotFound />} />
              )}

              {privileges.includes("position_retrieve") ? (
                <Route path="/position" element={<Position />} />
              ) : (
                <Route path="/position" element={<NotFound />} />
              )}

              {privileges.includes("position_create") ? (
                <Route path="/addposition" element={<CreatePosition />} />
              ) : (
                <Route path="/addposition" element={<NotFound />} />
              )}

              {privileges.includes("position_update") ? (
                <Route path="/editposition" element={<EditPosition />} />
              ) : (
                <Route path="/editposition" element={<NotFound />} />
              )}

              {privileges.includes("seniority_retrieve") ? (
                <Route path="/seniority" element={<Seniority />} />
              ) : (
                <Route path="/seniority" element={<NotFound />} />
              )}

              {privileges.includes("seniority_create") ? (
                <Route path="/createseniority" element={<CreateSeniority />} />
              ) : (
                <Route path="/createseniority" element={<NotFound />} />
              )}

              {privileges.includes("seniority_update") ? (
                <Route path="/editseniority" element={<EditSeniority />} />
              ) : (
                <Route path="/editseniority" element={<NotFound />} />
              )}

              {privileges.includes("agency_retrieve") ? (
                <Route path="/agencymaster" element={<AgencyMaster />} />
              ) : (
                <Route path="/agencymaster" element={<NotFound />} />
              )}

              {privileges.includes("agency_create") ? (
                <Route path="/addagency" element={<CreateAgencyMaster />} />
              ) : (
                <Route path="/addagency" element={<NotFound />} />
              )}

              {privileges.includes("agency_update") ? (
                <Route path="/editagency" element={<EditAgencyMaster />} />
              ) : (
                <Route path="/editagency" element={<NotFound />} />
              )}

              {/* {privileges.includes("tag_retrieve") ? (
                <Route path="/tag" element={<Tag />} />
              ) : (
                <Route path="/tag" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_create") ? (
                <Route path="/createtag" element={<CreateTag />} />
              ) : (
                <Route path="/createtag" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_update") ? (
                <Route path="/edittag" element={<EditTag />} />
              ) : (
                <Route path="/edittag" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_category_retrieve") ? (
                <Route path="/tagcategory" element={<TagCategory />} />
              ) : (
                <Route path="/tagcategory" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_category_create") ? (
                <Route
                  path="/createtagcategory"
                  element={<CreateTagCategory />}
                />
              ) : (
                <Route path="/createtagcategory" element={<NotFound />} />
              )} */}

              {/* {privileges.includes("tag_category_update") ? (
                <Route path="/edittagcategory" element={<EditTagCategory />} />
              ) : (
                <Route path="/edittagcategory" element={<NotFound />} />
              )} */}

              {privileges.includes("candidate_resume_retrieve") ? (
                <Route
                  path="/candidateresume"
                  element={<CandidateResumeTable />}
                />
              ) : (
                <Route path="/candidateresume" element={<NotFound />} />
              )}

              {privileges.includes("candidate_resume_create") ? (
                <Route
                  path="/createcandidateresume"
                  element={<CandidateResumeCreate />}
                />
              ) : (
                <Route path="/createcandidateresume" element={<NotFound />} />
              )}

              {privileges.includes("candidate_resume_update") ? (
                <Route
                  path="/editcandidateresume"
                  element={<CandidateResumeEdit />}
                />
              ) : (
                <Route path="/editcandidateresume" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_retrieve") ? (
                <Route path="/jobassignment" element={<JobAssignment />} />
              ) : (
                <Route path="/jobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_create") ? (
                <Route
                  path="/createjobassignment"
                  element={<JobAssignmentCreate />}
                />
              ) : (
                <Route path="/createjobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_update") ? (
                <Route
                  path="/editjobassignment"
                  element={<JobAssignmentEdit />}
                />
              ) : (
                <Route path="/editjobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_assignment_update") ? (
                <Route
                  path="/addjobassignment"
                  element={<AddJobAssignment />}
                />
              ) : (
                <Route path="/editjobassignment" element={<NotFound />} />
              )}

              {privileges.includes("job_opening_update") ? (
                <Route path="/writejd" element={<WriteRequestedJD />} />
              ) : (
                <Route path="/writejd" element={<NotFound />} />
              )}

              {privileges.includes("job_opening_retrieve") ? (
                <Route path="/jobdescription" element={<RequestedJDNew />} />
              ) : (
                <Route path="/jobdescription" element={<NotFound />} />
              )}

              {privileges.includes("interview_questionnair_retrieve") ? (
                <Route
                  path="/interviewquestionnaire"
                  element={<InterviewQuestionnaire />}
                />
              ) : (
                <Route path="/interviewquestionnaire" element={<NotFound />} />
              )}

              {privileges.includes("interview_questionnair_create") ? (
                <Route
                  path="/createinterviewquestionnaire"
                  element={<InterviewQuestionnaireCreate />}
                />
              ) : (
                <Route
                  path="/createinterviewquestionnaire"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("interview_questionnair_update") ? (
                <Route
                  path="/editinterviewquestionnaire"
                  element={<InterviewQuestionnaireEdit />}
                />
              ) : (
                <Route
                  path="/editinterviewquestionnaire"
                  element={<NotFound />}
                />
              )}
              {/* Masters Route Ends */}

              {/* Workflow Forms Starts */}

              {privileges.includes("stepper") ? (
                <Route path="/stepper" element={<StepperForm />} />
              ) : (
                <Route path="/stepper" element={<NotFound />} />
              )}

              {privileges.includes("Assign Assignment") ? (
                <Route
                  path="/assignassignment"
                  element={<AssignAssignment />}
                />
              ) : (
                <Route path="/assignassignment" element={<NotFound />} />
              )}

              {privileges.includes("Assignment Review") ? (
                <Route
                  path="/assignmentreview"
                  element={<AssignmentReview />}
                />
              ) : (
                <Route path="/assignmentreview" element={<NotFound />} />
              )}

              {privileges.includes("Test") ? (
                <Route path="/mettletest" element={<MettleTest />} />
              ) : (
                <Route path="/mettletest" element={<NotFound />} />
              )}

              {privileges.includes("Test Review") ? (
                <Route
                  path="/mettletestreview"
                  element={<MettleTestReview />}
                />
              ) : (
                <Route path="/mettletestreview" element={<NotFound />} />
              )}

              {privileges.includes("MD Approval") ? (
                <Route path="/mdapproval" element={<MDApproval />} />
              ) : (
                <Route path="/mdapproval" element={<NotFound />} />
              )}

              {privileges.includes("HRD Approval") ? (
                <Route path="/hrdapprovalform" element={<HRDApproval />} />
              ) : (
                <Route path="/hrdapprovalform" element={<HRDApproval />} />
              )}

              {privileges.includes("Send Offer") ? (
                <Route path="/sendoffer" element={<SendOffer />} />
              ) : (
                <Route path="/sendoffer" element={<NotFound />} />
              )}

              {privileges.includes("Technical Interview") ? (
                <Route
                  path="/technicalinterviewround"
                  element={<TechnicalInterviewRound />}
                />
              ) : (
                <Route path="/technicalinterviewround" element={<NotFound />} />
              )}

              {privileges.includes("Technical Interview Review") ? (
                <Route
                  path="/technicalinterviewreview"
                  element={<TechnicalInterviewReview />}
                />
              ) : (
                <Route
                  path="/technicalinterviewreview"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("HR Interview") ? (
                <Route
                  path="/hrinterviewround"
                  element={<HRInterviewRound />}
                />
              ) : (
                <Route path="/hrinterviewround" element={<NotFound />} />
              )}

              {privileges.includes("Telephonic Interview") ? (
                <Route
                  path="/telephonicinterviewround"
                  element={<TelephonicRound />}
                />
              ) : (
                <Route
                  path="/telephonicinterviewround"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("Telephonic Interview Review") ? (
                <Route
                  path="/telephonicinterviewreview"
                  element={<TelephonicInterviewReview />}
                />
              ) : (
                <Route
                  path="/telephonicinterviewreview"
                  element={<NotFound />}
                />
              )}

              {privileges.includes("Smart Hire Form") ? (
                <Route path="/smarthireform" element={<SmartHireForm />} />
              ) : (
                <Route path="/smarthireform" element={<NotFound />} />
              )}

              {privileges.includes("Smart Hire Form Review") ? (
                <Route
                  path="/smarthireformreview"
                  element={<SmartHireFormReview />}
                />
              ) : (
                <Route path="/smarthireformreview" element={<NotFound />} />
              )}

              {privileges.includes("Background Verification") ? (
                <Route
                  path="/backgroundverification"
                  element={<BackgroundVerification />}
                />
              ) : (
                <Route path="/backgroundverification" element={<NotFound />} />
              )}

              {privileges.includes("KYC") ? (
                <Route path="/kyc" element={<KYC />} />
              ) : (
                <Route path="/KYC" element={<KYC />} />
              )}

              {privileges.includes("workflow_form_candidate_details") ? (
                <Route
                  path="/formcandidatedetails"
                  element={<StepperCandidateDetails />}
                />
              ) : (
                <Route path="/formcandidatedetails" element={<NotFound />} />
              )}
              {/* Workflow Forms Ends */}

              {/* Setting Tab Start */}
              {privileges.includes("privilege_retrieve") ? (
                <Route path="/privilege" element={<Privilege />} />
              ) : (
                <Route path="/privilege" element={<NotFound />} />
              )}

              {privileges.includes("privilege_update") ? (
                <Route path="/editprivilege" element={<EditPrivilege />} />
              ) : (
                <Route path="/editprivilege" element={<NotFound />} />
              )}

              {privileges.includes("role_retrieve") ? (
                <Route path="/roles" element={<Roles />} />
              ) : (
                <Route path="/roles" element={<NotFound />} />
              )}

              {privileges.includes("role_create") ? (
                <Route path="/createrole" element={<RolesCreate />} />
              ) : (
                <Route path="/createrole" element={<NotFound />} />
              )}

              {privileges.includes("role_update") ? (
                <Route path="/editrole" element={<RolesEdit />} />
              ) : (
                <Route path="/editrole" element={<NotFound />} />
              )}

              {privileges.includes("type_master_retrieve") ? (
                <Route path="/typemaster" element={<TypeMaster />} />
              ) : (
                <Route path="/typemaster" element={<NotFound />} />
              )}

              {privileges.includes("type_master_create") ? (
                <Route
                  path="/createtypemaster"
                  element={<TypeMasterCreate />}
                />
              ) : (
                <Route path="/createtypemaster" element={<NotFound />} />
              )}

              {privileges.includes("type_master_update") ? (
                <Route path="/edittypemaster" element={<TypeMasterEdit />} />
              ) : (
                <Route path="/edittypemaster" element={<NotFound />} />
              )}
              {/* Setting Tab End */}

              {/* Doubt START */}

              <Route path="/viewresume" element={<ViewResume />} />
              <Route
                path="/viewshortlisted"
                element={<ShortlistedCandidate />}
              />
              <Route
                path="/professionaldetails"
                element={<ProfessionalDetails />}
              />

              <Route path="/workflow" element={<WorkFlowDemo />} />
              <Route path="/createfirsttab" element={<WorkFlowCreate />} />
              <Route path="/workflowedit" element={<WorkFlowEdit />} />
              <Route path="/jobworkflowdemo" element={<JobWorkflowDemo />} />
              {privileges.includes("job_opening_retrieve") ? (
                <Route
                  path="/rerequisitiondetails"
                  element={<RERequisitionDetails />}
                />
              ) : (
                <Route path="/rerequisitiondetails" element={<NotFound />} />
              )}

              <Route path="/viewresume" element={<ViewResume />} />

              {privileges.includes("personal_details_retrieve") ? (
                <Route path="/personaldetails" element={<PersonalDetails />} />
              ) : (
                <Route path="/personaldetails" element={<NotFound />} />
              )}

              {privileges.includes("educational_details_retrieve") ? (
                <Route
                  path="/educationdetails"
                  element={<EducationalDetails />}
                />
              ) : (
                <Route path="/educationdetails" element={<NotFound />} />
              )}

              {privileges.includes("professional_details_retrieve") ? (
                <Route
                  path="/professionaldetails"
                  element={<ProfessionalDetails />}
                />
              ) : (
                <Route path="/professionaldetails" element={<NotFound />} />
              )}

              {privileges.includes("workflowmanagement_retrieve") ? (
                <Route path="/jobworkflowdemo" element={<JobWorkflowDemo />} />
              ) : (
                <Route path="/jobworkflowdemo" element={<NotFound />} />
              )}

              {privileges.includes("workflow_tabs_configurable_retrieve") ? (
                <Route path="/workflow" element={<WorkFlowDemo />} />
              ) : (
                <Route path="/workflow" element={<NotFound />} />
              )}

              {privileges.includes("workflow_configure_first_tab_create") ? (
                <Route path="/createfirsttab" element={<WorkFlowCreate />} />
              ) : (
                <Route path="/createfirsttab" element={<NotFound />} />
              )}

              {privileges.includes("workflow_configure_tab_update") ? (
                <Route path="/workflowedit" element={<WorkFlowEdit />} />
              ) : (
                <Route path="/workflowedit" element={<NotFound />} />
              )}

              {privileges.includes("error_resume_download") ? (
                <Route path="/resumedownload" element={<ResumeDownload />} />
              ) : (
                <Route path="/resumedownload" element={<NotFound />} />
              )}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
