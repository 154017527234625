import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { WorkflowContext } from "../../Context/WorkflowContext";
import JobWorkflowEdit from "./JobWorkflowEdit";

const JobWorkflowTable = (props) => {
  const [searchWorkflow, setSearchWorkflow] = useState("");
  const privileges = secureLocalStorage.getItem("privileges");
  const [editedData, setEditedData] = useState(null);
  console.log(editedData);
  const [newFilteredWorkflowData, setNewFilteredWorkflowData] = useState([]);

  const { rawWorkflowData, getWorkflowData, isWorkflowData } =
    useContext(WorkflowContext);

  const onChangeHandler = (event) => {
    setSearchWorkflow(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getWorkflowData(searchWorkflow);
    } else {
      getWorkflowData();
      setSearchWorkflow("");
    }
  };

  useEffect(() => {
    getWorkflowData(searchWorkflow);
  }, []);

  useEffect(() => {
    console.log(rawWorkflowData);
    const filteredWorkflowData = rawWorkflowData.filter(
      (info) => info?.workflow_category === props.categoryName
    );
    console.log(filteredWorkflowData);
    setNewFilteredWorkflowData(filteredWorkflowData);
  }, [props.categoryName, rawWorkflowData]);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchWorkflow}
                      className="form-control"
                      placeholder="Search by workflow name..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Workflow name</span>
                      </div>

                      {privileges.includes("workflow_tabs_configurable_retrieve") ? (
                        <div className="nk-tb-col">
                          <span>Configure Workflow</span>
                        </div>
                      ) : (
                        " "
                      )}
                      {privileges.includes("workflowmanagement_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit Workflow</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {newFilteredWorkflowData.length !== 0 ? (
                      newFilteredWorkflowData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.workflow_name || "-"}
                              </span>
                            </span>
                          </div>

                          {privileges.includes("workflow_tabs_configurable_retrieve") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/workflow"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-configure_workflow"
                              >
                                <em className="icon ni ni-plus"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {privileges.includes("workflowmanagement_update") ? (
                            <div className="nk-tb-col">
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#edit_workflow"
                                className="btn btn-sm btn-primary"
                                id="button-button-edit_configure_Workflow"
                                onClick={() => setEditedData(post)}
                              >
                                <em className="icon ni ni-edit"></em>
                              </button>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isWorkflowData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JobWorkflowEdit editedData={editedData} />
    </>
  );
};

export default JobWorkflowTable;
