import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const WorkflowContext = createContext(null);

function WorkflowProvider({ children }) {
  // To store Workflow Data.
  const [isWorkflowData, setIsWorkflowData] = useState(false);
  const [rawWorkflowData, setRawWorkflowData] = useState([]);
  // const [workflowEntries, setWorkflowEntries] = useState(5);
  // const [workflowCount, setWorkflowCount] = useState(null);
  // const [workflowPageCount, setWorkflowPageCount] = useState(0);
  // const [isWorkflowLoading, setIsWorkflowLoading] = useState(true);

  // formworkflow data as per workflow id stored in "rawUserDetails"
  const [isFormWorkflowData, setIsFormWorkflowData] = useState(false);
  const [rawFormWorkflowData, setRawFormWorkflowData] = useState([]);
  const [formWorkflowEntries, setFormWorkflowEntries] = useState(5);
  const [formWorkflowCount, setFormWorkflowCount] = useState(null);
  const [formWorkflowPageCount, setFormWorkflowPageCount] = useState(0);
  const [isFormWorkflowLoading, setIsFormWorkflowLoading] = useState(true);

  // To store the formWorkFlowOptions Data
  const [formWorkflowOptions, setFormWorkflowOptions] = useState([]);

  // To store session of workflow tab
  const [workflowSession, setWorkflowSession] = useState([]);

  // To store the candidate workflow in ""
  const [candidateFormWorkflow, setCandidateFormWorkflow] = useState([]);

  const [dependsOnDropdownData, setDependsOnDropdownData] = useState([]);

  // "getWorkflowData" API call to get workflow data
  const getWorkflowData = async (searchWorkflow) => {
    // setIsWorkflowLoading(true)
    let url;
    // if (searchWorkflow) {
    //   url = VARIABLES.url + `/api/workflow/?entries=${workflowEntries}&page=${page}&event=${searchWorkflow}`;
    // } else {
    //   url = VARIABLES.url + `/api/workflow/?entries=${workflowEntries}&page=${page}`;
    // }
    if (searchWorkflow) {
      url = VARIABLES.url + `/api/workflow/?event=${searchWorkflow}`;
    } else {
      url = VARIABLES.url + `/api/workflow/`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsWorkflowData(false);
      if (response.length !== 0) {
        setIsWorkflowData(false);
        setRawWorkflowData(res.data.data);
        console.log(res.data.data);
      } else {
        setIsWorkflowData(true);
        setRawWorkflowData(res.data.data);
      }
      // const total = res.data.count;
      // setWorkflowCount(total);
      // setWorkflowPageCount(Math.ceil(total / workflowEntries));
    } catch (error) {
      console.log(error);
      // setIsWorkflowLoading(false)
      setIsWorkflowData(true);
    }
  };

  // const handleWorkflowClick = async (data,searchWorkflow) => {
  //   console.log(data.selected);
  //   let currentPage = data.selected + 1;
  //   const commentsFormServer = await getWorkflowData(
  //     currentPage,
  //     searchWorkflow
  //   );
  // };

  //  to get the formworkflow api call with specific workflow id
  
  const getFormWorkflowData = async (page, wk_id, searchedData) => {
    setIsFormWorkflowLoading(true);
    let url;
    if (searchedData) {
      url =
        VARIABLES.url +
        `/api/formworkflow/?entries=${formWorkflowEntries}&page=${page}&workflow_id=${wk_id}&event=${searchedData}`;
    } else {
      url =
        VARIABLES.url +
        `/api/formworkflow/?entries=${formWorkflowEntries}&page=${page}&workflow_id=${wk_id}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsFormWorkflowLoading(false);
      if (response.length !== 0) {
        setIsFormWorkflowData(false);
        setRawFormWorkflowData(res.data.data);
      } else {
        setIsFormWorkflowData(true);
        setRawFormWorkflowData(res.data.data);
      }
      const total = res.data.count;
      setFormWorkflowCount(total);
      setFormWorkflowPageCount(Math.ceil(total / formWorkflowEntries));
    } catch (error) {
      console.log(error);
      setIsFormWorkflowLoading(false);
      setIsFormWorkflowData(true);
    }
  };

  const handleFormWorkflowClick = async (data, wk_id, searchedData) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getFormWorkflowData(
      currentPage,
      wk_id,
      searchedData
    );
  };

  // "getFormWorkflowDropdownData" API call to get formworkflow data as dropdown
  const getFormWorkflowDropdownData = async (wk_id) => {
    const url = VARIABLES.url + `/api/formworkflow/?workflow_id=${wk_id}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setFormWorkflowOptions(
        res.data.data.map((pro) => ({
          value: pro.id,
          label: pro.tab_name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // "getWorkflowSession" API call to get the session data store in "workflowSession"
  const getWorkflowSession = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/workflowsession/");
      setWorkflowSession(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // "getCandidateStepperFlow" AP call to get the candidateformWorkflow
  const getCandidateStepperFlow = async (cand_id) => {
    const url = VARIABLES.url + `/api/candidatestepper/${cand_id}/`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setCandidateFormWorkflow(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDependsOnDropdownData = async (cand_id) => {
    const url =
      VARIABLES.url + `/api/candidateformworkflow/?candidate_job=${cand_id}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setDependsOnDropdownData(
        res.data.data.map((pro) => ({
          value: pro.id,
          label: pro.tab_name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <WorkflowContext.Provider
        value={{
          rawWorkflowData,
          getWorkflowData,
          // workflowEntries,
          // workflowPageCount,
          // setWorkflowEntries,
          // workflowCount,
          // handleWorkflowClick,
          isWorkflowData,
          // isWorkflowLoading,

          formWorkflowOptions,
          workflowSession,

          candidateFormWorkflow,
          dependsOnDropdownData,

          rawFormWorkflowData,
          getFormWorkflowData,
          formWorkflowEntries,
          formWorkflowPageCount,
          setFormWorkflowEntries,
          formWorkflowCount,
          handleFormWorkflowClick,
          isFormWorkflowData,
          isFormWorkflowLoading,

          getFormWorkflowDropdownData,
          getWorkflowSession,
          getCandidateStepperFlow,
          getDependsOnDropdownData,
        }}
      >
        {children}
      </WorkflowContext.Provider>
    </div>
  );
}

export default WorkflowProvider;
