import React, { useContext, useEffect, useState } from "react";
import { ViewResumeContext } from "../../Context/ViewResumeContext";
import CandidatePoolResumes from "../CandidatePoolResumes/CandidatePoolResumes";
import { useLocation } from "react-router-dom";

const ViewRCCandidatesResume = () => {
  const { rawRCResumeData, isRCResumeLoading, getAllCandidateResume } =
    useContext(ViewResumeContext);
  console.log("rawRCResumeData:", rawRCResumeData);
  const location = useLocation();
  console.log(location.state);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [sortedRCResumeData, setSortedRCResumeData] = useState([null]);
  console.log("sortedRCResumeData: ", sortedRCResumeData);

  useEffect(() => {
    const objectWithID4 = rawRCResumeData.find(
      (obj) => obj.id === location.state.candidateID
    );

    console.log(objectWithID4);

    const reorderedResponse = rawRCResumeData.filter(
      (obj) => obj.id !== location.state.candidateID
    );

    reorderedResponse.unshift(objectWithID4);

    console.log(reorderedResponse);
    setSortedRCResumeData(reorderedResponse);
  }, [rawRCResumeData, location.state.candidateID]);

  return (
    <>
      <CandidatePoolResumes
        rawResumeData={sortedRCResumeData}
        isLoading={isRCResumeLoading}
        getResumeData={getAllCandidateResume}
        jobID={location.state.jobID}
        setSubmitMessage={setSubmitMessage}
        submitMessage={submitMessage}
        shortlisting_type={"RC"}
      />
    </>
  );
};

export default ViewRCCandidatesResume;
