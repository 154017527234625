import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const ScheduleCandidateInterviewContext = createContext(null);

function ScheduleCandidateInterviewProvider({ children }) {
  // candidate selected for interview are stored in "rawAcceptedCandidateDetails"
  const [isCandidateData, setIsCandidateData] = useState(false);
  const [rawAcceptedCandidateDetails, setRawAcceptedCandidateDetails] =
    useState([]);
  console.log(rawAcceptedCandidateDetails);
  const [candidateEntries, setCandidateEntries] = useState(5);
  const [candidateCount, setCandidateCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);

  // interview schedule slots are stored in "rawInterviewSlots"
  const [rawInterviewSlots, setRawInterviewSlots] = useState([]);
  console.log(rawInterviewSlots);
  const [isInterviewSlotsLoading, setIsInterviewSlotsLoading] = useState(true);

  const getAcceptedCandidateDetails = async (page, reqId, searchCandidate) => {
    setIsCandidateLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate !== undefined) {
      url =
        VARIABLES.url +
        `/api/candidateresume/?shortlisted_type=sme_approved&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}&event=${searchCandidate}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidateresume/?shortlisted_type=sme_approved&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsCandidateLoading(false);
      if (response.length !== 0) {
        setIsCandidateData(false);
        setRawAcceptedCandidateDetails(res.data.data);
      } else {
        setIsCandidateData(true);
        setRawAcceptedCandidateDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateLoading(false);
      setIsCandidateData(true);
    }
  };

  const handleAcceptCandidateClick = async (data, searchCandidate) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAcceptedCandidateDetails(
      currentPage,
      searchCandidate
    );
  };

  const getInterviewSlots = async (reqID) => {
    let url = VARIABLES.url + `/api/interview-slots/?requisition_id=${reqID}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      console.log(res.data.data);
      setRawInterviewSlots(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <ScheduleCandidateInterviewContext.Provider
        value={{
          rawAcceptedCandidateDetails,
          getAcceptedCandidateDetails,
          candidateEntries,
          candidatePageCount,
          setCandidateEntries,
          candidateCount,
          handleAcceptCandidateClick,
          isCandidateData,
          isCandidateLoading,

          rawInterviewSlots,
          getInterviewSlots,
        }}
      >
        {children}
      </ScheduleCandidateInterviewContext.Provider>
    </div>
  );
}

export default ScheduleCandidateInterviewProvider;
