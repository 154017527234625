import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { JobAssignmentContext } from "../../Context/JobAssignment";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const JobAssignmentCreate = () => {
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const { getAssignmentData } = useContext(JobAssignmentContext);

  const { isGenricDropdownDataLoading, jobOpeningDropdownData } = useContext(
    GenericDropdownContext
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let user = new FormData();
    user.append("job_opening_id", data.job_title.value); //JOB ID
    user.append("assignment_title", data.assignment_title);
    user.append("assignment_document", data.assignment_document);
    // user.append("start_date", data.start_date);
    // user.append("end_date", data.end_date);
    user.append("created_by_id", userID);
    user.append("updated_by_id", userID);

    const url = VARIABLES.url + "/api/jobassignment/";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getAssignmentData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "successfully created assignment",
        });
        setTimeout(() => {
          navigate("/jobassignment");
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to create assignment",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Job Assignment</h3>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Job Title
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={jobOpeningDropdownData?.map((info) => ({
                          value: info.id,
                          label: info.job_title + " " + info.department_name + " " + info.team_name,
                        }))}
                        isLoading={isGenricDropdownDataLoading}
                        isClearable={true}
                        id="select-forms-jobtitle"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="job_title"
                    control={control}
                  />
                  {errors.job_title && (
                    <span className="fs-8 text-danger">
                      Job title is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-assignment_title"
                      placeholder="Enter assignment title"
                      {...register("assignment_title", {
                        required: true,
                      })}
                    />
                    {errors.assignment_title && (
                      <span className="fs-8 text-danger">
                        Assignment title is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Assignment Document
                      <span className="fs-8 text-danger">*</span>
                    </label>
                    <Controller
                      name="assignment_document"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          className="form-control"
                          id="file-forms-assignment_document"
                          type="file"
                          onChange={(e) => field.onChange(e.target.files[0])}
                        />
                      )}
                    />
                    {errors.assignment_document && (
                      <span className="fs-8 text-danger">
                        Assignment document is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createjobassignment"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobAssignmentCreate;
