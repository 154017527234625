import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const ResumeAdvanceFilterContext = createContext(null);

function ResumeAdvanceFilterProvider({ children }) {
  const [selectedPlatform, setSelectedPlatform] = useState([])
  console.log(selectedPlatform)
  const [selectedStatus, setSelectedStatus] = useState([])
  console.log(selectedStatus)

  // userdetails stored in "rawUserDetails"
  const [isLoading, setIsLoading] = useState(true)
  const [isData, setIsData] = useState(false)
  const [shortlistedResumeData, setShortlistedResumeData] = useState([])
  const [resumeEntries, setResumeEntries] = useState(5);
  const [resumeCount, setResumeCount] = useState(null);
  const [resumePageCount, setResumePageCount] = useState(0);

  const getAdvanceResumeFilterData = async (page) => {
    const combinedLabels = [...selectedPlatform, ...selectedStatus].map(option => option.label).join(',');
    console.log(combinedLabels)
    let url;
    if (combinedLabels) {
      url = VARIABLES.url + `/api/advanced-search/?search_tags=${combinedLabels}&entries=${resumeEntries}&page=${page}`;
    }  else {
      url = VARIABLES.url + `/api/candidateresume/?entries=${resumeEntries}&page=${page}`;
    }
    console.log(url);
    setIsLoading(true);
    try {
      const res = await axios.get(url);
      const response = res.data.data
      setIsLoading(false);
      if (response.length !== 0) {
        setIsData(false);
        setShortlistedResumeData(res.data.data);
        console.log(res.data.data)
      } else {
        setIsData(true);
        setShortlistedResumeData(res.data.data);
      }
      const total = res.data.count;
      setResumeCount(total);
      setResumePageCount(Math.ceil(total / resumeEntries));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsData(true);
    }
  };

  const handleResumeClick = async (data) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAdvanceResumeFilterData(currentPage);
  };

  return (
    <div>
      <ResumeAdvanceFilterContext.Provider
        value={{
          shortlistedResumeData,
          getAdvanceResumeFilterData,
          resumeEntries,
          resumePageCount,
          setResumeEntries,
          resumeCount,
          handleResumeClick,
          isData,
          isLoading,
          setSelectedPlatform,
          setSelectedStatus,
          selectedStatus,
          selectedPlatform
        }}
      >
        {children}
      </ResumeAdvanceFilterContext.Provider>
    </div>
  );
}

export default ResumeAdvanceFilterProvider;
