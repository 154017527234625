import axios from "axios";
import React, { useState, useContext } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const CandidateEntriesContext = createContext(null);

function CandidateEntriesProvider({ children }) {

  // userdetails stored in "rawUserDetails"
  const [isCandidateData, setIsCandidateData] = useState(false);
  const [rawCandidateDetails, setRawCandidateDetails] = useState([]);
  console.log(rawCandidateDetails)
  const [candidateEntries, setCandidateEntries] = useState(5);
  const [candidateCount, setCandidateCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);


  const [candidateOtherDetails, setCandidateOtherDetails] = useState([])
  console.log(candidateOtherDetails)
  const [isDetailsLoading, setIsDetailsLoading] = useState(false)
  const [isDetailsData, setIsDetailsData] = useState(false)

  const getCandidateEntriesData = async (page, searchCandidate) => {
    setIsCandidateLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate) {
      url =
        VARIABLES.url +
        `/api/candidatejob/?entries=${candidateEntries}&page=${page}&search_by_candidate_job_title=${searchCandidate}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False`;
    }
    // if (searchCandidate) {
    //   url =
    //   `http://192.168.29.226:8000/api/candidatejob/?entries=${candidateEntries}&page=${page}&search_by_candidate_job_title=${searchCandidate}`;
    // } else {
    //   url =
    //   `http://192.168.29.226:8000/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False`;
    // }
    
    console.log(url);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsCandidateLoading(false);
      if (response.length !== 0) {
        setIsCandidateData(false);
        setRawCandidateDetails(res.data.data);
      } else {
        setIsCandidateData(true);
        setRawCandidateDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateLoading(false);
      if (error?.response?.status === 403) {
        setIsCandidateData(false);
      } else {
        setIsCandidateData(true);
      }
    }
  };

  const handlePageClick = async (data, searchCandidate) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getCandidateEntriesData(
      currentPage,
      searchCandidate
    );
  };

  const getCandidateOtherData = async (cand_id) => {
    setIsDetailsLoading(true);
    let url = VARIABLES.url +
      `/all-candidate-details/?candidate_job_id=${cand_id}`;
    
    // let url = `http://192.168.29.226:8000/all-candidate-details/?candidate_job_id=${cand_id}`

    // console.log(url);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await axios(config);
      let response = res.data;
      console.log(response)
      setIsDetailsLoading(false);
      if (response) {
        setIsDetailsData(false);
        setCandidateOtherDetails(res.data);
      } else {
        setIsDetailsData(true);
        setCandidateOtherDetails(res.data);
      }
    } catch (error) {
      console.log(error);
      setIsDetailsLoading(false);
      // if (error?.response?.status === 403) {
      //   RefreshToken()
      //   setIsDetailsLoading(false);
      // } else {
      //   setIsCandidateData(true);
      // }
    }
  };

  return (
    <div>
      <CandidateEntriesContext.Provider
        value={{
          rawCandidateDetails,
          getCandidateEntriesData,
          candidateEntries,
          candidatePageCount,
          setCandidateEntries,
          candidateCount,
          handlePageClick,
          isCandidateData,
          isCandidateLoading,

          getCandidateOtherData,
          candidateOtherDetails,
          isDetailsLoading,
          isDetailsData, 

          setRawCandidateDetails
        }}
      >
        {children}
      </CandidateEntriesContext.Provider>
    </div>
  );
}

export default CandidateEntriesProvider;
