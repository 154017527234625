import React, { useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { Controller, useForm, useFormState } from "react-hook-form";
import { RCQuestions } from "../WorkflowStepperForm/StepperFormJson";
import Select from "react-select";
import { useLocation } from "react-router-dom";

const RejectCandidate = (props) => {
  const { register, handleSubmit, control, reset } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const { isDirty, isValid } = useFormState({ control });

  const location = useLocation();
  console.log(location.state.editdata);
  console.log(props);

  const handleEditSubmit = async () => {
    // var user = JSON.stringify({
    //   requisition_id: props.jobID,
    //   candidate_status: "reject",
    //   updated_by_id: userID,
    // });
    let user = new FormData();
    user.append("requisition_id", props.jobID);
    user.append("candidate_status", "reject");
    user.append("updated_by_id", userID);
    console.log(user);

    var config = {
      method: "patch",
      url:
        props.shortlisting_type === "RC"
          ? `${VARIABLES.url}/api/candidateresume/${props.editdata.id}/?shortlisting_type=RC`
          : `${VARIABLES.url}/api/candidateresume/${props.editdata.id}/?shortlisting_type=SME`,

      // headers: {
      //   "Content-Type": "application/json",
      // },
      data: user,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitLoading(false);
        props.setStoredStatus((prevStoredData) => ({
          ...prevStoredData,
          [props.editdata.id]: "reject",
        }));
        props.setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Candidate rejected successfuly",
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false);
        props.setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to reject candidate",
        });
      });
  };

  const submitFeedback = (data) => {
    console.log(data);
    var user = JSON.stringify({
      requisition_id: props.jobID,
      resume_id: props.editdata.id,
      feedback: RCQuestions.map((info) => ({
        feedback_question: info.label,
        feedback: data.manualmarks.includes(info.label) ? "yes" : "no",
        feedback_score: data.manualmarks.includes(info.label) ? "yes" : "no",
      })),
      feedback_by: props.shortlisting_type,
      updated_by_id: userID,
    });
    console.log(user);
    // for (var pair of user.entries()) {
    //   console.log(pair);
    // }

    var config = {
      method: "patch",
      url: `${VARIABLES.url}/api/resumefeedback/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        handleEditSubmit();
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <div
        class="modal fade"
        id="rejectcandidate"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Rejecting Candidate
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                }}
              ></button>
            </div>
            <form onSubmit={handleSubmit(handleEditSubmit)} autoComplete="off">
              <div class="modal-body">
                <div className="card-inner">
                  <div className="row g-4">
                    <div className="card-inner">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.no</th>
                              <th>Question</th>
                              <th>Choose</th>
                            </tr>
                          </thead>
                          <tbody>
                            {RCQuestions.map((info, index) => (
                              <tr>
                                <td style={{ width: "5%" }}>{index + 1}</td>
                                <td>{info.label} </td>
                                <td style={{ width: "5%" }}>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    style={{
                                      width: "1.5rem",
                                      height: "1.5rem",
                                    }}
                                    value={info.label}
                                    id={`flexCheckDefault-${index}`}
                                    {...register(`manualmarks[${index}]`)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">
                          Overall Recommendation
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Select
                              {...field}
                              // options={seniorityDropdownData?.map((info) => ({
                              //   value: info.id,
                              //   label: info.seniority_display_name,
                              // }))}
                              // isLoading={isGenricDropdownDataLoading}
                              isClearable={true}
                              id="select-forms-seniority"
                            />
                          )}
                          defaultValue=""
                          // rules={{ required: true }}
                          name="seniority"
                          control={control}
                        />
                        {/* {errors.seniority && (
                      <span className="fs-8 text-danger">
                        Seniority is required
                      </span>
                    )} */}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">Comments</label>
                        <textarea
                          style={{ height: "20px" }}
                          className="form-control"
                          id="date-forms-comments"
                          placeholder="Enter comments"
                          {...register("comments")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : isValid === true ? (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-rejectResume"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-rejectResume"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectCandidate;
