import React, { useContext, useEffect } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";

const AddTechnicalQuestion = (props) => {
  const { isDropdownDataLoading, interviewCategoryDropdownData } = useContext(
    TypemasterDropdownContext
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("first");
    const newQuestion = {
      question: data.question,
      category: [data.category.value],
      answer: data.answer,
      interview_type: ["TI"],
    };
    console.log(newQuestion);
    props.setAddTechnicalQuestion([...props.addTechnicalQuestion, newQuestion]);
    reset();
  };

  return (
    <>
      <div
        className="modal fade"
        id="addtechquestion"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add technical question
              </h5>

              <button
                type="button"
                className="btn-close"
                id="button-button-closemodal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Question <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-forms-question"
                        placeholder="Enter Question"
                        {...register("question", { required: true })}
                      />
                      {errors.question && (
                        <p className="fs-8 text-danger">Question is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Answer <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-forms-answer"
                        placeholder="Enter answer"
                        {...register("answer", { required: true })}
                      />
                      {errors.answer && (
                        <p className="fs-8 text-danger">Answer is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      {" "}
                      Category
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={interviewCategoryDropdownData?.map(
                            (info) => ({
                              value: info.value,
                              label: info.name,
                            })
                          )}
                          isLoading={isDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-catogory"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="category"
                      control={control}
                    />
                    {errors.category && (
                      <span className="fs-8 text-danger">
                        Category is required{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary"
                  id="submit-button-addtechquestion"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTechnicalQuestion;
