import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { ActivityLogsContext } from "../../Context/ActivityLogsContext";

const CandidatesAppliedForReq = () => {
  const [searchResume, setSearchResume] = useState("");
  console.log(searchResume);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawCandidateResumePerReq,
    getCandidateResumePerReq,
    candidateResumePerReqEntries,
    candidateResumePerReqPageCount,
    setCandidateResumePerReqEntries,
    candidateResumePerReqCount,
    handleCandidateResumePerReqClick,
    isResumePerReqData,
    isResumePerReqLoading,
  } = useContext(ActivityLogsContext);

  console.log("rawCandidateResumePerReq: ", rawCandidateResumePerReq);

  const location = useLocation();
  const navigate = useNavigate();

  const handleEntries = (event) => {
    setCandidateResumePerReqEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchResume(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getCandidateResumePerReq(1, location?.state?.JobID, searchResume);
    } else {
      getCandidateResumePerReq(1, location?.state?.JobID);
      setSearchResume("");
    }
  };

  useEffect(() => {
    getCandidateResumePerReq(1, location?.state?.JobID, searchResume);
  }, [candidateResumePerReqEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Candidates applied for {location?.state?.JobTitle}
            </h3>
            {isResumePerReqLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchResume}
                      className="form-control"
                      placeholder="Search by candidate name, email..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Email-ID</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile no.</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Resume</span>
                      </div>

                      {["candidate_activity_logs"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <div className="nk-tb-col">
                          <span>Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                    {rawCandidateResumePerReq.length !== 0 ? (
                      rawCandidateResumePerReq.map((data, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.candidate_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.email || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.mobile || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {data?.resume ? (
                                <a
                                  className="btn btn-primary"
                                  id="button-button-viewresume"
                                  href={data?.resume}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <em
                                    className="icon ni ni-file-docs"
                                    style={{ color: "white" }}
                                  ></em>
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>

                          {["candidate_activity_logs"].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="me-n1">
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0)"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                      id="list-dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        {privileges.includes(
                                          "candidate_activity_logs"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/candidatetimeline"}
                                              id="button-button-candidatetimeline"
                                              state={{
                                                CandidateName:
                                                  data?.candidate_name,
                                                ResumeID: data?.id,
                                              }}
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>
                                                View Candidate Timeline
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isResumePerReqData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidateResumePerReqPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidateResumePerReqPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleCandidateResumePerReqClick(
                        data,
                        location?.state?.JobID,
                        searchResume
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {candidateResumePerReqCount === null
                          ? 0
                          : candidateResumePerReqCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateResumePerReqEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidatesAppliedForReq;
