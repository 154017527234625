import axios from "axios";
import React, { useState, createContext } from "react";
import { VARIABLES } from "../Constants";

export const ViewResumeContext = createContext(null);

function ViewResumeProvider({ children }) {

  const [isAllResumeLoading, setIsAllResumeLoading] = useState(false);
  const [rawAllResumeData, setRawAllResumeData] = useState([]);
  console.log(rawAllResumeData);
  
  //show resumes to RC 
  const [rawRCResumeData, setRawRCResumeData] = useState([]);
  console.log(rawRCResumeData)
  const [isRCResumeLoading, setIsRCResumeLoading] = useState(false);

  //show resumes to SME
  const [rawSMEResumeData, setRawSMEResumeData] = useState([]);
  console.log(rawSMEResumeData)
  const [isSMEResumeLoading, setIsSMEResumeLoading] = useState(false);

  const getAllCandidateResume = async (requisition_id) => {
    const url =
      VARIABLES.url +
      `/api/candidateresume/?tags=FSC-R${requisition_id}`;
    console.log(url);
    try {
      setIsAllResumeLoading(true);
      const res = await axios.get(url);
      setRawAllResumeData(res.data.data);
      setIsAllResumeLoading(false);
    } catch (error) {
      console.log(error);
      setIsAllResumeLoading(false);
    }
  };

  const getRCCandidateResume = async (requisition_id) => {
    const url =
      VARIABLES.url +
      `/api/candidateresume/?shortlisted_type=not_shortlisted&requisition_id=${requisition_id}`;
    console.log(url);
    try {
      setIsRCResumeLoading(true);
      const res = await axios.get(url);
      setRawRCResumeData(res.data.data);
      setIsRCResumeLoading(false);
    } catch (error) {
      console.log(error);
      setIsRCResumeLoading(false);
    }
  };

  const getSMECandidateResume = async (requisition_id) => {
    const url =
      VARIABLES.url +
      `/api/candidateresume/?shortlisted_type=rc_approved&requisition_id=${requisition_id}`;
    console.log(url);
    try {
      setIsSMEResumeLoading(true);
      const res = await axios.get(url);
      setRawSMEResumeData(res.data.data);
      setIsSMEResumeLoading(false);
    } catch (error) {
      console.log(error);
      setIsSMEResumeLoading(false);
    }
  };

  return (
    <div>
      <ViewResumeContext.Provider
        value={{
          getAllCandidateResume,
          isAllResumeLoading,
          rawAllResumeData,

          getRCCandidateResume,
          rawRCResumeData,
          isRCResumeLoading,

          getSMECandidateResume,
          rawSMEResumeData,
          isSMEResumeLoading,
        }}
      >
        {children}
      </ViewResumeContext.Provider>
    </div>
  );
}

export default ViewResumeProvider;
