import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import { useForm } from "react-hook-form";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import { ResumeAdvanceFilterContext } from "../../Context/ResumeAdvanceFilter";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const ResumePoolAdvanceFilter = (props) => {
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  console.log(selectAll);
  const [isSelect, setIsSelect] = useState([]);
  console.log(isSelect);
  const { getRequisition, allRequisitionData } = useContext(
    RequestedJobOpeningContext
  );

  const { reset } = useForm();

  const {
    shortlistedResumeData,
    getAdvanceResumeFilterData,
    resumeEntries,
    resumePageCount,
    setResumeEntries,
    resumeCount,
    handleResumeClick,
    isData,
    isLoading,
    setSelectedPlatform,
    setSelectedStatus,
    selectedPlatform,
    selectedStatus,
  } = useContext(ResumeAdvanceFilterContext);

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { agencyNameDropdownData } = useContext(GenericDropdownContext);
  const { RECollegesDropdownData, RE2PortalsDropdownData } = useContext(
    TypemasterDropdownContext
  );
  const agency_name = agencyNameDropdownData.map((api) => {
    return { value: api.agency_name, label: api.agency_name };
  });
  const re1_college = RECollegesDropdownData.map((api) => {
    return { value: api.value, label: api.name };
  });
  const re2_portals = RE2PortalsDropdownData.map((api) => {
    return { value: api.value, label: api.name };
  });

  const combine_agency_college_portals = [
    ...agency_name,
    ...re1_college,
    ...re2_portals,
  ];

  const combine_status = [
    { label: "FSC-R4-RC-rejected", value: "FSC-R4-RC-rejected" },
    { label: "FSC-R39-RC-rejected", value: "FSC-R39-RC-rejected" },
  ];

  const handlePlatformChange = (selectedOption) => {
    setSelectedPlatform(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const handleEntries = (event) => {
    setResumeEntries(event.target.value);
  };

  const handleHeaderCheckboxChange = (event) => {
    setIsCheckboxClicked(event.target.checked);
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setIsSelect(shortlistedResumeData.map((api) => api.id));
    if (selectAll) {
      setIsSelect([]);
    }
  };

  const handleClick = (event, id) => {
    setIsCheckboxClicked(event.target.checked);
    console.log(event);
    console.log(id);
    setIsSelect([...isSelect, id]);
    if (!event.target.checked) {
      setIsSelect(isSelect.filter((item) => item !== id));
    }
  };

  const userID = secureLocalStorage.getItem("userID");

  const [requisition, setRequisition] = useState([]);
  const handleRequsiition = (selectedOption) => {
    setRequisition(selectedOption);
    console.log(selectedOption);
  };

  const mapRequisitionToResume = () => {
    let user = new FormData();
    user.append(
      "resume_id",
      isSelect.map((api) => api)
    );
    user.append(
      "requisition_id",
      requisition.map((api) => "FSC-R" + api.value)
    );
    user.append("created_by_id", userID);

    for (var pair of user.entries()) {
      console.log(pair);
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/advanced-search/",
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        reset();
        setSubmitLoading(false);
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Resume Mapped With the Requisiiton",
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to map the requisition with resume",
        });
      });
  };

  useEffect(() => {
    getAdvanceResumeFilterData(1);
  }, [resumeEntries, selectedPlatform, selectedStatus]);

  useEffect(() => {
    getRequisition();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-between">
        <div className="nk-block-head-content">
          <ul className="nav nav-tabs " id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="advance-filter-tab"
                data-bs-toggle="tab"
                data-bs-target="#advancefilter"
                type="button"
                role="tab"
                aria-controls="advancefilter"
                aria-selected="true"
              >
                Advance Filter
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="single-filter-tab" data-bs-toggle="tab" data-bs-target="#singlefilter"
                type="button" role="tab"
                aria-controls="singlefilter" aria-selected="false">
                Single Search
              </button>
            </li> */}
          </ul>
        </div>
        <div className="nk-block-head-content">
          {isLoading ? (
            <div class="spinner-border text-primary ms-2" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="advancefilter"
          role="tabpanel"
          aria-labelledby="advance-filter-tab"
        >
          <div className="card">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Platform</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <Select
                        options={combine_agency_college_portals}
                        isMulti="true"
                        onChange={handlePlatformChange}
                        id="select-form-college_portals"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Status Tags</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <Select
                        options={combine_status}
                        isMulti="true"
                        onChange={handleStatusChange}
                        id="select-form-status-tags"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex justify-content-end">
                      <button
                        id="button-button-ok_filter"
                        type="button"
                        onClick={getAdvanceResumeFilterData}
                        className="btn btn-primary"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-inner border-bottom-0">
                {/* <div className="form-control-wrap">
                <div className="input-group">
                  <input type="text" value={searchAgency} className="form-control" placeholder="Search by agency name and agency type..." onChange={onChangeHandler} />
                  <div className="input-group-append">
                    <button className="btn btn-outline-primary btn-dim" id="button-button-search" onClick={() => onSearchHandler("search")}>Search</button>
                    <button className="btn btn-outline-success btn-dim" id="button-button-view" onClick={() => onSearchHandler("view")}>View all</button>
                  </div>
                </div>
              </div> */}
                {isCheckboxClicked && (
                  <div className="row">
                    <div className="col-lg-10">
                      <Select
                        options={allRequisitionData}
                        isMulti
                        onChange={handleRequsiition}
                        id="select-form-allrequisition"
                      />
                    </div>
                    <div className="col-lg-2">
                      {submitLoading ? (
                        <button
                          id="button-loading"
                          className="btn text-dark"
                          disabled
                        >
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-md btn-primary"
                          id="submit-button-map_requisition"
                          onClick={mapRequisitionToResume}
                        >
                          Map to Requisition
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="card-inner p-0">
                <div className="table-responsive">
                  <table className="table">
                    <div className="nk-tb-list">
                      <div className="nk-tb-item nk-tb-head">
                        <div className="nk-tb-col nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={handleHeaderCheckboxChange}
                              id="checkbox-form-candidate"
                            />
                            <label
                              className="custom-control-label"
                              for="checkbox-form-candidate"
                            ></label>
                          </div>
                        </div>
                        <div className="nk-tb-col">
                          <span>Candidate Name</span>
                        </div>
                        <div className="nk-tb-col">
                          <span>Email-ID</span>
                        </div>
                        <div className="nk-tb-col">
                          <span>Mobile</span>
                        </div>
                        <div className="nk-tb-col">
                          <span>Tags</span>
                        </div>
                        <div className="nk-tb-col">
                          <span>Resume</span>
                        </div>
                      </div>

                      {shortlistedResumeData.length > 0 ? (
                        shortlistedResumeData.map((post, index) => (
                          <div className="nk-tb-item">
                            <div className="nk-tb-col nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  key={post.id}
                                  id={post.id}
                                  onChange={(event) =>
                                    handleClick(event, post.id)
                                  }
                                  checked={isSelect.includes(post.id)}
                                />
                                <label
                                  className="custom-control-label"
                                  for={post.id}
                                ></label>
                              </div>
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">
                                  {post.candidate_name}
                                </span>
                              </span>
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-sub">{post.email}</span>
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-sub">{post.mobile}</span>
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-sub">{post.tags}</span>
                            </div>
                            <div className="nk-tb-col">
                              <a
                                id="button-resume"
                                href={post.resume}
                                target="_blank"
                                className="btn btn-primary"
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            </div>
                          </div>
                        ))
                      ) : isData ? (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">No Data Found</span>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">Loading...</span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </table>
                </div>
              </div>

              <div className="card-inner">
                <div className="nk-block-between-md g-3">
                  <div className="g">
                    <ReactPaginate
                      key={resumePageCount}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={resumePageCount}
                      marginPagesDisplayed={2}
                      onPageChange={(data) => handleResumeClick(data)}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                  <div className="g">
                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                      <div>
                        Total entries:
                        <span className="fw-bold">
                          {resumeCount === null ? 0 : resumeCount}
                        </span>
                      </div>
                      <div>No of entries per page:</div>
                      <div>
                        <select
                          className="form-select js-select2"
                          data-search="on"
                          data-dropdown="xs center"
                          value={resumeEntries}
                          onChange={handleEntries}
                          id="select-form-enteries"
                        >
                          <option value="5">5</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tab-pane fade" id="singlefilter"
          role="tabpanel" aria-labelledby="single-filter-tab">
          Single Filter Tab
        </div> */}
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ResumePoolAdvanceFilter;
