import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const InterviewerSME = () => {
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawRCRequisitionData,
    getRCRequisitionData,
    RCRequisitionEntries,
    RCRequisitionpageCount,
    setRCRequisitionEntries,
    RCRequisitionCount,
    handleRCRequisitionPageClick,
    isRCRequisitionData,
    isRCRequisitionLoading,
  } = useContext(RequestedJobOpeningContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const calculatePendingSince = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - createdDate;
    const InMilliseconds = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / InMilliseconds
    );
    console.log(differenceInDays);
    return differenceInDays;
  };

  const handleEntries = (event) => {
    setRCRequisitionEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRCRequisitionData(1, searchRequest);
    } else {
      getRCRequisitionData(1);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    getRCRequisitionData(1);
  }, [RCRequisitionEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Interviewer / SME</h3>
            {isRCRequisitionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search by requisition id, job title, department, priority..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Priority (Set by TAM)</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Pending Since (Days) for shortlisting</span>
                      </div>

                      {[
                        "job_opening_retrieve",
                        "candidate_details_retrieve",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <div className="nk-tb-col">
                          <span>Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawRCRequisitionData.length !== 0 ? (
                      rawRCRequisitionData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name +
                                    post.requisition.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post.job_opening?.department
                                ?.department_display_name || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition?.priority || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {calculatePendingSince(post.created_at)} days
                            </span>
                          </div>

                          {[
                            "job_opening_retrieve",
                            "candidate_details_retrieve",
                          ].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="me-n1">
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0)"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                      id="list-dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        {privileges.includes(
                                          "job_opening_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/rerequisitiondetails"}
                                              state={{ editpost: post }}
                                              id="button-button-jobopeningdetails"
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>
                                                View Requisition Details
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}

                                        {privileges.includes(
                                          "candidate_details_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/smecandidateapplied"}
                                              id="button-button-candidateapplied"
                                              state={{
                                                editpost: post,
                                              }}
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>
                                                View Candidate details
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isRCRequisitionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={RCRequisitionpageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleRCRequisitionPageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {RCRequisitionCount === null ? 0 : RCRequisitionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={RCRequisitionEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewerSME;
