import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { VARIABLES } from "../Constants";
export const GenericDropdownContext = createContext(null);

function GenericDropdownContextProvider({ children }) {
  const [isGenricDropdownDataLoading, setisGenricDropdownDataLoading] =
    useState(true);
  const [isGenricParamsDropdownDataLoading, setisGenricParamsDropdownDataLoading] =
    useState(true);

  //GENERIC DROPDOWN STATES
  const [departmentDropdownData, setDepartmentDropdownData] = useState([]);
  const [teamDropdownData, setTeamDropdownData] = useState([]);
  const [positionDropdownData, setPositionDropdownData] = useState([]);
  const [seniorityDropdownData, setSeniorityDropdownData] = useState([]);
  const [roleDropdownData, setRoleDropdownData] = useState([]);
  const [formsDropdownData, setFormsDropdownData] = useState([]);
  const [jobOpeningDropdownData, setJobOpeningDropdownData] = useState([]);
  const [workflowDropdownData, setWorkflowDropdownData] = useState([]);
  const [tagCategoryDropdownData, setTagCategoryDropdownData] = useState([]);
  const [typeMasterCategoryDropdownData, setTypeMasterCategoryDropdownData] = useState([]);
  const [jobAssignmentDropdownData, setJobAssignmentDropdownData] = useState([]);
  const [usersDropdownData, setUsersDropdownData] = useState([]);
  const [agencyNameDropdownData, setAgencyNameDropdownData] = useState([]);

  const getGenericDropdownData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/generic/");
      setisGenricDropdownDataLoading(false);
      setDepartmentDropdownData(res.data.department)
      setTeamDropdownData(res.data.team)
      setPositionDropdownData(res.data.position)
      setSeniorityDropdownData(res.data.seniority)
      setRoleDropdownData(res.data.roles)
      setFormsDropdownData(res.data.forms)
      setJobOpeningDropdownData(res.data.job_opening)
      setWorkflowDropdownData(res.data.workflow)
      setTagCategoryDropdownData(res.data.tag_category)
      setTypeMasterCategoryDropdownData(res.data.type_master_category)
      setJobAssignmentDropdownData(res.data.job_assignment)
      setAgencyNameDropdownData(res.data.agency)
    } catch (error) {
      console.log(error);
      setisGenricDropdownDataLoading(false);
    }
  };

  const getGenericParamsDropdownData = async () => {
    let url;
    url = VARIABLES.url + `/api/generic/?login_types_id=${VARIABLES.rms_id}`
    console.log(url)
    try {
      const res = await axios.get(url);
      setUsersDropdownData(res.data.user)
      setisGenricParamsDropdownDataLoading(false)
    } catch (error) {
      setisGenricParamsDropdownDataLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getGenericDropdownData();
    getGenericParamsDropdownData();
  }, []);

  const value = {};
  return (
    <div>
      <GenericDropdownContext.Provider
        value={{
          isGenricDropdownDataLoading,
          isGenricParamsDropdownDataLoading,
          getGenericDropdownData,
          getGenericParamsDropdownData,


          departmentDropdownData,
          teamDropdownData,
          positionDropdownData,
          seniorityDropdownData,
          roleDropdownData,
          formsDropdownData,
          jobOpeningDropdownData,
          workflowDropdownData,
          tagCategoryDropdownData,
          typeMasterCategoryDropdownData,
          jobAssignmentDropdownData,
          agencyNameDropdownData,

          usersDropdownData
        }}
      >
        {children}
      </GenericDropdownContext.Provider>
    </div>
  );
}

export default GenericDropdownContextProvider;
