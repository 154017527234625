import React, { useContext, useState, useEffect } from "react";
import { CandidateDetailsContext } from "../../Context/CandidateDetailsContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import { useForm } from "react-hook-form";

const ShortlistedCandidate = () => {
  // const access = secureLocalStorage.getItem("access");
  const [searchRequest, setSearchRequest] = useState("");

  const {
    shortlistedCandidate,
    isLoading,
    setShortlistedEntries,
    handleShortlistedPageClick,
    shortlistedEntryCount,
    shortlistedEntries,
    shortlistedPageCount,
    shortlistedIsData,
    getShortlistedCandidateData,
    getEducationData,
    getProfessionalData,
  } = useContext(CandidateDetailsContext);

  console.log("shortlistedCandidate:", shortlistedCandidate);

  const { reset } = useForm();

  const privileges = secureLocalStorage.getItem("privileges");

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const location = useLocation();
  console.log(location.state?.editpost);
  const navigate = useNavigate();

  const handleResumeCount = (candidateJobID, count) => {
    var resumeCount = JSON.stringify({
      view_resume_count: count,
      updated_by_id: userID,
    });
    console.log(resumeCount);
    const url = VARIABLES.url + `/api/candidatejob/${candidateJobID}/`;
    console.log(url);

    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
        // "Authorization" : `Bearer ${access}`
      },
      user: resumeCount,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.user));
      })
      .catch((error) => {
        console.log(error);
        // if(error.response.status === 403) {
        //   RefreshToken()
        // } else {
          console.log("error")
        // }
      });
    reset();
  };

  const handleEntries = (event) => {
    setShortlistedEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getShortlistedCandidateData(
        1,
        location.state?.editpost?.id,
        searchRequest
      );
    } else {
      getShortlistedCandidateData(1, location.state?.editpost?.id);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    getShortlistedCandidateData(1, location.state?.editpost?.id);
  }, [location, shortlistedEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Shortlisted candidate for{" "}
              {location.state?.editpost?.job_title || "-"}
            </h3>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex">
            {privileges.includes("view_shortlisted_resume") ? (
              <div className="nk-block-head-content me-3">
                <NavLink
                  to={"/viewresume"}
                  // target="_blank"
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-viewresume"
                >
                  <em className="icon ni ni-plus"></em>
                </NavLink>

                <NavLink
                  to={"/viewresume"}
                  // target="_blank"
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-viewresume"
                >
                  <em className="icon ni ni-eye"></em>
                  <span>Shortlist Resume</span>
                </NavLink>
              </div>
            ) : (
              " "
            )}
            <div class="nk-block-head-content">
              <a
                id="button-back"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="button-back"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    value={searchRequest}
                    className="form-control"
                    placeholder="Search by candidate name..."
                    onChange={onChangeHandler}
                  />
                  <div className="input-group-append">
                    <button
                      type="submit"
                      className="btn btn-outline-primary btn-dim"
                      id="button-button-search"
                      onClick={(e) => onSearchHandler("search", e)}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-outline-success btn-dim"
                      id="button-button-view"
                      onClick={(e) => onSearchHandler("view", e)}
                    >
                      View all
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      {/* <div className="nk-tb-col tb-col-sm">
                        <span>ID</span>
                      </div> */}
                      <div className="nk-tb-col tb-col-sm">
                        <span>Candidate name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Current/Previous Company</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Excepted Salary</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Experience</span>
                      </div>
                      <div className="nk-tb-col tb-col-md">
                        <span>Notice Period</span>
                      </div>
                      <div className="nk-tb-col tb-col-md">
                        <span>Resume</span>
                      </div>

                      {privileges.includes("schedule_interview") ? (
                        <div className="nk-tb-col tb-col-md">
                          <span>Schedule Interview</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {privileges.includes("schedule_interview_details") ? (
                        <div className="nk-tb-col tb-col-md">
                          <span>Scheduled Interview Details</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {[
                        "view_shortlisted_educational_details",
                        "view_shortlisted_professional_details",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <div className="nk-tb-col tb-col-md">
                          <span>Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {shortlistedCandidate.length !== 0 ? (
                      shortlistedCandidate.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          {/* <div className="nk-tb-col tb-col-sm">
                            <span className="tb-lead">
                              <span className="title">
                                {post.id || '-'}
                              </span>
                            </span>
                          </div> */}
                          <div className="nk-tb-col tb-col-sm">
                            <span className="tb-product">
                              <span className="title">
                                {post.user?.first_name} {post.user?.last_name}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.current_previous_company || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.expected_salary || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.experience || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.notice_period || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            {post?.user_details?.attachment ? (
                              <a
                                className="btn btn-primary"
                                id="button-button-viewresume"
                                href={post?.user_details?.attachment || "-"}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => handleResumeCount(post.id, 1)}
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>

                          {privileges.includes("schedule_interview") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/scheduleinterviewcreate"}
                                state={{ CandidateData: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-"
                              >
                                <em class="icon ni ni-clock"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {privileges.includes("schedule_interview_details") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/scheduleinterviewdetails"}
                                className="btn btn-sm btn-primary"
                                id="button-button-viewinterview"
                                // onClick={() => setCandidateJobID(post.id)}
                              >
                                <em className="icon ni ni-grid-alt"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {[
                            "view_shortlisted_educational_details",
                            "view_shortlisted_professional_details",
                          ].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="me-n1">
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0)"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        {privileges.includes(
                                          "view_shortlisted_educational_details"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/educationdetails"}
                                              id="button-button-educationdetails"
                                              onClick={() =>
                                                getEducationData(post.id)
                                              }
                                            >
                                              <em className="icon ni ni-edit"></em>
                                              <span>Educational Details</span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}

                                        {privileges.includes(
                                          "view_shortlisted_professional_details"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/professionaldetails"}
                                              id="button-button-professionaldetails"
                                              onClick={() =>
                                                getProfessionalData(post.id)
                                              }
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>Professional Details</span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : shortlistedIsData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={shortlistedPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={shortlistedPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleShortlistedPageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:{" "}
                      <span className="fw-bold">
                        {shortlistedEntryCount === null
                          ? 0
                          : shortlistedEntryCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={shortlistedEntries}
                        onChange={handleEntries}
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortlistedCandidate;
