import React, { useContext, useEffect } from "react";
import { ScheduleInterviewContext } from "../../Context/ScheduleInterviewContext";
import { useLocation } from "react-router-dom";

const ScheduleInterviewDetails = () => {
  const { interviewDetails, isLoading, candidateJobID, getInterviewDetails } =
    useContext(ScheduleInterviewContext);
  const location = useLocation();
  console.log("interviewDetails:", interviewDetails);
  console.log("candidateJobID:", candidateJobID);

  useEffect(() => {
    getInterviewDetails(location.state?.CandidateData?.id);
  }, [candidateJobID]);

  return (
    <>
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content d-flex">
                  <h4 className="nk-block-title">
                    Scheduled Interview Details
                  </h4>
                  {isLoading ? (
                    <div class="spinner-border text-primary ms-2" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {isLoading ? (
              <div></div>
            ) : (
              <div>
                {interviewDetails == 0 ? (
                  <div>No interview details found</div>
                ) : (
                  <div className="nk-block">
                    {interviewDetails?.map((info, index) => (
                      <div className="card" key={index}>
                        <div className="card-aside-wrap">
                          <div className="card-content">
                            <div className="card-inner">
                              <div className="nk-block">
                                <div className="profile-ud-list">
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Interview Mode
                                      </span>
                                      <span className="profile-ud-value">
                                        {info?.interview_mode || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Interview Type
                                      </span>
                                      <span className="profile-ud-value">
                                        {info?.interview_type || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Interviewer Name
                                      </span>
                                      {info?.interviewer_name?.map(
                                        (intname) => (
                                          <span className="profile-ud-value">
                                            {intname?.first_name}{" "}
                                            {intname?.last_name}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Interview Date
                                      </span>
                                      <span className="profile-ud-value">
                                        {info?.interview_date || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Interview Time
                                      </span>
                                      <span className="profile-ud-value">
                                        {info?.interview_time || "-"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleInterviewDetails;
