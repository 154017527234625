import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import { RE2Options } from "../WorkflowStepperForm/StepperFormJson";

const RE2Create = () => {
  const userID = secureLocalStorage.getItem("userID");
  const navigate = useNavigate();
  const [submitMessage, setSubmitMessage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  let location = useLocation();
  console.log(location);
  console.log(location.state?.editpost?.requisition);

  const digitalMarketingPlatform = location.state?.editpost?.requisition?.re2_portals
  console.log(digitalMarketingPlatform)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Recruitment Executive - 2 </h4>
          </div>
          <div class="nk-block-head-content">
            <a id="button-back" className="btn btn-light d-none d-sm-inline-flex" onClick={() => navigate(-1)}><em class="icon ni ni-arrow-left"></em><span>Back</span></a>
            <a id="button-back" className="btn btn-icon btn-light d-inline-flex d-sm-none" onClick={() => navigate(-1)}><em class="icon ni ni-arrow-left"></em></a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            {/* <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"> */}
              <div className="row g-4">
                {digitalMarketingPlatform && digitalMarketingPlatform?.map((action, index) => (
                  <div className="col-lg-4 col-md-4 col-sm-12 p-5" key={index}>
                    <h5 className="titlesite d-flex justify-center mb-4">
                      {action}
                    </h5>
                    <div className="custom-control custom-switch d-flex justify-center mb-4">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`customSwitch${action.toLowerCase()}`}
                      />
                      <label
                        className="custom-control-label"
                        id="button-button-status"
                        htmlFor={`customSwitch${action.toLowerCase()}`}
                      ></label>
                    </div>
                    <div className="row">
                      <NavLink
                        to={"/createcandidateresume"}
                        state={{digital_marketing_platform: action,
                        editpost: location.state?.editpost}}
                        className="btn btn-primary d-flex justify-center"
                        id="button-button-re2_candidate_resume"
                      >
                        Upload Resume
                      </NavLink>
                    </div>
                  </div>
                ))}

                <div className="col-lg-4 col-md-4 col-sm-12 p-5">
                  <h5 className="titlesite d-flex justify-center mb-4">
                    Manage Internally
                  </h5>
                  <div className="custom-control custom-switch d-flex justify-center mb-4">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customSwitch5`}
                    />
                    <label
                      className="custom-control-label"
                      id="button-button-status"
                      htmlFor={`customSwitch5`}
                    ></label>
                  </div>
                  <div className="row">
                    <NavLink
                      to={"/createcandidateresume"}
                      className="btn btn-primary d-flex justify-center"
                      id="button-button-re2_manage_internally"
                    >
                      Upload Resume
                    </NavLink>
                  </div>
                </div>
              </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RE2Create;
