import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { VARIABLES } from "../../../Constants";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import Snackbar from "../../../Base/Snackbar";
import { SnackbarContext } from "../../../Context/SnackbarContext";

const AssignAssignment = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const {level,sequenceList,nextLevel,prevLevel,submitLoading,setSubmitLoading} = useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { isGenricDropdownDataLoading, jobAssignmentDropdownData } = useContext(GenericDropdownContext);
  console.log("jobAssignmentDropdownData: ", jobAssignmentDropdownData);

  const { assignment, getAssignmentData } = useContext(MDApprovalContext); //gets the data on behalf of particular candidate
  console.log(assignment);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const [changedAssignment, setChangedAssignment] = useState(null); //state to manage selected assignment
  console.log(changedAssignment);

  const {handleSubmit,formState: { errors },control,reset,setValue} = useForm();

  // On assignment selection
  const handleAssignmentChange = (newChangedAssignment) => {
    console.log("calling");
    if (newChangedAssignment) {
      console.log(newChangedAssignment);
      //checks whether the selected assignment is already present in the job assignment table or not
      //if yes then it will show the assignment preview of the selected option above the dropdown
      const selectedNewAssignment = jobAssignmentDropdownData.find(
        (data) => data.id === newChangedAssignment.value
      );
      setChangedAssignment(selectedNewAssignment);
    } else {
      setValue("choose_assignment", "");
      setChangedAssignment(null);
    }
  };

  //Submission of form
  const onSubmit = (data) => {
    console.log(data);
    let workflow_session = {
      candidate_job_id: candidate_job_id,
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user: userID,
      created_by_id: userID,
      updated_by_id: userID,
    };
    //PATCH REQUEST as an assignment is already assigned to the candidate
    if (assignment.length !== 0) {
      let user = new FormData();
      user.append("candidate_job_id", candidate_job_id);
      user.append("requisition_id", requisition_id);
      user.append("choose_assignment", data.choose_assignment.value);
      user.append("assignment_conducted_user", userID);
      user.append("updated_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      console.log(user);

      const url = VARIABLES.url + `/api/assignment/${assignment[0]?.id}/`;
      console.log(url);
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: url,
        data: user,
      };
      for (var pair of user.entries()) {
        console.log(pair);
      }
      console.log(JSON.stringify(data));
      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          setSubmitLoading(false);
          getAssignmentData(candidate_job_id);
          reset();
          nextLevel();
          getWorkflowSession(1);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Assignment uploaded successfully",
          });
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to upload assignment",
          });
          console.log(error);
        });
    } else {
      //POST REQUEST as its first time to assign the assignment to the candidate
      let user = new FormData();
      user.append("candidate_job_id", candidate_job_id);
      user.append("requisition_id", requisition_id);
      user.append("choose_assignment", data.choose_assignment.value);
      user.append("assignment_conducted_user", userID);
      user.append("created_by_id", userID);
      user.append("updated_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      console.log(user);

      const url = VARIABLES.url + "/api/assignment/";
      console.log(url);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        data: user,
      };

      for (var pair of user.entries()) {
        console.log(pair);
      }

      console.log(JSON.stringify(data));
      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          getAssignmentData(candidate_job_id);
          nextLevel();
          getWorkflowSession(1);
          reset();
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Assignment uploaded successfully",
          });
          console.log("next click");
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to upload assignment",
          });
        });
    }
  };

  //Pre-populating data
  useEffect(() => {
    const editedData = assignment[0];
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      const getAssignment = {
        value: editedData?.choose_assignment?.id,
        label: editedData?.choose_assignment?.assignment_title,
      };
      defaultValues.choose_assignment = getAssignment;

      reset(defaultValues);
    }
  }, [assignment]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Assign Assignment </h4>
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4 ">
                {/* shows the attachment of selected assignment */}
                {changedAssignment?.assignment_document &&
                  assignment[0]?.choose_assignment?.assignment_document && (
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="profile-ud-item p-0">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            View Assignment :
                          </span>
                          <span className="profile-ud-value align-start">
                            <a
                              href={changedAssignment?.assignment_document}
                              target="_blank"
                              className="text-start"
                            >
                              View
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Choose Assignment
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={jobAssignmentDropdownData?.map(
                          (assignment) => ({
                            label: assignment.assignment_title,
                            value: assignment.id,
                          })
                        )}
                        onChange={(e) => {
                          handleAssignmentChange(e);
                          field.onChange(e);
                        }}
                        isLoading={isGenricDropdownDataLoading}
                        isClearable={true}
                        id="select-forms-choose_assignment"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="choose_assignment"
                    control={control}
                  />
                  {errors.choose_assignment && (
                    <span className="fs-8 text-danger">
                      Assignment is required
                    </span>
                  )}
                </div>

                <div className="col-12 d-flex">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Snackbar />
      </div>

      {/* Submit message */}
    </>
  );
};

export default AssignAssignment;
