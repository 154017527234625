import React, { useContext, useEffect, useState } from "react";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import axios from "axios";
import { VARIABLES } from "../../../Constants";

const TechnicalInterviewReview = ({ formId }) => {
  const {
    isTechnicalDetailsLoading,
    getTechnicalInterviewDetails,
    technicalDetails,
  } = useContext(InterviewQuestionContext);

  console.log("technicalDetails: ", technicalDetails);

  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  // localstorage get
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const [categoryData, setCategoryData] = useState([]);
  console.log(categoryData);
  const [rowData, setRowData] = useState([]);
  console.log(rowData);

  //form submission
  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      interview_id: 1,
      candidate_resume: candidate_job_id,
      requisition_id: requisition_id,
      candidate_status: data.is_selected,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_job_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    console.log(user);

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/technicalinterview/${technicalDetails?.id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        nextLevel();
        getWorkflowSession(1);
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Interview reviewed successfully",
        });
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Review failed",
        });
      });
  };

  useEffect(() => {
    var defaultValues = {};
    if (technicalDetails?.categories_data) {
      const category_data = technicalDetails?.categories_data?.filter(
        (api) => api.score !== 0
      );
      setCategoryData(category_data);
    }

    if (technicalDetails?.questions_data) {
      const question_data = technicalDetails?.questions_data?.map((api) => api);
      setRowData(question_data);
    }
    if (technicalDetails?.interview_details) {
      defaultValues.feedback = technicalDetails?.interview_details.feedback;
    }
    reset(defaultValues);
  }, [technicalDetails]);

  useEffect(() => {
    getTechnicalInterviewDetails(candidate_job_id, requisition_id);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Technical Interview Review</h4>
            {isTechnicalDetailsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isTechnicalDetailsLoading ? (
        <div></div>
      ) : (
        <div className="row">
          <div className="card">
            {technicalDetails ? (
              <>
                <div className="card-inner">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryData?.map((info) => (
                          <tr>
                            <td>{info?.category || "-"}</td>
                            <td>{info?.score || "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div className="card-inner">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Question</th>
                            <th>Score</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowData?.map((info) => (
                            <tr>
                              <td>{info?.question || "-"}</td>
                              <td>{info?.score || "-"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-6 mt-4 mb-4">
                      <div className="form-group">
                        <label className="form-label">Feedback</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("feedback")}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">
                              Candidate Status :{" "}
                            </span>
                            <span
                              className="profile-ud-value fw-bold align-start"
                              style={{
                                color:
                                  technicalDetails?.interview_details
                                    ?.candidate_status === "Reject"
                                    ? "red"
                                    : technicalDetails?.interview_details
                                        ?.candidate_status === "Accept"
                                    ? "green"
                                    : "black",
                              }}
                            >
                              {technicalDetails?.interview_details
                                ?.candidate_status || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <ul className="row g-3">
                        {interviewActionDropdownData.map((action, index) => (
                          <li className="col-sm-4" key={index}>
                            <div className="custom-control custom-radio me-3">
                              <input
                                type="radio"
                                id={`checkbox-form-is-selected-technicalreview-${action.value.toLowerCase()}`}
                                className="custom-control-input"
                                value={action.value}
                                {...register("is_selected", { required: true })}
                              />
                              <label
                                className="custom-control-label"
                                for={`checkbox-form-is-selected-technicalreview-${action.value.toLowerCase()}`}
                              >
                                {action.name}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {errors.is_selected && (
                        <span className="fs-8 text-danger">
                          Please select any one
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <div>No Technical interview details found</div>
            )}
            <div className="card-inner">
              <div className="col-12 d-flex mt-4">
                <div className="form-group">
                  {level !== 0 && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary me-3"
                      id="button-button-prevlevel"
                      onClick={() => prevLevel()}
                    >
                      Prev
                    </button>
                  )}
                </div>
                <div className="form-group">
                  {submitLoading ? (
                    <button className="btn text-dark" disabled>
                      Loading...
                    </button>
                  ) : (
                    <>
                      {sequenceList.length - 2 > level ? (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-techinterview"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-techinterview"
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TechnicalInterviewReview;
