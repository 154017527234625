import axios from "axios";
import React, { createContext, useState } from "react";
import { VARIABLES } from "../Constants";

export const KYCContext = createContext(null);

function KYCContextProvider({ children }) {
  const [KYCDetails, setKYCDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getKYCDetails = async (id) => {
    setIsLoading(true);
    let url;
    url = VARIABLES.url + `/api/knowyourcandidate/`;
    try {
      const res = await axios.get(url);
      setKYCDetails(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const value = {
    KYCDetails,
    isLoading,
    getKYCDetails,
  };
  return (
    <div>
      <KYCContext.Provider value={value}>{children}</KYCContext.Provider>
    </div>
  );
}

export default KYCContextProvider;
