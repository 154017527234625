import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";

const CandidateResumeEdit = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);

  const { getCandidateResume } = useContext(CandidateResumeContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();
  let location = useLocation();
  let navigate = useNavigate();

  const deleteCandidateResume = () => {
    const url =
      VARIABLES.url +
      `/api/candidateresume/${location.state.editdata.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {},
    };
    axios(config)
      .then((response) => {
        getCandidateResume();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the candidate resume",
        });
        console.log(JSON.stringify(response.data));
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setActiveStatus(1);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to delete the candidate resume",
        });
        console.log(error);
      });
  };

  const deleteCandidateResumeAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCandidateResume();
      } else {
        setActiveStatus(1);
      }
    });
  };

  const onSubmit = (data) => {
    console.log(data);
    let user = new FormData();
    user.append("candidate_name", data.candidate_name);
    user.append("email", data.email);
    user.append("mobile", data.mobile);
    user.append("resume", data.resume);
    user.append("updated_by_id", userID);

    const url =
      VARIABLES.url + `/api/candidateresume/${location.state.editdata.id}/`;
    console.log(url);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      data: user,
    };
    console.log(user);
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Sucessfully updated the candidate resume",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed update candidate resume",
        });
      });
  };

  useEffect(() => {
    console.log(location.state.editdata);
    const editedCandidateResume = location.state.editdata;
    if (editedCandidateResume) {
      var defaultValues = {};
      defaultValues.candidate_name = editedCandidateResume.candidate_name;
      defaultValues.email = editedCandidateResume.email;
      defaultValues.mobile = editedCandidateResume.mobile.slice(3);
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Resume pool </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          {privileges.includes("candidate_resume_delete") ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-delete-candidateresume"
                  checked={activeStatus}
                  onChange={(e) => {
                    deleteCandidateResumeAlert(e.target.checked);
                  }}
                />
                <label
                  className="custom-control-label"
                  id="button-button-status"
                  htmlFor="submit-button-delete-candidateresume"
                ></label>
              </div>
            </div>
          ) : (
            " "
          )}
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Candidate Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="text-forms-candidatename"
                    placeholder="Enter Candidate name"
                    {...register("candidate_name", { required: true })}
                  />
                  {errors.candidate_name && (
                    <span className="fs-8 text-danger">
                      Candidate name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Email
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="text-forms-email"
                    placeholder="Enter Email"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="fs-8 text-danger">Email is required</span>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Mobile
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="text-forms-mobile"
                    placeholder="Enter mobile"
                    {...register("mobile", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                    })}
                  />
                  {errors.mobile && errors.mobile.type == "required" && (
                    <span className="fs-8 text-danger">Mobile is required</span>
                  )}
                  {errors.mobile && errors.mobile.type == "minLength" && (
                    <span className="fs-8 text-danger">
                      Number should be minimum 10 digits
                    </span>
                  )}
                  {errors.mobile && errors.mobile.type == "maxLength" && (
                    <span className="fs-8 text-danger">
                      Number should be maximum 10 digits
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Resume <span className="fs-8 text-danger"> *</span>
                  </label>
                  <Controller
                    name="resume"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => value.type === "application/pdf",
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-resume"
                        type="file"
                        multiple
                        onChange={(e) => field.onChange(e.target.files[0])}
                      />
                    )}
                  />
                  {errors.resume && errors.resume.type === "required" && (
                    <span className="fs-8 text-danger">Resume is required</span>
                  )}
                  {errors.resume && errors.resume.type === "validate" && (
                    <span className="fs-8 text-danger">
                      Please upload your document in pdf format
                    </span>
                  )}
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editcandidateresume"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateResumeEdit;
