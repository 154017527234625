import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const BackgroundVerificationContext = createContext(null);

function BackgroundVerificationContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);

  const [bgVerification, setBGVerification] = useState([]);
  console.log(bgVerification);

  const getBGVerification = async () => {
    setIsLoading(true);
    let url;
    url = VARIABLES.url + `/api/backgroundverification/`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setBGVerification(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const value = {
    isLoading,
    bgVerification,
    getBGVerification,
  };
  return (
    <div>
      <BackgroundVerificationContext.Provider value={value}>
        {children}
      </BackgroundVerificationContext.Provider>
    </div>
  );
}

export default BackgroundVerificationContextProvider;
