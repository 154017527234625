import React, { Component, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Document, Page } from "react-pdf";
import secureLocalStorage from "react-secure-storage";
import { NavLink, useNavigate } from "react-router-dom";
import RejectCandidate from "./RejectCandidate";

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      // className="btn btn-white btn-dim resumebtn"
      className="btn btn-outline-dark"
      id="rightarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      {/* <h4 style={{ marginBottom: "0px" }}>next</h4> */}
      <em className="icon ni ni-forward-ios resume-icon"></em>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      // className="btn btn-white btn-dim resumebtn"
      className="btn btn-outline-dark "
      id="leftarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <em className="icon ni ni-back-ios resume-icon"></em>
      {/* <h4>back</h4> */}
    </button>
  );
}

const Slide = ({ document }) => {
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    if (document) {
      setNumPages(null);
    }
  }, [document]);

  const handleLoadSuccess = ({ numPages }) => {
    console.log("PDF loaded successfully:", numPages, "pages");
    setNumPages(numPages);
  };

  const handleError = (error) => {
    console.error("PDF Error:", error);
  };

  const renderPages = () => {
    const pages = [];
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      pages.push(
        <div key={pageNumber} className="pdf-page">
          <Page pageNumber={pageNumber} scale={2} />
        </div>
      );
    }
    return pages;
  };

  console.log("Document path:", document);
  console.log("Number of pages:", numPages);

  return (
    <div className="landscape-pdf-container">
      {document && (
        <Document
          file={document}
          onLoadSuccess={handleLoadSuccess}
          onError={handleError}
        >
          {numPages && renderPages()}
        </Document>
      )}
    </div>
  );
};

const CandidatePoolResumes = (props) => {
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  console.log("currentData:", currentData);
  console.log("ResumeData:", props.rawResumeData);
  const privileges = secureLocalStorage.getItem("privileges");

  const [storedStatus, setStoredStatus] = useState([]);
  console.log("storedStatus:", storedStatus);

  console.log(props.shortlisting_type);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const handleSliderChange = (index) => {
    console.log(index);
    console.log(props.rawResumeData[index]);
    setCurrentData(props.rawResumeData[index]);
  };

  console.log(props.jobID);

  const handleSubmit = (is_shortlisting, data) => {
    console.log(data);
    let user = new FormData();
    user.append("requisition_id", props.jobID);
    user.append("candidate_status", is_shortlisting);
    user.append("updated_by_id", userID);
    var config = {
      method: "patch",
      url:
        props.shortlisting_type === "RC"
          ? `${VARIABLES.url}/api/candidateresume/${data.id}/?shortlisting_type=RC`
          : `${VARIABLES.url}/api/candidateresume/${data.id}/?shortlisting_type=SME`,
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    console.log(config);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setStoredStatus((prevStoredData) => ({
          ...prevStoredData,
          [data.id]: is_shortlisting,
        }));
        props.setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Candidate shortlisted successfully",
        });
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        props.setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to shortlist candidate",
        });
      });
  };

  useEffect(() => {
    if (props.rawResumeData.length > 0) {
      handleSliderChange(0);
    }
  }, [props.rawResumeData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      props.setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [props.submitMessage]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between d-flex justify-end">
          {props.shortlisting_type !== "SME" && (
            <>
              {privileges.includes("view_all_resume") ? (
                <div className="nk-block-head-content me-2">
                  <NavLink
                    to={"/viewallresume"}
                    onClick={() => props.getResumeData(props.jobID)}
                    state={{
                      jobID: props.jobID,
                      shortlisting_type: props.shortlisting_type,
                    }}
                    className="toggle btn btn-icon btn-primary d-md-none"
                    id="button-button-viewallresume"
                  >
                    <em className="icon ni ni-plus"></em>
                  </NavLink>

                  <NavLink
                    to={"/viewallresume"}
                    onClick={() => props.getResumeData(props.jobID)}
                    state={{
                      jobID: props.jobID,
                      shortlisting_type: props.shortlisting_type,
                    }}
                    className="toggle btn btn-primary d-none d-md-inline-flex"
                    id="button-button-viewallresume"
                  >
                    <em className="icon ni ni-eye"></em>
                    <span>View all resumes</span>
                  </NavLink>
                </div>
              ) : (
                "-"
              )}
            </>
          )}
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      {props.isLoading ? (
        <div> loading... </div>
      ) : props.rawResumeData.length === 0 ? (
        <p>No candidate data available.</p>
      ) : (
        <div className="resume-container">
          <div className="resume-result">
            <div>
              {submitLoading ? (
                <button className="btn text-dark" disabled>
                  Loading...
                </button>
              ) : storedStatus[currentData?.id] === "reject" ? (
                <button type="button" className="btn btn-secondary" disabled>
                  REJECTED
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-danger"
                  id="submit-button-rejectresume"
                  data-bs-toggle="modal"
                  data-bs-target="#rejectcandidate"
                  state={{ editpost: currentData }}
                >
                  REJECT
                </button>
              )}
            </div>

            <div>
              {submitLoading ? (
                <button className="btn text-dark" disabled>
                  Loading...
                </button>
              ) : storedStatus[currentData?.id] === "accept" ? (
                <button type="button" className="btn btn-secondary" disabled>
                  ACCEPTED
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-success"
                  id="submit-button-acceptresume"
                  onClick={() => handleSubmit("accept", currentData)}
                >
                  ACCEPT
                </button>
              )}
            </div>
          </div>
          <Slider {...settings} afterChange={handleSliderChange}>
            {props.rawResumeData.map((data, dataIndex) => (
              <div key={dataIndex}>
                <div>
                  <Slide key={dataIndex} document={data?.resume} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}

      <div>
        {props.submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${props.submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${props.submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{props.submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <RejectCandidate
        setSubmitMessage={props.setSubmitMessage}
        shortlisting_type={props.shortlisting_type}
        editdata={currentData}
        jobID={props.jobID}
        setStoredStatus={setStoredStatus}
      />
    </>
  );
};

export default CandidatePoolResumes;
