import React, { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";

const ViewAllRequisition = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const {
    setSelectedRequisitionStatus,
    selectedRequisitionStatus,
    isAllRequistionData,
    rawAllRequistionData,
    getAllRequistion,
    allRequisitionEntries,
    allRequistionPageCount,
    setAllRequistionEntries,
    allRequisitionCount,
    handleQuestionClick,
    isAllRequisitionLoading,
  } = useContext(RequestedJobOpeningContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const handleEntries = (event) => {
    setAllRequistionEntries(event.target.value);
  };

  useEffect(() => {
    getAllRequistion(1);
  }, [allRequisitionEntries, selectedRequisitionStatus]);

  console.log(rawAllRequistionData);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">All Requisitions</h3>
            {isAllRequisitionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              className="btn btn-light d-none d-sm-inline-flex"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
        {/* <!-- .nk-block-between --> */}
      </div>

      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            <div className="row d-flex">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label"> Requisition Status</label>
                  <Select
                    options={[
                      { value: null, label: "All" },
                      { value: "True", label: "Accepted" },
                      { value: "False", label: "Rejected" },
                    ]}
                    isClearable={true}
                    id="select-forms-requisitionstatus"
                    onChange={(selectedOption) =>
                      setSelectedRequisitionStatus(selectedOption)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-inner-group">
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Vacancy</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Active/ Inactive</span>
                      </div>

                      {["job_opening_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <div className="nk-tb-col">
                          <span>Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawAllRequistionData.length !== 0 ? (
                      rawAllRequistionData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name + post?.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post?.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.job_opening?.department
                                ?.department_display_name || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.job_type || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.no_of_vacancy || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition_acceptance !== null ? (
                                <>
                                  {post?.requisition_acceptance === true ? (
                                    <span class="badge bg-success">Active</span>
                                  ) : (
                                    (
                                      <span class="badge bg-danger">
                                        Inactive
                                      </span>
                                    ) || "-"
                                  )}
                                </>
                              ) : (
                                <span class="badge bg-warning">Pending</span>
                              )}
                            </span>
                          </div>

                          {["job_opening_retrieve"].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="me-n1">
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0)"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                      id="list-dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        {privileges.includes(
                                          "job_opening_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/jobopeningdetails"}
                                              state={{ editpost: post }}
                                              id="button-button-jobopeningdetails"
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>View Requisition Details</span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isAllRequistionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={allRequistionPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={allRequistionPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) => handleQuestionClick(data)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {allRequisitionCount === null ? 0 : allRequisitionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={allRequisitionEntries}
                        onChange={handleEntries}
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAllRequisition;
