import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";

const SmartHireForm = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present

  const {
    level,
    sequenceList,
    setSequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { SHFInterviewQuestion, getSHFInterviewQuestions, isQuestionLoading } =
    useContext(InterviewQuestionContext);
  console.log(SHFInterviewQuestion); //all interview questionnaire data

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");
  const job_opening_id = secureLocalStorage.getItem("WF_job_opening_id");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  //Submission of form
  const onSubmit = (data) => {
    console.log(data);
    const interviewQuestions = [...SHFInterviewQuestion];

    console.log(data);
    console.log(interviewQuestions);

    const questionsWithAnswers = interviewQuestions?.map((post, index) => {
      const question = post.question;
      const answer = data[`question${index}`];
      return { question, answer };
    });
    questionsWithAnswers.push({ question: "Name", answer: data.name });
    questionsWithAnswers.push({ question: "Email", answer: data.email });
    console.log(questionsWithAnswers);

    var user = JSON.stringify({
      interview_id: 1,
      candidate_job_id: candidate_job_id,
      requisition_id: requisition_id,
      question_status: true,
      score: 0,
      comments: null,
      question_answers: questionsWithAnswers,
      created_by_id: userID,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_job_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    console.log(user);

    const url = VARIABLES.url + "/api/smarthireform/";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        // workflowSession();
        nextLevel();
        getWorkflowSession(1);
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Success",
        });
        reset();
        console.log("next click");
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed",
        });
      });
  };

  useEffect(() => {
    getSHFInterviewQuestions(job_opening_id);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Smart Hire Form </h4>
            {isQuestionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4 ">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email-forms-email"
                      placeholder="Enter email"
                      {...register("email", {
                        required: true,
                      })}
                    />
                    {errors.email && (
                      <span className="fs-8 text-danger">
                        Email is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-name"
                      placeholder="Enter name"
                      {...register("name", {
                        required: true,
                      })}
                    />
                    {errors.name && (
                      <span className="fs-8 text-danger">Name is required</span>
                    )}
                  </div>
                </div>

                {SHFInterviewQuestion?.map((post, index) => (
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{post?.question}</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`text-forms-question-${index}`}
                        placeholder="Your answer"
                        {...register(`question${index}`)}
                      />
                    </div>
                  </div>
                ))}

                <div className="col-12 d-flex">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    {submitLoading ? (
                      <button className="btn text-dark" disabled>
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-submitshf"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-submitshf"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Snackbar />
    </>
  );
};

export default SmartHireForm;
