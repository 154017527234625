import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TagContext } from "../../Context/TagContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const EditTag = () => {
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");

  const { getTag } = useContext(TagContext);

  const { isGenricDropdownDataLoading, tagCategoryDropdownData } = useContext(
    GenericDropdownContext
  );

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  let location = useLocation();
  let navigate = useNavigate();

  const onSubmit = (data) => {
    var form_data = JSON.stringify({
      tag_name: data.name,
      value: data.value,
      tag_category_id: data.tag_category.value,
      description: data.description, // TODO : Until we gets userID, use default value as 1.
      updated_by_id: userID,
    });

    // TODO : Check the API properly from postman
    const url = VARIABLES.url + `/api/tag/${location.state.editdata.id}/`;
    console.log(url);
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Tag successfully updated",
        });
        getTag();
        reset();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update tag",
        });
      });
  };

  const deleteTag = () => {
    const url =
      VARIABLES.url +
      `/api/tag/${location.state.editdata.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {},
    };
    axios(config)
      .then((response) => {
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted tag",
        });
        console.log(JSON.stringify(response.data));
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setActiveStatus(1);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to delete tag",
        });
        console.log(error);
      });
  };

  const deleteTagAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      title: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTag();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editdata);
    const editedTag = location.state.editdata;
    if (editedTag) {
      var defaultValues = {};
      defaultValues.name = editedTag.name;
      defaultValues.value = editedTag.value;
      if (editedTag.tag_category?.tag_category_name !== null) {
        const gettag_category = {
          value: editedTag.tag_category.id,
          label: editedTag.tag_category.tag_category_name,
        };
        defaultValues.tag_category = gettag_category;
      }

      defaultValues.description = editedTag.description;
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    getTag();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit tag </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            {privileges.includes("tag_delete") ? (
              <div className="card-inner d-flex justify-content-end pt-3 pb-0">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="submit-button-deletetag"
                    checked={activeStatus}
                    onChange={(e) => {
                      deleteTagAlert(location.state?.editdata?.id);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    id="button-button-status"
                    htmlFor="submit-button-deletetag"
                  ></label>
                </div>
              </div>
            ) : (
              ""
            )}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <label className="form-label">
                    Tag Category
                    <span className="fs-8 text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={tagCategoryDropdownData.map((category) => ({
                          value: category.id,
                          label: category.tag_category_name,
                        }))}
                        isLoading={isGenricDropdownDataLoading}
                        isClearable={true}
                        id="select-forms-tag_category"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="tag_category"
                    control={control}
                  />
                  {errors.tag_category && (
                    <span className="fs-8 text-danger">
                      Tag category is required
                    </span>
                  )}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <label className="form-label">
                    Tag Display Name
                    <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter tag display name"
                    id="text-forms-tag_display_name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="fs-8 text-danger">
                      Tag display name is required
                    </span>
                  )}
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <label className="form-label">
                    Tag Name
                    <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter tag name"
                    id="text-forms-tag_value"
                    {...register("value", { required: true })}
                  />
                  {errors.value && (
                    <span className="fs-8 text-danger">
                      Tag name is required
                    </span>
                  )}
                </div>
                <div className="col-12">
                  <label className="form-label"> Description</label>
                  <textarea
                    className="form-control"
                    id="textarea-forms-description"
                    placeholder="Enter description"
                    {...register("description")}
                  />
                  {errors.description && (
                    <span className="fs-8 text-danger"></span>
                  )}
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-edittag"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditTag;
