import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { CandidateEntriesContext } from "../../Context/CandidateEntriesContext";

const CandidateEntries = () => {
  const [searchCandidate, setSearchCandidate] = useState("");
  console.log(searchCandidate);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawCandidateDetails,
    getCandidateEntriesData,
    candidateEntries,
    candidatePageCount,
    setCandidateEntries,
    candidateCount,
    handlePageClick,
    isCandidateData,
    isCandidateLoading,
    setRawCandidateDetails,
  } = useContext(CandidateEntriesContext);

  const handleEntries = (event) => {
    setCandidateEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchCandidate(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getCandidateEntriesData(1, searchCandidate);
    } else {
      getCandidateEntriesData(1);
      setSearchCandidate("");
    }
  };

  const handleSorting = (event) => {

  };

  useEffect(() => {
    
      getCandidateEntriesData(1, searchCandidate);
    
  }, [candidateEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Candidate Details</h3>
            {isCandidateLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchCandidate}
                      className="form-control"
                      placeholder="Search using Candidate name, job title"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      {/* <div className="nk-tb-col">
                        <span>Department</span>
                      </div> */}
                      {/* <div className="nk-tb-col">
                        <span>Job Type</span>
                      </div> */}
                      <div className="nk-tb-col">
                        <span>Applied on</span>
                        
                      </div>
                      <div className="nk-tb-col">
                        <span>Candidate Details</span>
                      </div>
                      
                    </div>

                    {rawCandidateDetails.length !== 0 ? (
                      rawCandidateDetails.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                              {post?.user?.first_name || post?.user?.last_name ? post?.user?.first_name + " " + post?.user?.last_name : "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.job_opening?.job_title || "-"}
                            </span>
                          </div>
                          {/* <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.job_type || "-"}
                            </span>
                          </div> */}
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.created_at ? moment(post?.created_at).format("hh:mm a DD-MM-YYYY") : "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <NavLink
                              to={"/candidateotherdetails"}
                              state={{ editpost: post }}
                              className="btn btn-sm btn-primary"
                              id="button-button-candidateotherdetails"
                            >
                              <em className="icon ni ni-eye"></em>
                            </NavLink>
                          </div>
                          
                        </div>
                      ))
                    ) : isCandidateData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidatePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidatePageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handlePageClick(data, searchCandidate)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {candidateCount === null ? 0 : candidateCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateEntries;
