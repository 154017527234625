import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const HRD = () => {
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawRequestedJob,
    getRawRequestedJobData,
    requestedEntries,
    requestPageCount,
    setRequestedEntries,
    requestedCount,
    handlePageClick,
    isData,
    isLoading,
  } = useContext(RequestedJobOpeningContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const handleEntries = (event) => {
    setRequestedEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRawRequestedJobData(1, searchRequest);
    } else {
      getRawRequestedJobData(1);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    getRawRequestedJobData(1);
  }, [requestedEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">HRD Approval</h3>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search by requisition id, job title..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th>Requisition ID</th>
                      <th>Vacancy</th>
                      <th colSpan="2" className="text-center">
                        Candidate in Process
                      </th>
                      <th colSpan="2" className="text-center">
                        Shortlisted Candidate
                      </th>
                      <th colSpan="2" className="text-center">
                        Round 1
                      </th>
                      <th colSpan="2" className="text-center">
                        Round 2-F2F
                      </th>
                      <th colSpan="2" className="text-center">
                        Round 3-Assignment
                      </th>
                      <th colSpan="2" className="text-center">
                        Test
                      </th>
                      <th colSpan="2" className="text-center">
                        SHF
                      </th>
                      <th colSpan="2" className="text-center">
                        BGV
                      </th>
                      <th>HRD Approval</th>
                      <th>MD Approval</th>
                      <th>Details</th>
                    </tr>
                    <tr className="border-bottom">
                      <td></td>
                      <td></td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td>Recieved</td>
                      <td>Processed</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {rawRequestedJob.length !== 0 ? (
                      rawRequestedJob.map((post, index) => (
                        <tr key={index}>
                          <td>
                            {(requisitionID &&
                              requisitionID[0]?.name + post?.id) ||
                              "-"}
                          </td>
                          <td>{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td className="text-center">{post?.id || "-"}</td>
                          <td>
                            {["job_opening_retrieve"].some((privilege) =>
                              privileges.includes(privilege)
                            ) ? (
                              <div className="nk-tb-col nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-1 my-n1">
                                  <li className="me-n1">
                                    <div className="dropdown">
                                      <a
                                        href="javascript:void(0)"
                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                        data-bs-toggle="dropdown"
                                        id="list-dropdown"
                                      >
                                        <em className="icon ni ni-more-h"></em>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-end">
                                        <ul className="link-list-opt no-bdr">
                                          {privileges.includes(
                                            "job_opening_retrieve"
                                          ) ? (
                                            <li>
                                              <NavLink
                                                to={"/jobopeningdetails"}
                                                state={{ editpost: post }}
                                                id="button-button-jobopeningdetails"
                                              >
                                                <em className="icon ni ni-eye"></em>
                                                <span>
                                                  View Requisition Details
                                                </span>
                                              </NavLink>
                                            </li>
                                          ) : (
                                            " "
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              " "
                            )}
                          </td>
                        </tr>
                      ))
                    ) : isData ? (
                      <tr>
                        <td colSpan="7">No Data Found</td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="7">Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* <table border="1">
                <tr>
                  <th>ID</th>
                  <th colspan="2">Test Heading</th>
                </tr>
                <tr>
                  <th></th>
                  <th>First col text</th>
                  <th>First line</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>1</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>2</td>
                </tr>
              </table> */}
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={requestPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={requestPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handlePageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {requestedCount === null ? 0 : requestedCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={requestedEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRD;
