import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { useLocation } from "react-router-dom";
import { WorkflowContext } from "../../Context/WorkflowContext";

const JobWorkflowCreate = (props) => {
  console.log(props.categoryName)
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
  let location = useLocation();
  console.log(location);

  const { getWorkflowData } = useContext(WorkflowContext);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    console.log(data);
    var workflowname = JSON.stringify({
      workflow_name: data.workflow_name,
      workflow_category: props.categoryName,
      created_by_id: userID,
      updated_by_id: userID,
    });
    console.log(workflowname)

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/workflow/",
      headers: {
        "Content-Type": "application/json",
      },
      data: workflowname,
    };

    setIsLoading(true);
    axios(config)
      .then((response) => {
        setIsLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Workflow Successfully Created",
        });
        getWorkflowData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to Create Workflow",
        });
      });
    reset();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-9">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="text-form-workflow_name"
                      placeholder="Enter Workflow name"
                      {...register("workflow_name", { required: true })}
                    />
                    {errors.workflow_name && (
                      <span className="fs-8 text-danger">
                        Workflow name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group d-flex justify-content-center">
                    {isLoading ? (
                      <button id="button-loading" className="btn text-dark" disabled>
                        Creating workflow...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-md btn-primary"
                        id="submit-button-createworkflow"
                      >
                        Create Workflow
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          {submitMessage && (
            <div className="example-alerts position-fixed bottom-0 end-0 p-3">
              <div className="gy-4">
                <div className="example-alert">
                  <div
                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                  >
                    <em
                      className={`icon ni ni-${submitMessage.icon}-circle`}
                    ></em>
                    <strong>{submitMessage.message}</strong>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JobWorkflowCreate;
