import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const MasterTableContext = createContext(null);

function MasterTableProvider({ children }) {

  // Department Data stores in "rawDepartment".
  const [isDepartmentData, setIsDepartmentData] = useState(false);
  const [rawDepartment, setRawDepartment] = useState([]);
  console.log(rawDepartment)
  const [departmentEntries, setDepartmentEntries] = useState(5);
  const [departmentCount, setDepartmentCount] = useState(null);
  const [departmentPageCount, setDepartmentPageCount] = useState(0);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(true);
  
  // Team Data stores in "rawTeam".
  const [isTeamData, setIsTeamData] = useState(false);
  const [rawTeam, setRawTeam] = useState([]);
  console.log(rawTeam)
  const [teamEntries, setTeamEntries] = useState(5);
  const [teamCount, setTeamCount] = useState(null);
  const [teamPageCount, setTeamPageCount] = useState(0);
  const [isTeamLoading, setIsTeamLoading] = useState(true);

  // Position Data stores in "rawPosition".
  const [isPositionData, setIsPositionData] = useState(false);
  const [rawPosition, setRawPosition] = useState([]);
  const [positionEntries, setPositionEntries] = useState(5);
  const [positionCount, setPositionCount] = useState(null);
  const [positionPageCount, setPositionPageCount] = useState(0);
  const [isPositionLoading, setIsPositionLoading] = useState(true);

  // Seniority Data stores in "rawSeniority".
  const [isSeniorityData, setIsSeniorityData] = useState(false);
  const [rawSeniority, setRawSeniority] = useState([]);
  const [seniorityEntries, setSeniorityEntries] = useState(5);
  const [seniorityCount, setSeniorityCount] = useState(null);
  const [seniorityPageCount, setSeniorityPageCount] = useState(0);
  const [isSeniorityLoading, setIsSeniorityLoading] = useState(true);

  // Agency Data stores in "rawAgency".
  const [isAgencyData, setIsAgencyData] = useState(false);
  const [rawAgency, setRawAgency] = useState([]);
  const [agencyEntries, setAgencyEntries] = useState(5);
  const [agencyCount, setAgencyCount] = useState(null);
  const [agencyPageCount, setAgencyPageCount] = useState(0);
  const [isAgencyLoading, setIsAgencyLoading] = useState(true);

  const getDepartmentData = async (page, searchDepartment) => {
    setIsDepartmentLoading(true);
    console.log(searchDepartment);
    let url;
    if (searchDepartment) {
      url =
        VARIABLES.url +
        `/api/department/?entries=${departmentEntries}&page=${page}&event=${searchDepartment}`;
    } else {
      url =
        VARIABLES.url +
        `/api/department/?entries=${departmentEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsDepartmentLoading(false);
      if (response.length !== 0) {
        setIsDepartmentData(false);
        setRawDepartment(res.data.data);
      } else {
        setIsDepartmentData(true);
        setRawDepartment(res.data.data);
      }
      const total = res.data.count;
      setDepartmentCount(total);
      setDepartmentPageCount(Math.ceil(total / departmentEntries));
    } catch (error) {
      console.log(error);
      setIsDepartmentLoading(false);
      setIsDepartmentData(true);
    }
  };

  const handleDepartmentClick = async (data, searchDepartment) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getDepartmentData(
      currentPage,
      searchDepartment
    );
  };

  const getTeamData = async (page, searchTeam) => {
    setIsTeamLoading(true);
    console.log(searchTeam);
    let url;
    if (searchTeam) {
      url =
        VARIABLES.url +
        `/api/team/?entries=${teamEntries}&page=${page}&event=${searchTeam}`;
    } else {
      url = VARIABLES.url + `/api/team/?entries=${teamEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsTeamLoading(false);
      if (response.length !== 0) {
        setIsTeamData(false);
        setRawTeam(res.data.data);
      } else {
        setIsTeamData(true);
        setRawTeam(res.data.data);
      }
      const total = res.data.count;
      setTeamCount(total);
      setTeamPageCount(Math.ceil(total / teamEntries));
    } catch (error) {
      console.log(error);
      setIsTeamLoading(false);
      setIsTeamData(true);
    }
  };

  const handleTeamClick = async (data, searchTeam) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getTeamData(currentPage, searchTeam);
  };

  const getPositionData = async (page, searchPosition) => {
    setIsPositionLoading(true);
    console.log(searchPosition);
    let url;
    if (searchPosition) {
      url =
        VARIABLES.url +
        `/api/position/?entries=${positionEntries}&page=${page}&event=${searchPosition}`;
    } else {
      url =
        VARIABLES.url +
        `/api/position/?entries=${positionEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsPositionLoading(false);
      if (response.length !== 0) {
        setIsPositionData(false);
        setRawPosition(res.data.data);
      } else {
        setIsPositionData(true);
        setRawPosition(res.data.data);
      }
      const total = res.data.count;
      setPositionCount(total);
      setPositionPageCount(Math.ceil(total / positionEntries));
    } catch (error) {
      console.log(error);
      setIsPositionLoading(false);
      setIsPositionData(true);
    }
  };

  const handlePositionClick = async (data, searchPosition) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getPositionData(
      currentPage,
      searchPosition
    );
  };

  const getSeniorityData = async (page, searchSeniority) => {
    setIsSeniorityLoading(true);
    console.log(searchSeniority);
    let url;
    if (searchSeniority) {
      url =
        VARIABLES.url +
        `/api/seniority/?entries=${seniorityEntries}&page=${page}&event=${searchSeniority}`;
    } else {
      url =
        VARIABLES.url +
        `/api/seniority/?entries=${seniorityEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      console.log(response)
      setIsSeniorityLoading(false);
      if (response.length !== 0) {
        setIsSeniorityData(false);
        setRawSeniority(res.data.data);
      } else {
        setIsSeniorityData(true);
        setRawSeniority(res.data.data);
      }
      const total = res.data.count;
      setSeniorityCount(total);
      setSeniorityPageCount(Math.ceil(total / seniorityEntries));
    } catch (error) {
      console.log(error);
      setIsSeniorityLoading(false);
      setIsSeniorityData(true);
    }
  };

  const handleSeniorityClick = async (data, searchSeniority) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getSeniorityData(
      currentPage,
      searchSeniority
    );
  };

  const getAgencyData = async (page, searchAgency,access) => {
    setIsAgencyLoading(true);
    console.log(searchAgency);
    let url;
    if (searchAgency) {
      url =
        VARIABLES.url +
        `/api/agencymaster/?entries=${agencyEntries}&page=${page}&event=${searchAgency}`;
    } else {
      url =
        VARIABLES.url +
        `/api/agencymaster/?entries=${agencyEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        // "Authorization" : `Bearer ${access}`
       }
    };

    try {
      console.log("In Try Block")
      const res = await axios(config);
      let response = res.data.data;
      setIsAgencyLoading(false);
      if (response.length !== 0) {
        setIsAgencyData(false);
        setRawAgency(res.data.data);
      } else {
        setIsAgencyData(true);
        setRawAgency(res.data.data);
      }
      const total = res.data.count;
      setAgencyCount(total);
      setAgencyPageCount(Math.ceil(total / agencyEntries));
    } catch (error) {
      console.log("In Catch Block")
      console.log(error);
      setIsAgencyLoading(false);
      setIsAgencyData(true);
      // if(error?.response?.status === 403) {
      //   RefreshToken()
      // }
    }
  };

  const handleAgencyClick = async (data, searchAgency) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAgencyData(currentPage, searchAgency);
  };

  return (
    <div>
      <MasterTableContext.Provider
        value={{
          rawDepartment,
          getDepartmentData,
          departmentEntries,
          departmentPageCount,
          setDepartmentEntries,
          departmentCount,
          handleDepartmentClick,
          isDepartmentData,
          isDepartmentLoading,

          rawTeam,
          getTeamData,
          teamEntries,
          teamPageCount,
          setTeamEntries,
          teamCount,
          handleTeamClick,
          isTeamData,
          isTeamLoading,

          rawPosition,
          getPositionData,
          positionEntries,
          positionPageCount,
          setPositionEntries,
          positionCount,
          handlePositionClick,
          isPositionData,
          isPositionLoading,

          rawSeniority,
          getSeniorityData,
          seniorityEntries,
          seniorityPageCount,
          setSeniorityEntries,
          seniorityCount,
          handleSeniorityClick,
          isSeniorityData,
          isSeniorityLoading,

          rawAgency,
          getAgencyData,
          agencyEntries,
          agencyPageCount,
          setAgencyEntries,
          agencyCount,
          handleAgencyClick,
          isAgencyData,
          isAgencyLoading,
        }}
      >
        {children}
      </MasterTableContext.Provider>
    </div>
  );
}

export default MasterTableProvider;
