import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { TagContext } from "../../Context/TagContext";
import ReactPaginate from "react-paginate";

const TagCategory = () => {
  const [searchTagCategory, setSearchTagCategory] = useState("");
  console.log(searchTagCategory);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawTagCategory,
    getTagCategory,
    tagCategoryEntries,
    tagCategoryPageCount,
    setTagCategoryEntries,
    tagCategoryCount,
    handleTagCategoryClick,
    isTagCategoryData,
    isTagCategoryLoading,
  } = useContext(TagContext);

  const handleEntries = (event) => {
    setTagCategoryEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchTagCategory(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getTagCategory(1, searchTagCategory);
    } else {
      getTagCategory(1);
      setSearchTagCategory("");
    }
  };

  useEffect(() => {
    getTagCategory(1, searchTagCategory);
  }, [tagCategoryEntries]);
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Tag Category</h3>
          {isTagCategoryLoading ? (
            <div class="spinner-border text-primary ms-2" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
          </div>
          {privileges.includes("tag_category_create") ? (
            <div className="nk-block-head-content">
              <div className="nk-block-head-content">
                <NavLink
                  to={"/createtagcategory"}
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-createtagcategory"
                >
                  <em className="icon ni ni-plus"></em>
                </NavLink>

                <NavLink
                  to={"/createtagcategory"}
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-createtagcategory"
                >
                  <em className="icon ni ni-plus"></em>
                  <span>Create TagCategory</span>
                </NavLink>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                <div className="input-group">
                  <input
                    type="text"
                    value={searchTagCategory}
                    className="form-control"
                    placeholder="Search by category display name and value..."
                    onChange={onChangeHandler}
                    id="text-form-input-search"
                  />
                  <div className="input-group-append">
                    <button
                      type="submit"
                      className="btn btn-outline-primary btn-dim"
                      id="button-button-search"
                      onClick={(e) => onSearchHandler("search", e)}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-outline-success btn-dim"
                      id="button-button-view"
                      onClick={(e) => onSearchHandler("view", e)}
                    >
                      View all
                    </button>
                  </div>
                </div>
                </form>
              </div>
            </div>

            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Tag Category Display Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Tag Category Value</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Description</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Tag Category Dynamic</span>
                      </div>

                      {privileges.includes("tag_category_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawTagCategory.length !== 0 ? (
                      rawTagCategory.map((data, index) => (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data.tag_category_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">{data.value || "-"}</span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data.description || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data.is_dynamic ? "Yes" : "No"}
                              </span>
                            </span>
                          </div>

                          {privileges.includes("tag_category_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/edittagcategory"}
                                state={{ editdata: data }}
                                className="btn btn-sm btn-primary"
                                id="button-button-edittagcategory"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isTagCategoryData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>

            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={tagCategoryPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={tagCategoryPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleTagCategoryClick(data, searchTagCategory)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {tagCategoryCount === null ? 0 : tagCategoryCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={tagCategoryEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagCategory;
