import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { VARIABLES } from "../../Constants";
import Select from "react-select";
import { MasterTableContext } from "../../Context/MasterTableContext";
import Swal from "sweetalert2";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const EditAgencyMaster = () => {
  // const access = secureLocalStorage.getItem("access");
  const { getAgencyData } = useContext(MasterTableContext);

  //DROPDOWN DATA
  const { isDropdownDataLoading, agencyTypeDropdownData } = useContext(
    TypemasterDropdownContext
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const location = useLocation();
  const privileges = secureLocalStorage.getItem("privileges");

  const navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);

  const onSubmit = (data) => {
    var user = JSON.stringify({
      agency_name: data.agency_name,
      preference: data.preference,
      agency_type: data.agency_type.value,
      updated_by_id: userID,
    });

    console.log(user);
    const url =
      VARIABLES.url + `/api/agencymaster/${location.state.editpost.id}/`;
    console.log(url);
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
        // "Authorization" : `Bearer ${access}`
      },
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitLoading(false);
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Agency successfully updated",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        // if(error.response.status === 403) {
        //   RefreshToken()
        // } else {
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update agency",
        });
        // }
      });
  };

  const deleteAgency = () => {
    const url =
      VARIABLES.url +
      `/api/agencymaster/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        // "Authorization" : `Bearer ${access}`
      },
    };
    axios(config)
      .then((response) => {
        getAgencyData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the agency",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        // if(error.response.status === 403) {
        //   RefreshToken()
        // } else {
        setActiveStatus(1);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to delete the agency",
        });
        // }
        console.log(error);
      });
  };

  const deleteAgencyAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAgency();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedAgency = location.state.editpost;
    if (editedAgency) {
      var defaultValues = {};
      defaultValues.agency_name = editedAgency.agency_name;
      defaultValues.preference = editedAgency.preference;
      const getAgencyType = {
        value: editedAgency.agency_type,
        label: editedAgency.agency_type,
      };
      defaultValues.agency_type = getAgencyType;
      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    getAgencyData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Edit Agency </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          {privileges.includes("agency_delete") ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-deleteagency"
                  checked={activeStatus}
                  onChange={(e) => {
                    deleteAgencyAlert(location.state?.editpost?.id);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="submit-button-deleteagency"
                ></label>
              </div>
            </div>
          ) : (
            " "
          )}
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Agency Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-agency_name"
                      placeholder="Enter agency name"
                      {...register("agency_name", { required: true })}
                    />
                    {errors.agency_name && (
                      <span className="text-danger">
                        Agency name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Preference <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-preference"
                      placeholder="Enter preference"
                      {...register("preference", { required: true })}
                    />
                    {errors.preference && (
                      <span className="text-danger">
                        Preference is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Agency type
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={agencyTypeDropdownData?.map((info) => ({
                            value: info.value,
                            label: info.name,
                          }))}
                          isLoading={isDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-agency_type"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="agency_type"
                      control={control}
                    />
                    {errors.agency_type && (
                      <p className="fs-8 text-danger">
                        Agency type is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editagency"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditAgencyMaster;
