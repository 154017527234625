import React, { useEffect, useState, useContext } from "react";
import DualListBox from "react-dual-listbox";
import axios from "axios";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { LoginContext } from "../../../Context/LoginContext";
import { SettingContext } from "../../../Context/SettingsContext";

const RolesCreate = () => {
  const userID = secureLocalStorage.getItem("userID");
  const { privilege, privilegeDataLoading } = useContext(LoginContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [selected, setSelected] = useState([]);
  console.log(selected);

  const onChange = (value) => {
    setSelected(value);
  };

  const { getRole } = useContext(SettingContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    var roles = JSON.stringify({
      role_name: data.role_name,
      privilege_id: selected,
      description: data.description,
      created_by_id: userID,
      updated_by_id: userID,
    });
console.log(roles)
    var config = {
      method: "post",
      url: VARIABLES.url + "/api/roles/",
      headers: {
        "Content-Type": "application/json",
      },
      data: roles,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getRole();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "New Role Successfully Created.",
        });
        reset();
        setTimeout(() => {
          navigate("/roles");
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to Create New Role.",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Create Role</h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div class="row mb-3">
                <label class="col-sm-2 form-label fs-6">
                  Role Name : <span className="text-danger">*</span>
                </label>
                <div class="col-sm-8">
                  <input
                      type="text"
                      className="form-control"
                      id="text-forms-role_name"
                      placeholder="Enter role name"
                      {...register("role_name", {
                        required: true,
                      })}
                    />
                    {errors.role_name && (
                      <span className="fs-8 text-danger">
                        Role name is required
                      </span>
                    )}
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-2 form-label fs-6">
                  Description :
                </label>
                <div class="col-sm-8">
                <textarea
                      className="form-control"
                      id="textarea-forms-description"
                      placeholder="Enter description"
                      {...register("description")}
                    />
                </div>
              </div>
              <div className="row g-4">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label fs-6">
                      Privileges
                      <span className="text-danger">*</span>
                    </label>
                    <DualListBox
                      id="dualbox-list-privilege"
                      canFilter={true}
                      options={privilege}
                      selected={selected}
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                  <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={()=> navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button id="button-loading" className="btn text-dark" disabled>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-submitrole"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RolesCreate;
