import React, { createContext, useEffect, useState } from "react";

export const SequenceContext = createContext(null);

function SequenceContextProvider({ children }) {
  const [level, setLevel] = useState(0);
  console.log(level)
  const [sequenceList, setSequenceList] = useState([]);
  console.log("sequenceList: ", sequenceList)
  const [currentSequence, setCurrentSequence] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Handle Next button
  // Goto next tab only if next tab exists
  const nextLevel = () => {
    console.log("next level:", level)
    if (sequenceList.length - 1 > level) {
      setLevel((level) => (level += 1));
    }
  };
  
  // Handle Prev button
  // Goto previous tab only if previous tab exists
  const prevLevel = () => {
    console.log("prev level:", level)
    if (level > 0) {
    setLevel((level) => (level -= 1));
    }
  };

  const value = {
    level,
    setLevel,
    sequenceList,
    setSequenceList,
    currentSequence,
    setCurrentSequence,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading
  };

  useEffect(() => {
    if (sequenceList.length !== 0) {
      setCurrentSequence(sequenceList[level]);
    }
  }, [level, sequenceList]);

  return (
    <SequenceContext.Provider value={value}>
      {children}
    </SequenceContext.Provider>
  );
}

export default SequenceContextProvider;
