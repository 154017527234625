import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const SnackbarContext = createContext(null);

function SnackbarContextProvider({ children }) {
  const [submitMessage, setSubmitMessage] = useState(null);

  const value = { submitMessage, setSubmitMessage };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);
  return (
    <div>
      <SnackbarContext.Provider value={value}>
        {children}
      </SnackbarContext.Provider>
    </div>
  );
}

export default SnackbarContextProvider;
