import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const ScheduleInterviewContext = createContext(null);

function ScheduleInterviewProvider({ children }) {
  //   const [allInterviewDetails, setAllInterviewDetails] = useState([]);
  const [interviewDetails, setInterviewDetails] = useState([]);
  // const [candidateJobID, setCandidateJobID] = useState([]);
  // console.log("candidateJobID:", candidateJobID);

  const [isLoading, setIsLoading] = useState(true);

  const getInterviewDetails = async (candidateJobID) => {
    const url =
      VARIABLES.url +
      `/api/candidateinterview/?candidate_job=${candidateJobID}`;
    console.log(url);
    try {
      setIsLoading(true);
      const res = await axios.get(url);
      setInterviewDetails(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  //   const getAllInterviewDetails = async () => {
  //     const url =
  //       VARIABLES.url +
  //       `/api/candidateinterview/?candidate_job=${candidateJobID}`;
  //     console.log(url);
  //     try {
  //       setIsLoading(true);
  //       const res = await axios.get(url);
  //       setAllInterviewDetails(res.data.data);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       setIsLoading(false);
  //     }
  //   };

  //   useEffect(() =>{
  //     getAllInterviewDetails()
  //   },[])

  return (
    <div>
      <ScheduleInterviewContext.Provider
        value={{
          interviewDetails,
          isLoading,
          // setCandidateJobID,
          // candidateJobID,
          getInterviewDetails,
        }}
      >
        {children}
      </ScheduleInterviewContext.Provider>
    </div>
  );
}

export default ScheduleInterviewProvider;
