import React, { useContext, useEffect } from "react";
import { CandidateDetailsContext } from "../../../Context/CandidateDetailsContext";
import secureLocalStorage from "react-secure-storage";

const ProfessionalDetails = () => {
  const { professionalData, isProfessionalDataLoading, getProfessionalData } =
    useContext(CandidateDetailsContext);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");

  useEffect(() => {
    getProfessionalData(candidate_job_id);
  }, [candidate_job_id]);

  console.log(professionalData);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Candidate's Professional Details</h4>
            {isProfessionalDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isProfessionalDataLoading ? (
        <div></div>
      ) : (
        <div>
          {professionalData == 0 ? (
            <div>No professional details found</div>
          ) : (
            <div className="row">
              {professionalData?.map((prof, index) => (
                <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
                  <div className="card">
                    <div className="card-aside-wrap">
                      <div className="card-content">
                        <div className="card-inner">
                          <div className="nk-block">
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Previously Worked
                                  </span>
                                  <span className="profile-ud-value">
                                    {prof?.previously_worked || "-"}
                                  </span>
                                </div>
                              </div>
                              {prof?.previously_worked !== "No" && (
                                <>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Profile Name
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.profile_name || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Organization Name
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.organization_name || "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Start Date
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.start_date || "-"}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        End Date
                                      </span>
                                      <span className="profile-ud-value">
                                        {prof?.end_date || "-"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {prof?.previously_worked !== "No" && (
                            <>
                              <div className="nk-block">
                                <div className="nk-block-head nk-block-head-sm nk-block-between">
                                  <h5 className="title">
                                    Job Responsibilities
                                  </h5>
                                </div>
                                <div className="bq-note">
                                  <div className="bq-note-item">
                                    <div className="bq-note-text">
                                      <p>
                                        {prof?.job_responsibilities || "-"}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="nk-block">
                            <div className="nk-block-head nk-block-head-sm nk-block-between">
                              <h5 className="title">Comments</h5>
                            </div>
                            <div className="bq-note">
                              <div className="bq-note-item">
                                <div className="bq-note-text">
                                  <p>{prof?.comments || "-"} </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="nk-divider divider md"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProfessionalDetails;
