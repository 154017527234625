import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const ScheduleInterviewCreate = () => {
  //DROPDOWN DATA
  const {
    isDropdownDataLoading,
    interviewTypeDropdownData,
    interviewModeDropdownData,
  } = useContext(TypemasterDropdownContext);

  console.log("interviewTypeDropdownData: ", interviewTypeDropdownData);
  console.log("interviewModeDropdownData: ", interviewModeDropdownData);

  const { isGenricParamsDropdownDataLoading, usersDropdownData } = useContext(
    GenericDropdownContext
  );
  console.log("usersDropdownData: ", usersDropdownData);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state?.CandidateData);

  const candidate_job_id = location.state?.CandidateData?.id;
  console.log(candidate_job_id);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      candidate_job_id: candidate_job_id,
      interview_mode: data.interview_mode.value,
      interviewer_name_id: data.interview_name.map((val) => val.value),
      interview_type: data.interview_type.value,
      interview_date: data.interview_date,
      interview_time: data.interview_time,
      created_by_id: userID,
      updated_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidateinterview/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully scheduled interview",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to schedule interview",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Schedule candidate Interview
            </h3>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <label className="form-label fs-4">
                    Candidate name : &nbsp;
                    {location.state?.CandidateData?.candidate_name || "-"}
                  </label>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interview Type
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewTypeDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isLoading={isDropdownDataLoading}
                        isClearable={true}
                        id="select-forms-interviewtype"
                        // isMulti
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_type"
                    control={control}
                  />
                  {errors.interview_type && (
                    <span className="fs-8 text-danger">
                      Interview Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interview Mode
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewModeDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isClearable={true}
                        isLoading={isDropdownDataLoading}
                        id="select-forms-interviewmode"
                        // isMulti
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_mode"
                    control={control}
                  />
                  {errors.interview_mode && (
                    <span className="fs-8 text-danger">
                      Interview Mode is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interviewer Name
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={usersDropdownData?.map((info) => ({
                          value: info.user.id,
                          label: `${info.user.first_name} ${info.user.last_name}`,
                        }))}
                        isClearable={true}
                        isLoading={isGenricParamsDropdownDataLoading}
                        isMulti
                        id="select-forms-interviewname"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_name"
                    control={control}
                  />
                  {errors.interview_name && (
                    <span className="fs-8 text-danger">
                      Interview Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Interview Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="text-forms-interview_date"
                      {...register("interview_date", { required: true })}
                    />
                    {errors.interview_date && (
                      <span className="fs-8 text-danger">
                        Interview date is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Interview Time <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="text-forms-interview_time"
                      {...register("interview_time", { required: true })}
                    />
                    {errors.interview_time && (
                      <span className="fs-8 text-danger">
                        Interview time is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-secondary me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createscheduleinterview"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScheduleInterviewCreate;
