import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { SendOfferContext } from "../../../Context/SendOfferContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";

const SendOffer = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const {
    sendOffer,
    candidateStatus,
    getCandidateJobOfferData,
    getSendOfferData,
  } = useContext(SendOfferContext);
  console.log(sendOffer); //stores data of candidates previous offer data
  console.log(candidateStatus);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedOffer, setSelectedOffer] = useState([]);
  console.log(selectedOffer);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const currentDate = new Date().toISOString().split("T")[0];
  console.log(currentDate);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  //checks that is there an offer already given to this candidate or not
  //if yes : then Patch will work
  //if no : then Post will work
  const handleOfferCheck = () => {
    const existingAssignment = sendOffer.find(
      (OfferItem) => OfferItem.candidate_job.id === candidate_job_id
    );
    setSelectedOffer(existingAssignment);
    console.log("Yes");
  };

  //Form submission
  const onSubmit = (data) => {
    console.log(data);
    let workflow_session = {
      candidate_job_id: candidate_job_id,
      tab_id: formId.id,
      workflow_id: formId.WF,
      user: userID,
      created_by_id: userID,
      updated_by_id: userID,
    };
    if (selectedOffer) {
      let user = new FormData();
      user.append("candidate_job_id", candidate_job_id);
      user.append("requisition_id", requisition_id);
      user.append("offer_attachment", data.offer_attachment);
      user.append("user_id", data.contact);
      user.append("date", currentDate);
      user.append("updated_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      const url = VARIABLES.url + `/api/sendoffer/${sendOffer.id}/`;
      console.log(url);
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: url,
        data: user,
      };

      for (var pair of user.entries()) {
        console.log(pair);
      }
      console.log(JSON.stringify(data));
      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          nextLevel();
          getWorkflowSession(1);
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Offer sent successfully",
          });
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: error?.response?.data?.message || "Failed to send offer",
          });
        });
    } else {
      let user = new FormData();
      user.append("candidate_job_id", candidate_job_id);
      user.append("requisition_id", requisition_id);
      user.append("offer_attachment", data.offer_attachment);
      user.append("user_id", data.contact);
      user.append("date", currentDate);
      user.append("created_by_id", userID);
      user.append("updated_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      const url = VARIABLES.url + "/api/sendoffer/";
      console.log(url);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        data: user,
      };

      console.log(data);
      console.log(JSON.stringify(data));
      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          nextLevel();
          getWorkflowSession(1);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Offer sent successfully",
          });
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: error?.response?.data?.message || "Failed to send offer",
          });
        });
    }
  };

  useEffect(() => {
    handleOfferCheck();
    getCandidateJobOfferData();
    getSendOfferData();
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create offer </h4>
          </div>
          {/* {formId.is_iterable && formId.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )} */}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="form-label">STATUS : </label>
                    <div
                      className="form-control fs-6 fw-bold"
                      style={{
                        backgroundColor:
                          candidateStatus?.md_approval === "Reject"
                            ? "red"
                            : candidateStatus?.md_approval === "Accept"
                            ? "green"
                            : "",
                      }}
                    >
                      {candidateStatus?.md_approval || "-"}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12"></div>
                <div className="nk-block">
                  <div className="nk-block-head nk-block-head-sm nk-block-between">
                    <h5 className="title">Comments</h5>
                  </div>
                  {/* <!-- .nk-block-head --> */}
                  <div className="bq-note">
                    <div className="bq-note-item">
                      <div className="bq-note-text">
                        <p>{candidateStatus?.md_comments || "-"}</p>
                      </div>
                    </div>
                    {/* <!-- .bq-note-item --> */}
                  </div>
                  {/* <!-- .bq-note --> */}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Upload Offer Letter <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name={`offer_attachment`}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => value.type === "application/pdf",
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-offerattachment"
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                      />
                    )}
                  />
                  {errors.offer_attachment &&
                    errors.offer_attachment.type === "required" && (
                      <span className="fs-8 text-danger">
                        Offer Letter is required
                      </span>
                    )}
                  {errors.offer_attachment &&
                    errors.offer_attachment.type === "validate" && (
                      <span className="fs-8 text-danger">
                        Please upload your document in pdf format
                      </span>
                    )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Contact user <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="number-forms-contact"
                    placeholder="Enter Contact number"
                    {...register("contact", {
                      required: true,
                      maxLength: 10,
                      minLength: 10,
                    })}
                  />

                  {errors.contact && errors.contact.type === "required" && (
                    <span className="fs-8 text-danger">
                      Contact number is required
                    </span>
                  )}
                  {errors.contact && errors.contact.type === "minLength" && (
                    <span className="fs-8 text-danger">
                      Contact number should be at least 10 digits
                    </span>
                  )}
                  {errors.contact && errors.contact.type === "maxLength" && (
                    <span className="fs-8 text-danger">
                      Contact number should not exceed 10 digits
                    </span>
                  )}
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Date
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="date-forms-date"
                    placeholder="Enter date"
                    {...register("date", { required: true })}
                  />
                  {errors.date && (
                    <span className="text-danger">Date is required</span>
                  )}
                </div> */}

                <div className="col-12">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-sendoffer"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-sendoffer"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default SendOffer;
