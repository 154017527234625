import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import Swal from "sweetalert2";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const EditRequested = () => {
  const userID = secureLocalStorage.getItem("userID");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const privileges = secureLocalStorage.getItem("privileges");
  const [filteredTeamData, setFilteredTeamData] = useState([]);
  const [filteredPositionData, setFilteredPositionData] = useState([]);
  console.log(filteredTeamData);
  console.log(filteredPositionData);

  const [departmentEntry, setDepartmentEntry] = useState(null);
  console.log(departmentEntry);
  const [teamEntry, setTeamEntry] = useState(null);
  console.log(teamEntry);
  const [positionEntry, setPositionEntry] = useState(null);

  const [activeStatus, setActiveStatus] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    resetField,
  } = useForm();
  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();

  const { getRequisitionByUserData } = useContext(RequestedJobOpeningContext);

  //DROPDOWN DATA
  const { isDropdownDataLoading, jobTypeDropdownData } = useContext(
    TypemasterDropdownContext
  );
  const {
    isGenricDropdownDataLoading,
    isGenricParamsDropdownDataLoading,
    departmentDropdownData,
    teamDropdownData,
    positionDropdownData,
    jobOpeningDropdownData,
    seniorityDropdownData,
    usersDropdownData,
  } = useContext(GenericDropdownContext);

  const jobID = location.state.editpost?.id;
  console.log(location.state.editpost);

  const minSalary = watch("min_salary");

  const validateMaxSalary = (value) => {
    return (
      parseFloat(value) > minSalary ||
      "Max Salary must be greater than Min Salary"
    );
  };

  const [jobOpeningID, setJobOpeningID] = useState(null);
  console.log(jobOpeningID);

  const handleJobOpeningID = (data) => {
    console.log(data);
    console.log(positionEntry);
    console.log("jobOpeningDropdownData: ", jobOpeningDropdownData);
    const getJobOpeningID = jobOpeningDropdownData.find(
      (info) =>
        info.seniority_pk === data?.value &&
        info.position_pk === positionEntry.value
    );
    setJobOpeningID(getJobOpeningID);
    console.log(getJobOpeningID);
    if (getJobOpeningID === undefined) {
      Swal.fire({
        title: `Please contact admin`,
        text: `Job opening for ${data?.label || "-"} ${
          positionEntry.label || "-"
        } is not created yet! `,
        confirmButtonText: "OK",
      });
    }
  };
  console.log(jobOpeningID);

  const onSubmit = (data) => {
    console.log(data);
    var job_posting = JSON.stringify({
      job_opening_id: jobOpeningID?.id,
      job_type: data.job_type.value,
      job_location: data.job_location,
      required_experience: data.required_experience,
      no_of_vacancy: data.no_of_vacancy,
      min_salary: data.min_salary,
      max_salary: data.max_salary,
      deadline: data.deadline ? data.deadline : null,
      status: 0,
      // priority: data.priority.value,
      depute_status: null,
      comments: data.comments,
      updated_by_id: userID,
      requested_user_id: data.requestedusername
        ? data.requestedusername.value
        : userID,
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/requestrequisition/${jobID}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: job_posting,
    };

    console.log(job_posting);
    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully edited request requisition",
        });
        getRequisitionByUserData();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to edit request requisition",
        });
      });
    reset();
  };

  const deletePosition = () => {
    const url =
      VARIABLES.url +
      `/api/requestrequisition/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {},
    };
    axios(config)
      .then((response) => {
        getRequisitionByUserData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the requisition",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setActiveStatus(1);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to delete the requisition",
        });
        console.log(error);
      });
  };

  const deletePositionAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePosition();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedJobPost = location.state.editpost;
    if (editedJobPost) {
      var defaultValues = {};
      defaultValues.job_location = editedJobPost.job_location;
      defaultValues.required_experience = editedJobPost.required_experience;
      defaultValues.no_of_vacancy = editedJobPost.no_of_vacancy;
      defaultValues.min_salary = editedJobPost.min_salary;
      defaultValues.max_salary = editedJobPost.max_salary;
      defaultValues.comments = editedJobPost.comments;
      if (editedJobPost.deadline) {
        const deadlineDate = new Date(editedJobPost.deadline);
        const localDeadline = new Date(
          deadlineDate.getTime() - deadlineDate.getTimezoneOffset() * 60000
        );
        const formattedDeadline = localDeadline.toISOString().slice(0, 16);
        defaultValues.deadline = formattedDeadline;
      }

      if (editedJobPost.job_opening?.department) {
        const getDepartment = {
          value: editedJobPost.job_opening?.department?.id,
          label: editedJobPost.job_opening?.department?.department_display_name,
        };
        defaultValues.department = getDepartment;
        setDepartmentEntry(getDepartment);
      }

      if (editedJobPost.job_opening?.team) {
        const getTeam = {
          value: editedJobPost.job_opening?.team?.id,
          label: editedJobPost.job_opening?.team?.team_display_name,
        };
        defaultValues.team = getTeam;
        setTeamEntry(getTeam);
      }

      if (editedJobPost.job_opening?.position) {
        const getPosition = {
          value: editedJobPost.job_opening?.position?.id,
          label: editedJobPost.job_opening?.position?.position_display_name,
        };
        defaultValues.position = getPosition;
        setPositionEntry(getPosition);
      }

      if (editedJobPost.job_opening?.seniority) {
        const getSeniority = {
          value: editedJobPost.job_opening?.seniority?.id,
          label: editedJobPost.job_opening?.seniority?.seniority_display_name,
        };
        defaultValues.seniority = getSeniority;
      }

      if (editedJobPost?.requested_user) {
        const getRequestedUser = {
          value: editedJobPost?.requested_user?.id,
          label:
            editedJobPost?.requested_user?.first_name +
            " " +
            editedJobPost?.requested_user?.last_name,
        };
        defaultValues.requestedusername = getRequestedUser;
      }

      const getJobType = {
        value: editedJobPost.job_type,
        label: editedJobPost.job_type,
      };
      defaultValues.job_type = getJobType;

      // const getPriority = {
      //   value: editedJobPost.priority,
      //   label: editedJobPost.priority,
      // };
      // defaultValues.priority = getPriority;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    console.log(departmentEntry?.label);
    const rawfilteredTeamData = teamDropdownData.filter(
      (info) => info?.department_display_name == departmentEntry?.label
    );
    setFilteredTeamData(rawfilteredTeamData);
    resetField("team");
    resetField("position");
    resetField("seniority");
  }, [departmentEntry]);

  useEffect(() => {
    const rawfilteredPositionData = positionDropdownData.filter(
      (info) => info?.team_display_name == teamEntry?.label
    );
    setFilteredPositionData(rawfilteredPositionData);
    resetField("position");
    resetField("seniority");
  }, [teamEntry]);

  useEffect(() => {
    resetField("seniority");
  }, [positionEntry]);

  useEffect(() => {
    // getRequisitionByUserData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">
              Edit {location.state.editpost?.job_opening?.job_title}
            </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner d-flex justify-content-end pt-3 pb-0">
            {privileges.includes("requisition_delete") ? (
              <div className="d-flex align-center justify-center">
                <em className="icon ni ni-trash me-2 fs-4 text-danger"></em>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input bg-danger"
                    id="submit-button-deleterequisition"
                    checked={activeStatus}
                    onChange={(e) => {
                      deletePositionAlert(location.state?.editpost?.id);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    id="button-button-status"
                    htmlFor="submit-button-deleterequisition"
                  ></label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                {privileges.includes("write_requisition") ? (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label">Requested User Name</label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={usersDropdownData?.map((info) => ({
                              value: info?.user.id,
                              label: `${info?.user.first_name} ${info?.user.last_name}`,
                            }))}
                            isClearable={true}
                            isLoading={isGenricParamsDropdownDataLoading}
                            id="select-forms-requestedusername"
                          />
                        )}
                        defaultValue=""
                        name="requestedusername"
                        control={control}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Department <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={departmentDropdownData?.map((info) => ({
                            value: info?.id,
                            label: info?.department_display_name,
                          }))}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          onChange={(e) => {
                            setDepartmentEntry(e);
                            field.onChange(e);
                          }}
                          id="select-forms-department"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="department"
                      control={control}
                    />
                    {errors.department && (
                      <span className="fs-8 text-danger">
                        Department name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Team <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredTeamData?.map((info) => ({
                            value: info.team_display_name,
                            label: info.team_display_name,
                          }))}
                          isClearable={true}
                          onChange={(e) => {
                            setTeamEntry(e);
                            field.onChange(e);
                          }}
                          id="select-forms-team"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="team"
                      control={control}
                    />
                    {errors.team && (
                      <span className="fs-8 text-danger">
                        Team name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Position <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredPositionData?.map((info) => ({
                            value: info.id,
                            label: info.position_display_name,
                          }))}
                          isClearable={true}
                          onChange={(e) => {
                            setPositionEntry(e);
                            field.onChange(e);
                          }}
                          id="select-forms-position"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="position"
                      control={control}
                    />
                    {errors.position && (
                      <span className="fs-8 text-danger">
                        Position is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Seniority <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={seniorityDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.seniority_display_name,
                          }))}
                          onChange={(e) => {
                            handleJobOpeningID(e);
                            field.onChange(e);
                          }}
                          isClearable={true}
                          isLoading={isGenricDropdownDataLoading}
                          id="select-forms-seniority"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="seniority"
                      control={control}
                    />
                    {errors.seniority && (
                      <span className="fs-8 text-danger">
                        Seniority is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Type <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={jobTypeDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-job_type"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="job_type"
                      control={control}
                    />
                    {errors.job_type && (
                      <span className="fs-8 text-danger">
                        Job type is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Location <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-job_location"
                      placeholder="Enter Job Location"
                      {...register("job_location", {
                        required: true,
                        pattern: /^[A-Za-z\s]+$/i,
                      })}
                    />
                    {errors.job_location && (
                      <span className="fs-8 text-danger">
                        Job Location is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Required Experience (in Years)
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number-forms-required_experience"
                      placeholder="Enter Required Experience"
                      onWheel={(e) => e.target.blur()}
                      {...register("required_experience", {
                        required: true,
                        pattern: { value: /^\d+$/ },
                      })}
                    />
                    {errors.required_experience &&
                      errors.required_experience.type === "required" && (
                        <span className="fs-8 text-danger">
                          Please enter your experience
                        </span>
                      )}
                    {errors.required_experience &&
                      errors.required_experience.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          Experience cannot be negative or in decimal
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      No of Vacancy <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number-forms-no_of_vacancy"
                      placeholder="Enter no of vacancy"
                      onWheel={(e) => e.target.blur()}
                      {...register("no_of_vacancy", {
                        required: true,
                        pattern: { value: /^\d+$/ },
                      })}
                    />
                    {errors.no_of_vacancy &&
                      errors.no_of_vacancy.type === "required" && (
                        <span className="fs-8 text-danger">
                          No of Vacancy is required
                        </span>
                      )}
                    {errors.no_of_vacancy &&
                      errors.no_of_vacancy.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          No of Vacancy cannot be negative or in decimal
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Minimum Salary <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number-forms-min_salary"
                      placeholder="Enter minimum salary"
                      onWheel={(e) => e.target.blur()}
                      {...register("min_salary", {
                        required: true,
                        pattern: { value: /^\d+$/ },
                      })}
                    />
                    {errors.min_salary &&
                      errors.min_salary.type === "required" && (
                        <span className="fs-8 text-danger">
                          Min Salary is required
                        </span>
                      )}
                    {errors.min_salary &&
                      errors.min_salary.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          Min Salary cannot be negative or in decimal
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Maximum Salary <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number-forms-max_salary"
                      placeholder="Enter maximum salary"
                      onWheel={(e) => e.target.blur()}
                      {...register("max_salary", {
                        required: true,
                        validate: validateMaxSalary,
                      })}
                    />
                    {errors.max_salary &&
                      errors.max_salary.type === "required" && (
                        <span className="fs-8 text-danger">
                          Max Salary is required
                        </span>
                      )}
                    <br />
                    {errors.max_salary &&
                      errors.max_salary.type === "validate" && (
                        <span className="fs-8 text-danger">
                          {errors.max_salary.message}
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Deadline</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="date-forms-deadline"
                      placeholder="Enter Deadline"
                      {...register("deadline")}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Priority <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={priorityDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-priority"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="priority"
                      control={control}
                    />
                    {errors.priority && (
                      <span className="fs-8 text-danger">
                        Priority is required
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Comments</label>
                    <textarea
                      type="datetime-local"
                      className="form-control"
                      id="textarea-forms-comments"
                      placeholder="Enter comments"
                      {...register("comments")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createjobopening"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditRequested;
