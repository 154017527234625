import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const ResumeDownloadContext = createContext(null);

function ResumeDownloadProvider({ children }) {
  const [allResume, setAllResume] = useState([]);
  const [isResumeData, setIsResumeData] = useState(false);
  const [resumeEntries, setResumeEntries] = useState(5)
  const [resumeCount, setResumeCount] = useState(null)
  const [resumePageCount, setResumePageCount] = useState(0)
  const [isResumeLoading, setIsResumeLoading] = useState(false);

  const getResumes = async () => {
    setIsResumeLoading(true);
    try {
      const res = await axios.get(VARIABLES.url + "/api/resumezip/");
      let response = res.data.data;
      setIsResumeLoading(false);
      if (response.length !== 0) {
        setIsResumeData(false);
        setAllResume(res.data.data);
      } else {
        setIsResumeData(true);
        setAllResume(res.data.data);
      }
      const total = res.data.count;
      setResumeCount(total);
      setResumePageCount(Math.ceil(total / resumeEntries));
    } catch (error) {
      console.log(error);
      setIsResumeLoading(false);
      setIsResumeData(true);
    }
  };

  const handleResumeClick = async (data) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getResumes(
      currentPage
    );
  };

  return (
    <div>
      <ResumeDownloadContext.Provider
        value={{
          allResume,
          getResumes,
          resumeEntries,
          resumePageCount,
          setResumeEntries,
          resumeCount,
          handleResumeClick,
          isResumeData,
          isResumeLoading,
        }}
      >
        {children}
      </ResumeDownloadContext.Provider>
    </div>
  );
}

export default ResumeDownloadProvider;
