import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";
import secureLocalStorage from "react-secure-storage";

export const CandidateDetailsContext = createContext(null);

function CandidateDetailsProvider({ children }) {
  const [candidateJob, setCandidateJob] = useState([]);
  console.log(candidateJob);

  const [shortlistedCandidate, setShortlistedCandidate] = useState([]);
  console.log(shortlistedCandidate);

  const [isLoading, setIsLoading] = useState(true);

  const [CandidateEntryCount, setCandidateEntryCount] = useState(null);
  const [shortlistedEntryCount, setShortlistedEntryCount] = useState(null);

  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [shortlistedPageCount, setShortlistedPageCount] = useState(0);
  console.log(candidatePageCount);
  console.log(shortlistedPageCount);

  const [candidateEntries, setCandidateEntries] = useState(5);

  const [shortlistedEntries, setShortlistedEntries] = useState(5);

  const job_opening_id = secureLocalStorage.getItem("jobOpeningid");

  const [isData, setIsData] = useState(false);
  const [shortlistedIsData, setShortlistedIsData] = useState(false);

  //Candidate Data
  const [isCandidatePersonalDataLoading, setIsCandidatePersonalDataLoading] =
    useState(true);
  const [candidatePersonalDetails, setCandidatePersonalDetails] = useState([]);
  const [isEducationDataLoading, setIsEducationDataLoading] = useState(true);
  const [educationDetails, setEducationDetails] = useState([]);
  const [isProfessionalDataLoading, setIsProfessionalDataLoading] =
    useState(true);
  const [professionalData, setProfessionalData] = useState([]);

  //done
  const getCandidateJobData = async (page, job_id, searchRequest) => {
    setIsLoading(true);
    let url;
    console.log(searchRequest);
    if (searchRequest !== undefined) {
      url =
        VARIABLES.url +
        `/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False&job_opening=${job_id}&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidatejob/?entries=${candidateEntries}&page=${page}&is_draft=False&job_opening=${job_id}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsLoading(false);
      if (response.length !== 0) {
        setIsData(false);
        setCandidateJob(res.data.data);
      } else {
        setIsData(true);
        setCandidateJob(res.data.data);
      }
      const total = res.data.count;
      setCandidateEntryCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsData(true);
    }
  };

  const handleCandidatePageClick = async (data, searchRequest) => {
    console.log(data.selected);

    let page = data.selected + 1;

    const commentsFormServer = await getCandidateJobData(page, searchRequest);
  };

  //done
  const getShortlistedCandidateData = async (
    page,
    jobOpeningID,
    searchRequest
  ) => {
    setIsLoading(true);
    let url;
    console.log(searchRequest);
    if (searchRequest !== undefined) {
      url =
        VARIABLES.url +
        `/api/candidatejob/?entries=${shortlistedEntries}&page=${page}&is_draft=False&job_opening=${jobOpeningID}&is_shortlisted=True&event=${searchRequest}`;
    } else {
      url =
        VARIABLES.url +
        `/api/candidatejob/?entries=${shortlistedEntries}&page=${page}&is_draft=False&job_opening=${jobOpeningID}&is_shortlisted=True`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsLoading(false);
      if (response.length !== 0) {
        setShortlistedCandidate(res.data.data);
        setShortlistedIsData(false);
      } else {
        setShortlistedCandidate(res.data.data);
        setShortlistedIsData(true);
      }
      const total = res.data.count;
      setShortlistedEntryCount(total);
      setShortlistedPageCount(Math.ceil(total / shortlistedEntries));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShortlistedIsData(true);
    }
  };

  const handleShortlistedPageClick = async (data, searchRequest) => {
    console.log(data.selected);

    let page = data.selected + 1;

    const commentsFormServer = await getShortlistedCandidateData(
      page,
      searchRequest
    );
  };

  //Educational Details of candidate
  const getCandidatePersonalData = async (candidate_job_id) => {
    try {
      setIsCandidatePersonalDataLoading(true);
      const res = await axios.get(
        VARIABLES.url + `/api/userdetails/?candidate_job=${candidate_job_id}`
      );
      setCandidatePersonalDetails(res.data.data);
      setIsCandidatePersonalDataLoading(false);
    } catch (error) {
      console.log(error);
      setIsCandidatePersonalDataLoading(false);
    }
  };

  //Educational Details of candidate
  const getEducationData = async (candidate_job_id) => {
    try {
      setIsEducationDataLoading(true);
      const res = await axios.get(
        VARIABLES.url +
          `/api/educationdetails/?candidate_job=${candidate_job_id}`
      );
      setEducationDetails(res.data.data);
      setIsEducationDataLoading(false);
    } catch (error) {
      console.log(error);
      setIsEducationDataLoading(false);
    }
  };

  //Professional Details of candidate
  const getProfessionalData = async (candidate_job_id) => {
    try {
      setIsProfessionalDataLoading(true);
      const res = await axios.get(
        VARIABLES.url +
          `/api/professionaldetails/?candidate_job=${candidate_job_id}`
      );
      setProfessionalData(res.data.data);
      setIsProfessionalDataLoading(false);
    } catch (error) {
      console.log(error);
      setIsProfessionalDataLoading(false);
    }
  };

  return (
    <div>
      <CandidateDetailsContext.Provider
        value={{
          candidateJob,
          isLoading,
          educationDetails,
          professionalData,
          shortlistedCandidate,
          getCandidateJobData,
          setShortlistedEntries,
          setCandidateEntries,
          getShortlistedCandidateData,
          handleShortlistedPageClick,
          handleCandidatePageClick,
          shortlistedEntryCount,
          CandidateEntryCount,
          candidateEntries,
          shortlistedEntries,
          candidatePageCount,
          shortlistedPageCount,
          isData,
          setIsData,
          shortlistedIsData,
          isEducationDataLoading,
          getEducationData,
          isProfessionalDataLoading,
          getProfessionalData,
          job_opening_id,

          candidatePersonalDetails,
          isCandidatePersonalDataLoading,
          getCandidatePersonalData,
        }}
      >
        {children}
      </CandidateDetailsContext.Provider>
    </div>
  );
}

export default CandidateDetailsProvider;
