import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import axios from "axios";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { BackgroundVerificationContext } from "../../../Context/BackgroundVerificationContext";
import { useNavigate } from "react-router-dom";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";

const BackgroundVerificationForm = ({ formId, props }) => {
  console.log(props);
  console.log(formId); //data of the tab in which this form is present

  const { getBGVerification } = useContext(BackgroundVerificationContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { level, sequenceList, setSequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const navigate = useNavigate();

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const [submitLoading, setSubmitLoading] = useState(false); //loading state while submitting data

  //Submission of form
  const onSubmit = (data) => {
    let workflow_session = {
      candidate_job_id: candidate_job_id,
      tab_id: formId.id,
      workflow_id: formId.WF,
      user: userID,
      created_by_id: userID,
      updated_by_id: userID,
    };
    let user = new FormData();
    user.append("candidate_job_id", candidate_job_id);
    user.append("requisition_id", requisition_id);
    user.append("verification_agency", data.verification_agency);
    user.append("date_of_verification", data.date_of_verification);
    user.append("comments", data.comments);
    user.append("bgv_type", props.bgv_type);
    user.append("result", data.document);
    user.append("created_by_id", userID);
    user.append("updated_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));

    console.log(user);
    const url = VARIABLES.url + `/api/backgroundverification/`;
    console.log(url);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        getBGVerification();
        // workflowSession();
        nextLevel();
        getWorkflowSession(1);
        console.log(JSON.stringify(response.data));
        setSubmitLoading(false);
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Verification successful",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Verification failed",
        });
      });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Background Verification </h4>
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Verification Agency<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-verification_agency"
                      placeholder="Enter verification agency name"
                      {...register("verification_agency", { required: true })}
                    />
                    {errors.verification_agency && (
                      <span className="text-danger">
                        Verification Agency is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Date of Verification{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="date-forms-date_of_verification"
                      placeholder="Enter Verification date"
                      {...register("date_of_verification", { required: true })}
                    />
                    {errors.date_of_verification && (
                      <span className="text-danger">
                        Verification date is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Upload Document <span className="fs-8 text-danger">*</span>
                  </label>
                  <Controller
                    name="document"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-document"
                        type="file"
                        onChange={(e) => {
                          field.onChange(e.target.files[0]);
                        }}
                      />
                    )}
                  />
                  {errors.document && errors.document.type === "required" && (
                    <span className="fs-8 text-danger">
                      Document is required
                    </span>
                  )}
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Comments</label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-comments"
                      placeholder="Enter commnets"
                      {...register("comments")}
                    />
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-backgroundverification"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-backgroundverification"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default BackgroundVerificationForm;
