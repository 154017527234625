import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const SettingContext = createContext(null);

function SettingContextProvider({ children }) {
  // To store Role Data.
  const [isRoleData, setIsRoleData] = useState(false);
  const [rawRole, setRawRole] = useState([]);
  const [roleEntries, setRoleEntries] = useState(5)
  const [roleCount, setRoleCount] = useState(null)
  const [rolePageCount, setRolePageCount] = useState(0)
  const [isRoleLoading, setIsRoleLoading] = useState(false);

  // To store Type Master Data.
  const [isTypeMasterData, setIsTypeMasterData] = useState(false);
  const [rawTypeMaster, setRawTypeMaster] = useState([]);
  const [typeMasterEntries, setTypeMasterEntries] = useState(5)
  const [typeMasterCount, setTypeMasterCount] = useState(null)
  const [typeMasterPageCount, setTypeMasterPageCount] = useState(0)
  const [isTypeMasterLoading, setIsTypeMasterLoading] = useState(false);

  // To store Privilege Data.
  const [isPrivilegeData, setIsPrivilegeData] = useState(false);
  const [rawPrivilege, setRawPrivilege] = useState([]);
  const [privilegeEntries, setPrivilegeEntries] = useState(5)
  const [privilegeCount, setPrivilegeCount] = useState(null)
  const [privilegePageCount, setPrivilegePageCount] = useState(0)
  const [isPrivilegeLoading, setIsPrivilegeLoading] = useState(false);

  // "GetRole" API call to get role data
  const getRole = async (page, searchRole) => {
    setIsRoleLoading(true);
    console.log(searchRole)
    let url;
    if (searchRole !== undefined) {
      url = VARIABLES.url + `/api/roles/?entries=${roleEntries}&page=${page}&event=${searchRole}`;
    } else {
      url = VARIABLES.url + `/api/roles/?entries=${roleEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsRoleLoading(false);
      if (response.length !== 0) {
        setIsRoleData(false);
        setRawRole(res.data.data);
      } else {
        setIsRoleData(true);
        setRawRole(res.data.data);
      }
      const total = res.data.count;
      setRoleCount(total);
      setRolePageCount(Math.ceil(total / roleEntries));
    } catch (error) {
      console.log(error);
      setIsRoleLoading(false);
      setIsRoleData(true);
    }
  };

  const handleRoleClick = async (data,searchRole) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getRole(currentPage,searchRole);
  };

  // "GetTypeMaster" API call to get role data
   const getTypeMaster = async (page, searchMaster) => {
    setIsTypeMasterLoading(true);
    console.log(searchMaster)
    let url;
    if (searchMaster !== undefined) {
      url = VARIABLES.url + `/api/typemaster/?entries=${typeMasterEntries}&page=${page}&event=${searchMaster}`;
    } else {
      url = VARIABLES.url + `/api/typemaster/?entries=${typeMasterEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsTypeMasterLoading(false);
      if (response.length !== 0) {
        setIsTypeMasterData(false);
        setRawTypeMaster(res.data.data);
      } else {
        setIsTypeMasterData(true);
        setRawTypeMaster(res.data.data);
      }
      const total = res.data.count;
      setTypeMasterCount(total);
      setTypeMasterPageCount(Math.ceil(total / typeMasterEntries));
    } catch (error) {
      console.log(error);
      setIsTypeMasterLoading(false);
      setIsTypeMasterData(true);
    }
  };

  const handleTypeMasterClick = async (data,searchMaster) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getTypeMaster(currentPage,searchMaster);
  };

  // "GetPrivilege" API call to get privilege data
  const getPrivilege = async (page, searchPrivilege) => {
    setIsPrivilegeLoading(true);
    console.log(searchPrivilege)
    let url;
    if (searchPrivilege !== undefined) {
      url = VARIABLES.url + `/api/privileges/?entries=${privilegeEntries}&page=${page}&event=${searchPrivilege}`;
    } else {
      url = VARIABLES.url + `/api/privileges/?entries=${privilegeEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsPrivilegeLoading(false);
      if (response.length !== 0) {
        setIsPrivilegeData(false);
        setRawPrivilege(res.data.data);
      } else {
        setIsPrivilegeData(true);
        setRawPrivilege(res.data.data);
      }
      const total = res.data.count;
      setPrivilegeCount(total);
      setPrivilegePageCount(Math.ceil(total / privilegeEntries));
    } catch (error) {
      console.log(error);
      setIsPrivilegeLoading(false);
      setIsPrivilegeData(true);
    }
  };

  const handlePrivilegeClick = async (data,searchPrivilege) => {
    console.log(data)
    let currentPage = data.selected + 1;
    const commentsFormServer = await getPrivilege(currentPage,searchPrivilege);
  };


  const value = {
    rawRole,
    getRole,
    roleEntries,
    rolePageCount,
    setRoleEntries,
    roleCount,
    handleRoleClick,
    isRoleData,
    isRoleLoading,

    rawTypeMaster,
    getTypeMaster,
    typeMasterEntries,
    typeMasterPageCount,
    setTypeMasterEntries,
    typeMasterCount,
    handleTypeMasterClick,
    isTypeMasterData,
    isTypeMasterLoading,

    rawPrivilege,
    getPrivilege,
    privilegeEntries,
    privilegePageCount,
    setPrivilegeEntries,
    privilegeCount,
    handlePrivilegeClick,
    isPrivilegeData,
    isPrivilegeLoading,
  };
  return (
    <div>
      <SettingContext.Provider value={value}>
        {children}
      </SettingContext.Provider>
    </div>
  );
}

export default SettingContextProvider;
