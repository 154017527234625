import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Sidebar = () => {
  const privileges = secureLocalStorage.getItem("privileges");

  const [sidebarActive, setSidebarActive] = useState(false);
  const handleActive = (status) => {
    setSidebarActive(status);
  };

  const [sidebarForm, setSidebarForm] = useState(false);
  const handleForm = (status) => {
    setSidebarForm(status);
  };

  const [sidebarAssign, setSidebarAssign] = useState(false);
  const handleAssign = (status) => {
    setSidebarAssign(status);
  };

  const [sidebarWorkflow, setSidebarWorkflow] = useState(false);
  const handleWorkflow = (status) => {
    setSidebarWorkflow(status);
  };

  return (
    <>
      <div
        className="nk-sidebar is-light nk-sidebar-fixed is-light"
        data-content="sidebarMenu"
      >
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <a
              href="http://www.fluidscapes.in/"
              className="logo-link nk-sidebar-logo"
              id="logo-fluidscapes"
            >
              <img
                className="logo-light logo-img"
                src="./assets/images/logo.jpg"
                srcset="./assets/images/logo.jpg 2x"
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src="./assets/images/logo.jpg"
                srcset="./assets/images/logo.jpg 2x"
                alt="logo-dark"
              />
              <img
                className="logo-small logo-img logo-img-small"
                src="./assets/images/logo.jpg"
                srcset="./assets/images/logo.jpg 2x"
                alt="logo-small"
              />
            </a>
          </div>
          <div className="nk-menu-trigger me-n2">
            <a
              href="javascript:void(0)"
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                {[
                  "requisition_retrieve",
                  "requisition_create",
                  "requisition_update",
                  "requisition_delete",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/"
                      className="nk-menu-link"
                      id="sidebar-requested-requisition"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-grid-add-c"></em>
                      </span>
                      <span className="nk-menu-text">Requisition Manager</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}

                {[
                  "requisition_approval_retrieve",
                  "requisition_approval_update",
                  "requisition_approval_filter",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/requestacceptance"
                      className="nk-menu-link"
                      id="sidebar-requestacceptance"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-list-check"></em>
                      </span>
                      <span className="nk-menu-text">Requisition Approval</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {privileges.includes("candidate_entries_retrieve") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/candidateentries"
                      className="nk-menu-link"
                      id="sidebar-candidateentries"
                    >
                      <span className="nk-menu-text">
                        Candidate Details
                      </span>
                    </NavLink>
                  </li>
                    ) : (
                    " "
                  )}

                {[
                  "requisition_tam_retrieve",
                  "requisition_user_assign_update",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/tam"
                      className="nk-menu-link"
                      id="sidebar-tam"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-grid-alt"></em>
                      </span>
                      <span className="nk-menu-text">TAM</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {[
                  "requisition_re1_retrieve",
                  "requisition_re1_resume_upload",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/re1"
                      className="nk-menu-link"
                      id="sidebar-re1"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-user-add"></em>
                      </span>
                      <span className="nk-menu-text">RE-1</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {[
                  "requisition_re2_retrieve",
                  "requisition_re2_resume_upload",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/re2"
                      className="nk-menu-link"
                      id="sidebar-re2"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-user-add"></em>
                      </span>
                      <span className="nk-menu-text">RE-2</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {[
                  "requisition_re3_retrieve",
                  "requisition_re3_resume_upload",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/re3"
                      className="nk-menu-link"
                      id="sidebar-re3"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-user-add"></em>
                      </span>
                      <span className="nk-menu-text">RE-3</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {[
                  "rc_shortlisting_retrieve",
                  "rc_candidate_resume_update",
                  "view_all_resume",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/recruitmentcordinator"
                      className="nk-menu-link"
                      id="sidebar-recruitment_cordinator"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-user-list"></em>
                      </span>
                      <span className="nk-menu-text">
                        RC (Recruitment Cordinator)
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {[
                  "sme_shortlisting_retrieve",
                  "sme_candidate_resume_update",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/interviewersme"
                      className="nk-menu-link"
                      id="sidebar-interviewersme"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-file-docs"></em>
                      </span>
                      <span className="nk-menu-text">Interviewer/SME</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {[
                  "schedule_candidate_interview",
                  "accepted_candidate_details",
                  "view_calendar",
                  "schedule_free_slots",
                ].some((privilege) => privileges.includes(privilege)) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/schedulecandidateinterview"
                      className="nk-menu-link"
                      id="sidebar-schedulecandidateinterview"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-clock"></em>
                      </span>
                      <span className="nk-menu-text">
                        Schedule Candidate Interview
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {["seo_retrieve", "seo_update"].some((privilege) =>
                  privileges.includes(privilege)
                ) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/seo"
                      className="nk-menu-link"
                      id="sidebar-seo"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-trend-up"></em>
                      </span>
                      <span className="nk-menu-text">SEO</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {["resume_pool_advance_filter"].some((privilege) =>
                  privileges.includes(privilege)
                ) ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/resumepooladvancefilter"
                      className="nk-menu-link"
                      id="sidebar-resume_pool_advance_filter"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-filter"></em>
                      </span>
                      <span className="nk-menu-text">
                        Resume Pool Advance Filter
                      </span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {privileges.includes("hrd_eagle_eye_view") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/hrdapproval"
                      className="nk-menu-link"
                      id="sidebar-hrd_eagle_view"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-activity"></em>
                      </span>
                      <span className="nk-menu-text">HRD Approval</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {privileges.includes("activity_logs") ? (
                  <li className="nk-menu-item">
                    <NavLink
                      to="/activitylogs"
                      className="nk-menu-link"
                      id="sidebar-activity_logs"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-user"></em>
                      </span>
                      <span className="nk-menu-text">Activity Logs</span>
                    </NavLink>
                  </li>
                ) : (
                  " "
                )}

                {/* Masters Tab Starts */}
                {privileges.includes("masters") ? (
                  <li
                    className={`${
                      sidebarAssign
                        ? "nk-menu-item has-sub active"
                        : "nk-menu-item has-sub"
                    }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleAssign(!sidebarAssign)}
                      id="sidebar-master"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-shield-star"></em>
                      </span>
                      <span className="nk-menu-text">Masters</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {[
                        "usermanagement_retrieve",
                        "usermanagement_create",
                        "usermanagement_update",
                        "usermanagement_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/usermanagement"
                            className="nk-menu-link"
                            id="sidebar-usermanagement"
                          >
                            <span className="nk-menu-text">
                              User Management
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "workflowmanagement_retrieve",
                        "workflowmanagement_create",
                        "workflowmanagement_update",
                        "workflow_tabs_configurable_retrieve",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li
                          className={`${
                            sidebarWorkflow
                              ? "nk-menu-item has-sub active"
                              : "nk-menu-item has-sub"
                          }`}
                        >
                          <a
                            href="javascript:void(0)"
                            className="nk-menu-link nk-menu-toggle"
                            d="sidebar-jobworkflowdemo"
                            onClick={() => handleWorkflow(!sidebarWorkflow)}
                          >
                            <span className="nk-menu-text">
                              Workflow Management
                            </span>
                          </a>
                          <ul className="nk-menu-sub">
                            <li className="nk-menu-item">
                              <NavLink
                                to="/jobworkflowdemo"
                                state={{ categoryName: "Candidate" }}
                                className="nk-menu-link"
                                id="sidebar-jobworkflowdemo-candidate"
                              >
                                <span className="nk-menu-text">Candidate</span>
                              </NavLink>
                            </li>
                            <li className="nk-menu-item">
                              <NavLink
                                to="/jobworkflowdemo"
                                state={{ categoryName: "network" }}
                                className="nk-menu-link"
                                id="sidebar-jobworkflowdemo-network"
                              >
                                <span className="nk-menu-text">Network</span>
                              </NavLink>
                            </li>
                            <li className="nk-menu-item">
                              <NavLink
                                to="/jobworkflowdemo"
                                state={{ categoryName: "Developer" }}
                                className="nk-menu-link"
                                id="sidebar-jobworkflowdemo-developer"
                              >
                                <span className="nk-menu-text">Developer</span>
                              </NavLink>
                            </li>
                          </ul>
                          {/* <!-- .nk-menu-sub --> */}
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "interview_questionnair_retrieve",
                        "interview_questionnair_create",
                        "interview_questionnair_update",
                        "interview_questionnair_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/interviewquestionnaire"
                            className="nk-menu-link"
                            id="sidebar-interviewquestionnaire"
                          >
                            <span className="nk-menu-text">
                              Interview Questionnaire
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "job_assignment_retrieve",
                        "job_assignment_create",
                        "job_assignment_update",
                        "job_assignment_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/jobassignment"
                            className="nk-menu-link"
                            id="sidebar-jobassignment"
                          >
                            <span className="nk-menu-text">Job Assignment</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "job_opening_update",
                        "job_opening_retrieve",
                        "job_assignment_update",
                      ].every((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/jobdescription"
                            className="nk-menu-link"
                            id="sidebar-jobdescription"
                          >
                            <span className="nk-menu-text">
                              Job Description
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "seniority_retrieve",
                        "seniority_create",
                        "seniority_update",
                        "seniority_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/seniority"
                            className="nk-menu-link"
                            id="sidebar-seniority"
                          >
                            <span className="nk-menu-text">Seniority</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "department_retrieve",
                        "department_create",
                        "department_update",
                        "department_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/department"
                            className="nk-menu-link"
                            id="sidebar-department"
                          >
                            <span className="nk-menu-text">Department</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "team_retrieve",
                        "team_create",
                        "team_update",
                        "team_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/team"
                            className="nk-menu-link"
                            id="sidebar-team"
                          >
                            <span className="nk-menu-text">Team</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "position_retrieve",
                        "position_create",
                        "position_update",
                        "position_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/position"
                            className="nk-menu-link"
                            id="sidebar-position"
                          >
                            <span className="nk-menu-text">Position</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "agency_retrieve",
                        "agency_create",
                        "agency_update",
                        "agency_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/agencymaster"
                            className="nk-menu-link"
                            id="sidebar-agencymaster"
                          >
                            <span className="nk-menu-text">Agency Master</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "candidate_resume_retrieve",
                        "candidate_resume_update",
                        "candidate_resume_create",
                        "candidate_resume_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/candidateresume"
                            className="nk-menu-link"
                            id="sidebar-resumepool"
                          >
                            <span className="nk-menu-text">Resume Pool</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {/* {[
                        "tag_retrieve",
                        "tag_create",
                        "tag_update",
                        "tag_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/tag"
                            className="nk-menu-link"
                            id="sidebar-tag"
                          >
                            <span className="nk-menu-text">Tags</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )} */}

                      {/* {[
                        "tag_category_retrieve",
                        "tag_category_create",
                        "tag_category_update",
                        "tag_category_delete",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/tagcategory"
                            className="nk-menu-link"
                            id="sidebar-tagcategory"
                          >
                            <span className="nk-menu-text">Tag Category</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )} */}
                    </ul>
                  </li>
                ) : (
                  " "
                )}

                {/* Form Workflow Tab with Assignment,Test,MD-Approval,Send Offer,Background Verification and Technical as sub tabs */}
                {privileges.includes("form_workflow_tab") ? (
                  <li
                    className={`${
                      sidebarForm
                        ? "nk-menu-item has-sub active"
                        : "nk-menu-item has-sub"
                    }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      id="stepper-forms"
                      onClick={() => handleForm(!sidebarForm)}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-files"></em>
                      </span>
                      <span className="nk-menu-text">Forms</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {privileges.includes("Assign Assignment") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/assignassignment"
                            className="nk-menu-link"
                            id="sidebar-assignassignment"
                          >
                            <span className="nk-menu-text">
                              Assign Assignment
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Assignment Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/assignmentreview"
                            className="nk-menu-link"
                            id="sidebar-assignmentreview"
                          >
                            <span className="nk-menu-text">
                              Assignment Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Test") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/mettletest"
                            className="nk-menu-link"
                            id="sidebar-mettletest"
                          >
                            <span className="nk-menu-text">
                              Psychometric Test
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Test Review") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/mettletestreview"
                            className="nk-menu-link"
                            id="sidebar-mettletestreview"
                          >
                            <span className="nk-menu-text">
                              Psychometric Test Review
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("HRD Approval") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/hrdapprovalform"
                            className="nk-menu-link"
                            id="sidebar-hrdapprovalform"
                          >
                            <span className="nk-menu-text">HRD Approval</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("MD Approval") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/mdapproval"
                            className="nk-menu-link"
                            id="sidebar-mdapproval"
                          >
                            <span className="nk-menu-text">MD Approval</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Send Offer") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/sendoffer"
                            className="nk-menu-link"
                            id="sidebar-sendoffer"
                          >
                            <span className="nk-menu-text">Send Offer</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Technical Interview") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/technicalinterviewround"
                            className="nk-menu-link"
                            id="sidebar-technicalinterviewround"
                          >
                            <span className="nk-menu-text">
                              Technical Interview Round
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Technical Interview Review") ? (
                      <li className="nk-menu-item">
                        <NavLink
                          to="/technicalinterviewreview"
                          className="nk-menu-link"
                          id="sidebar-technicalinterviewreview"
                        >
                          <span className="nk-menu-text">
                            Technical Interview Review
                          </span>
                        </NavLink>
                      </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("HR Interview") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/hrinterviewround"
                            className="nk-menu-link"
                            id="sidebar-hrinterviewround"
                          >
                            <span className="nk-menu-text">
                              HR Interview Round
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Telephonic Interview") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/telephonicinterviewround"
                            className="nk-menu-link"
                            id="sidebar-telephonicinterviewround"
                          >
                            <span className="nk-menu-text">
                              Telephonic Interview Round
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                    
                      {privileges.includes("Telephonic Interview Review") ? (
                      <li className="nk-menu-item">
                        <NavLink
                          to="/telephonicinterviewreview"
                          className="nk-menu-link"
                          id="sidebar-telephonicinterviewreview"
                        >
                          <span className="nk-menu-text">
                            Telephonic Interview Review
                          </span>
                        </NavLink>
                      </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Smart Hire Form") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/smarthireform"
                            className="nk-menu-link"
                            id="sidebar-smarthireform"
                          >
                            <span className="nk-menu-text">
                              Smart Hire Form
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      
                      {privileges.includes("Smart Hire Form Review") ? (
                      <li className="nk-menu-item">
                        <NavLink
                          to="/smarthireformreview"
                          className="nk-menu-link"
                          id="sidebar-smarthireformreview"
                        >
                          <span className="nk-menu-text">
                            Smart Hire Form Review
                          </span>
                        </NavLink>
                      </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("Background Verification") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/backgroundverification"
                            className="nk-menu-link"
                            id="sidebar-background_verfification"
                          >
                            <span className="nk-menu-text">
                              Background Verification
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("KYC") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/kyc"
                            className="nk-menu-link"
                            id="sidebar-kyc"
                          >
                            <span className="nk-menu-text">KYC</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes(
                        "workflow_form_candidate_details"
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/formcandidatedetails"
                            className="nk-menu-link"
                            id="sidebar-candidatedetails"
                          >
                            <span className="nk-menu-text">
                              Candidate Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      
                      {privileges.includes("personal_details_retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/personaldetails"
                            className="nk-menu-link"
                            id="sidebar-personaldetails"
                          >
                            <span className="nk-menu-text">
                              Personal Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("educational_details_retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/educationdetails"
                            className="nk-menu-link"
                            id="sidebar-educationdetails"
                          >
                            <span className="nk-menu-text">
                              Educational Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {privileges.includes("professional_details_retrieve") ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/professionaldetails"
                            className="nk-menu-link"
                            id="sidebar-professionaldetails"
                          >
                            <span className="nk-menu-text">
                              Professional Details
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                  </li>
                ) : (
                  " "
                )}

                {/* Setting Tab with role, privilege and typemaster as sub tabs */}
                {privileges.includes("setting_tab") ? (
                  <li
                    className={`${
                      sidebarActive
                        ? "nk-menu-item has-sub active"
                        : "nk-menu-item has-sub"
                    }`}
                  >
                    <a
                      href="javascript:void(0)"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={() => handleActive(!sidebarActive)}
                      id="sidebar-setting"
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-setting"></em>
                      </span>
                      <span className="nk-menu-text">Settings</span>
                    </a>
                    <ul className="nk-menu-sub">
                      {[
                        "role_create",
                        "role_update",
                        "role_delete",
                        "role_retrieve",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/roles"
                            className="nk-menu-link"
                            id="sidebar-role"
                          >
                            <span className="nk-menu-text">Role</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {["privilege_retrieve", "privilege_update"].some(
                        (privilege) => privileges.includes(privilege)
                      ) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/privilege"
                            className="nk-menu-link"
                            id="sidebar-privilege"
                          >
                            <span className="nk-menu-text">Privilege</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}

                      {[
                        "type_master_delete",
                        "type_master_create",
                        "type_master_update",
                        "type_master_retrieve",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <li className="nk-menu-item">
                          <NavLink
                            to="/typemaster"
                            className="nk-menu-link"
                            id="sidebar-typemaster"
                          >
                            <span className="nk-menu-text">Type Master</span>
                          </NavLink>
                        </li>
                      ) : (
                        " "
                      )}
                    </ul>
                    {/* <!-- .nk-menu-sub --> */}
                  </li>
                ) : (
                  " "
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
