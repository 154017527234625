import React, { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { TAMRE1RE2RE3Context } from "../../Context/TAMRE1RE2RE3Context";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const RE2 = () => {
  const [searchRE2, setSearchRE2] = useState("");
  console.log(searchRE2);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawRE2Data,
    getRE2Data,
    re2Entries,
    re2PageCount,
    setRE2Entries,
    re2Count,
    handleRE2Click,
    isRE2Data,
    isRE2Loading,
  } = useContext(TAMRE1RE2RE3Context);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const calculatePendingSince = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - createdDate;
    const InMilliseconds = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / InMilliseconds
    );
    console.log(differenceInDays);
    return differenceInDays;
  };

  const handleEntries = (event) => {
    setRE2Entries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRE2(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRE2Data(1, searchRE2);
    } else {
      getRE2Data(1);
      setSearchRE2("");
    }
  };

  useEffect(() => {
    getRE2Data(1);
  }, [re2Entries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Recruitment Executive - 2
            </h3>
            {isRE2Loading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRE2}
                      className="form-control"
                      placeholder="Search by requisition id, job title, department, priority..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Priority (Set by TAM)</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Pending Since (Days) for outreach</span>
                      </div>

                      {privileges.includes("requisition_re2_resume_upload") ? (
                        <div className="nk-tb-col">
                          <span>
                            Action - (Outreach pending to job portals)
                          </span>
                        </div>
                      ) : (
                        " "
                      )}

                      {privileges.includes("error_resume_download") ? (
                        <div className="nk-tb-col">
                          <span>Error Resume Zip Download</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {["job_opening_retrieve"].some((privilege) =>
                        privileges.includes(privilege)
                      ) ? (
                        <div className="nk-tb-col">
                          <span>Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawRE2Data.length !== 0 ? (
                      rawRE2Data.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name +
                                    post?.requisition?.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post.job_opening?.department
                                ?.department_display_name || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition?.priority || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {calculatePendingSince(post.created_at)} days
                            </span>
                          </div>

                          {privileges.includes(
                            "requisition_re2_resume_upload"
                          ) ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/re2create"}
                                className="btn btn-sm btn-primary"
                                id="button-button-re2create"
                                state={{ editpost: post }}
                              >
                                <em className="icon ni ni-plus"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {privileges.includes("error_resume_download") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/resumedownload"}
                                className="btn btn-sm btn-primary"
                                id="button-button-re2zipdownload"
                              >
                                <em className="icon ni ni-download"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {["job_opening_retrieve"].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="me-n1">
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0)"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                      id="list-dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        {privileges.includes(
                                          "job_opening_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/rerequisitiondetails"}
                                              state={{ editpost: post }}
                                              id="button-button-jobopeningdetails"
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>
                                                View Requisition Details
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isRE2Data ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={re2PageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={re2PageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) => handleRE2Click(data, searchRE2)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {re2Count === null ? 0 : re2Count}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={re2Entries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RE2;
