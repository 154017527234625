import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { InterviewQuestionContext } from "../../Context/InterviewQuestionMasterContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const InterviewQuestionnaireCreate = () => {
  const { getInterviewQuestionnair } = useContext(InterviewQuestionContext);

  //DROPDOWN DATA
  const {
    isDropdownDataLoading,
    interviewTypeDropdownData,
    interviewCategoryDropdownData,
  } = useContext(TypemasterDropdownContext);

  const { isGenricDropdownDataLoading, jobOpeningDropdownData } = useContext(
    GenericDropdownContext
  );

  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      job_opening_id: data.job_title.map((val) => val.value),
      interview_type: data.interview_type.map((val) => val.value),
      category: data.category.map((val) => val.value),
      question: data.question,
      answer: data.answer,
      created_by_id: userID,
      updated_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/interviewquestionnaire/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getInterviewQuestionnair();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "successfully created interview questionnaire",
        });
        reset();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to create interview questionnaire",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Create Interview Questionnaire
            </h3>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Job Title
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={jobOpeningDropdownData?.map((info) => ({
                          value: info.id,
                          label:
                            info.job_title +
                            " " +
                            info.department_name +
                            " " +
                            info.team_name,
                        }))}
                        isLoading={isGenricDropdownDataLoading}
                        isClearable={true}
                        isMulti
                        id="select-forms-jobtitle"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="job_title"
                    control={control}
                  />
                  {errors.job_title && (
                    <span className="fs-8 text-danger">
                      Job title is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interview Type
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewTypeDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isClearable={true}
                        isLoading={isDropdownDataLoading}
                        isMulti
                        id="select-forms-interviewtype"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_type"
                    control={control}
                  />
                  {errors.interview_type && (
                    <span className="fs-8 text-danger">
                      Interview Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Category
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewCategoryDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isLoading={isDropdownDataLoading}
                        isClearable={true}
                        isMulti
                        id="select-forms-catogory"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="fs-8 text-danger">
                      Category is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Question <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-question"
                      placeholder="Enter question"
                      {...register("question", {
                        required: true,
                      })}
                    />
                    {errors.question && (
                      <span className="fs-8 text-danger">
                        Question is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Answer <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-answer"
                      placeholder="Enter answer"
                      {...register("answer", {
                        required: true,
                      })}
                    />
                    {errors.answer && (
                      <span className="fs-8 text-danger">
                        Answer is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createinterviewquestion"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InterviewQuestionnaireCreate;
