import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { StepperCandidate } from "../../Context/StepperCandidate";
import { NavLink } from "react-router-dom";

const StepperCandidateDetails = () => {
  const {
    CandidateDetails,
    getCandidateDetailsData,
    candidateEntries,
    setCandidateEntries,
    isLoading,
    handleCandidatePageClick,
    CandidateEntryCount,
    candidatePageCount,
  } = useContext(StepperCandidate);

  const privileges = secureLocalStorage.getItem("privileges");

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const [filterAppliedCandidate, setFilterAppliedCandidate] = useState([]);

  const handleEntries = (event) => {
    setCandidateEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterAppliedCandidate(CandidateDetails);
      return;
    }
    let filterAppliedCandidateData = CandidateDetails.filter(
      (item) =>
        item?.user?.first_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item?.user?.last_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item?.current_previous_company
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setFilterAppliedCandidate(filterAppliedCandidateData);
  };

  const handleViewStepper = (id) => {
    secureLocalStorage.setItem("RWFcandidate_job_id", id);
    console.log(id);
  };

  useEffect(() => {
    setFilterAppliedCandidate(CandidateDetails);
  }, [CandidateDetails]);

  useEffect(() => {
    if (CandidateDetails.length === 0) {
      getCandidateDetailsData(1);
    }
  }, [candidateEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Candidate Details</h3>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="filter">
                <input
                  type="text"
                  placeholder="Search by candidate name, company name..."
                  className="form-control"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    {isLoading ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col tb-col-sm">
                            <span>Candidate name</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Job Title</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Assign Assignment</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Review Assignment</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>MD Approval</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Mettle Test</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Mettle Test Review</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Send Offer</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Technical Round</span>
                          </div>
                        </div>

                        {filterAppliedCandidate.length === 0 && (
                          <div className="nk-tb-item">
                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">No Data Found</span>
                              </span>
                            </div>
                          </div>
                        )}

                        {filterAppliedCandidate.length > 0 &&
                          filterAppliedCandidate.map((post, index) => (
                            <div className="nk-tb-item" key={index}>
                              <div className="nk-tb-col tb-col-sm">
                                <span className="tb-product">
                                  <span className="title">
                                    {post.user?.first_name}
                                    {post.user?.last_name}
                                  </span>
                                </span>
                              </div>
                              <div className="nk-tb-col">
                                <span className="tb-sub">
                                  {post?.job_opening?.job_title || "-"}
                                </span>
                              </div>
                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/assignassignment"}
                                  // state={{ editpost: post }}
                                  onClick={() => handleViewStepper(post.id)}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-assign_assignment"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>

                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/assignmentreview"}
                                  // state={{ editpost: post }}
                                  onClick={() => handleViewStepper(post.id)}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-assignment-review"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>

                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/mdapproval"}
                                  // state={{ editpost: post }}
                                  onClick={() => handleViewStepper(post.id)}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-md_approval"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>

                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/mettletest"}
                                  // state={{ editpost: post }}
                                  onClick={() => handleViewStepper(post.id)}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-mettle_test"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>

                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/mettletestreview"}
                                  // state={{ editpost: post }}
                                  onClick={() => handleViewStepper(post.id)}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-mettle_test_review"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>

                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/sendoffer"}
                                  // state={{ editpost: post }}
                                  onClick={() => handleViewStepper(post.id)}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-send_offer"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>

                              <div className="nk-tb-col">
                                <NavLink
                                  to={"/technicalinterviewround"}
                                  // state={{ editpost: post }}
                                  onClick={() => handleViewStepper(post.id)}
                                  className="btn btn-sm btn-primary"
                                  id="button-button-send_offer"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </NavLink>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidatePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidatePageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handleCandidatePageClick}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                  <div>
                      Total entries:{" "}
                      <span className="fw-bold">
                        {CandidateEntryCount === null
                          ? 0
                          : CandidateEntryCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>{" "}
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateEntries}
                        onChange={handleEntries}
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default StepperCandidateDetails;
