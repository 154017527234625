import React from "react";
import JobWorkflowCreate from "./JobWorkflowCreate";
import JobWorkflowTable from "./JobWorkflowTable";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const JobWorkflowDemo = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const location = useLocation();
  console.log(location.state.categoryName);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Workflow - ({location.state.categoryName})</h3>
          </div>
        </div>
      </div>
      <div className="row g-gs">
        {privileges.includes("workflowmanagement_create") ? (
          <JobWorkflowCreate
            categoryName={location.state.categoryName}
          />
        ) : (
          " "
        )}
        <JobWorkflowTable
          categoryName={location.state.categoryName}
        />
      </div>
    </>
  );
};

export default JobWorkflowDemo;
