import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";

const CandidateResume = () => {
  const [searchResume, setSearchResume] = useState("");
  console.log(searchResume);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawCandidateResume,
    getCandidateResume,
    candidateResumeEntries,
    candidateResumePageCount,
    setCandidateResumeEntries,
    candidateResumeCount,
    handleCandidateResumeClick,
    isResumeData,
    isResumeLoading,
  } = useContext(CandidateResumeContext);

  const handleEntries = (event) => {
    setCandidateResumeEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchResume(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getCandidateResume(1, searchResume);
    } else {
      getCandidateResume(1);
      setSearchResume("");
    }
  };

  useEffect(() => {
    getCandidateResume(1, searchResume);
  }, [candidateResumeEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Resume pool</h3>
            {isResumeLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("candidate_resume_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/createcandidateresume"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-candidateresume"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/createcandidateresume"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-candidateresume"
              >
                <em className="icon ni ni-plus"></em>
                <span>Add Resume</span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>

      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchResume}
                      className="form-control"
                      placeholder="Search by candidate name,email and tag..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Email-ID</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile no.</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Tags</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Resume</span>
                      </div>

                      {privileges.includes("candidate_resume_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {rawCandidateResume.length !== 0 ? (
                      rawCandidateResume.map((data, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.candidate_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.email || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data?.mobile || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">{data?.tags || "-"}</span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {data?.resume ? (
                                <a
                                  className="btn btn-primary"
                                  id="button-button-viewresume"
                                  href={data?.resume}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <em
                                    className="icon ni ni-file-docs"
                                    style={{ color: "white" }}
                                  ></em>
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>

                          {privileges.includes("candidate_resume_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/editcandidateresume"}
                                state={{
                                  editdata: data,
                                }}
                                className="btn btn-md btn-primary"
                                id="button-button-editcandidateresume"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isResumeData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidateResumePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidateResumePageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleCandidateResumeClick(data, searchResume)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {candidateResumeCount === null
                          ? 0
                          : candidateResumeCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateResumeEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateResume;
