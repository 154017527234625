import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const RequestedJobOpening = () => {
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    requisitionByUserData,
    getRequisitionByUserData,
    requisitionByUserEntries,
    requisitionByUserPageCount,
    setRequisitionByUserEntries,
    requisitionByUserCount,
    handleRequisitionByUserPageClick,
    isRequisitionByUserData,
    isRequisitionByUserLoading,
  } = useContext(RequestedJobOpeningContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const handleEntries = (event) => {
    setRequisitionByUserEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRequisitionByUserData(1, searchRequest);
    } else {
      getRequisitionByUserData(1);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    getRequisitionByUserData(1);
  }, [requisitionByUserEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Requisition Manager</h3>
            {isRequisitionByUserLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>

          {privileges.includes("requisition_create") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/addrequestrequisition"}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-addrequestrequisition"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/addrequestrequisition"}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-addrequestrequisition"
              >
                <em className="icon ni ni-plus"></em>
                <span>Add Request Requisition</span>
              </NavLink>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search by requisition id, job title..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Vacancy</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Active/ Inactive</span>
                      </div>

                      {privileges.includes("requisition_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit Request</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {[
                        "job_opening_retrieve",
                        "candidate_details_retrieve",
                        "shortlist_details_retrieve",
                      ].some((privilege) => privileges.includes(privilege)) ? (
                        <div className="nk-tb-col">
                          <span>Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {requisitionByUserData.length !== 0 ? (
                      requisitionByUserData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name + post?.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post?.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.job_opening?.department
                                ?.department_display_name || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.job_type || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.no_of_vacancy || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.status === 1 ? (
                                <span class="badge bg-success">Active</span>
                              ) : (
                                <span class="badge bg-danger">Inactive</span> ||
                                "-"
                              )}
                            </span>
                          </div>

                          {privileges.includes("requisition_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/editrequest"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-editrequisition"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {[
                            "job_opening_retrieve",
                            // "candidate_details_retrieve",
                            // "shortlist_details_retrieve",
                          ].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1 my-n1">
                                <li className="me-n1">
                                  <div className="dropdown">
                                    <a
                                      href="javascript:void(0)"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-bs-toggle="dropdown"
                                      id="list-dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <ul className="link-list-opt no-bdr">
                                        {privileges.includes(
                                          "job_opening_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/jobopeningdetails"}
                                              state={{ editpost: post }}
                                              id="button-button-jobopeningdetails"
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>
                                                View Requisition Details
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )}

                                        {/* {privileges.includes(
                                          "candidate_details_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/candidateapplied"}
                                              id="button-button-candidateapplied"
                                              state={{
                                                editpost: post?.job_opening,
                                              }}
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>
                                                View Candidate details
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )} */}

                                        {/* {privileges.includes(
                                          "shortlist_details_retrieve"
                                        ) ? (
                                          <li>
                                            <NavLink
                                              to={"/viewshortlisted"}
                                              id="button-button-viewshortlisted"
                                              onClick={() =>
                                                getShortlistedCandidateData(
                                                  1,
                                                  post.job_opening.id
                                                )
                                              }
                                              state={{
                                                editpost: post.job_opening,
                                              }}
                                            >
                                              <em className="icon ni ni-eye"></em>
                                              <span>View Shortlisted</span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          " "
                                        )} */}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isRequisitionByUserData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={requisitionByUserPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={requisitionByUserPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleRequisitionByUserPageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {requisitionByUserCount === null
                          ? 0
                          : requisitionByUserCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={requisitionByUserEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestedJobOpening;
