// export const Formdata = {
//   "tabs": [
//     {
//       "id": 1,
//       "name": "Tab 1",
//       "parent_id": 0,
//       "sequence": 1,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 2,
//           "name": "Tab 1.1",
//           "parent_id": 1,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 1,
//             "form_path" : "Stepper/AssignAssignment/AssignAssignment",
//             "form_name" : "Assign Assignment"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 3,
//           "name": "Tab 1.2",
//           "parent_id": 1,
//           "sequence": 2,
//           "depends_on": [2],
//           "form": {
//             "id" : 2,
//             "form_path" : "Stepper/AssignmentReview/AssignmentReview",
//             "form_name" : "Assignment Review"
//           },
//           "WF": 1,
//         "tabs": [],
//         },
//       ]
//     },
//     {
//       "id": 4,
//       "name": "Tab 2",
//       "parent_id": 0,
//       "sequence": 2,
//       "depends_on": [2,3],
//       "form": {
//         "id" : 3,
//         "form_path" : "Stepper/MettleTest/MettleTest",
//         "form_name" : "Test"
//       },
//       "WF": 1,
//         "tabs": [],
//     },
//     {
//       "id": 5,
//       "name": "Tab 3",
//       "parent_id": 0,
//       "sequence": 3,
//       "depends_on": [0],
//       "form": null,
//       "WF": 1,
//       "tabs": [
//         {
//           "id": 6,
//           "name": "Tab 3.1",
//           "parent_id": 5,
//           "sequence": 1,
//           "depends_on": [0],
//           "form": {
//             "id" : 4,
//             "form_path" : "Stepper/MettleTestReview/MettleTestReview",
//             "form_name" : "Test Review"
//           },
//           "WF": 1,
//           "tabs": [],
//         },
//         {
//           "id": 7,
//           "name": "Tab 3.2",
//           "parent_id": 5,
//           "sequence": 2,
//           "depends_on": [2,6],
//           "form": null,
//           "WF": 1,
//           "tabs": [
//             {
//               "id": 8,
//               "name": "Tab 3.2.1",
//               "parent_id": 7,
//               "sequence": 1,
//               "depends_on": [7],
//               "form": {
//                 "id" : 1,
//                 "form_path" : "Stepper/SendOffer/SendOffer",
//                 "form_name" : "Send Offer"
//               },
//               "WF": 1,
//                "tabs": [],
//             }
//           ]
//         }
//       ]
//     }
//   ],
//   "sequence": [[0, 0, 0], [0, 1], [1, 0], [2, 0], [2, 1, 0], [3]]
// };

export const candidateFormWorkflowss = {
  candidate_job_id: 197,
  tabs: [
    {
      id: 136,
      name: "Assignment",
      parent_id: 0,
      sequence: 1,
      depends_on: [0],
      form: null,
      WF: 15,
      completion: null,
      tabs: [
        {
          id: 140,
          name: "Assign Assignment",
          parent_id: 136,
          sequence: 2,
          depends_on: [0],
          form: {
            id: 2,
            form_path: "Stepper/AssignAssignment/AssignAssignment",
            form_name: "Assign Assignment",
          },
          completion_id: null,
          level: 1,
          WF: 15,
          tabs: [],
        },
        {
          id: 139,
          name: "Assignment Review",
          parent_id: 136,
          sequence: 3,
          depends_on: [140],
          form: {
            id: 1,
            form_path: "Stepper/AssignmentReview/AssignmentReview",
            form_name: "Assignment Review",
          },
          completion_id: null,
          level: 1,
          WF: 15,
          tabs: [],
        },
      ],
      level: 0,
    },
    {
      id: 141,
      name: "Test",
      parent_id: 0,
      sequence: 4,
      depends_on: [0],
      form: null,
      WF: 15,
      completion_id: null,
      tabs: [
        {
          id: 143,
          name: "Mettle Test",
          parent_id: 141,
          sequence: 5,
          depends_on: [140, 139],
          form: {
            id: 4,
            form_path: "Stepper/MettleTest/MettleTest",
            form_name: "Test",
          },
          completion_id: null,
          level: 1,
          WF: 15,
          tabs: [],
        },
        {
          id: 142,
          name: "Mettle Test Review",
          parent_id: 141,
          sequence: 6,
          depends_on: [143],
          form: {
            id: 5,
            form_path: "Stepper/MettleTestReview/MettleTestReview",
            form_name: "Test Review",
          },
          completion_id: null,
          level: 1,
          WF: 15,
          tabs: [],
        },
      ],
      level: 0,
    },
    {
      id: 144,
      name: "MD",
      parent_id: 0,
      sequence: 7,
      depends_on: [142],
      form: {
        id: 3,
        form_name: "MD Approval",
        form_path: "Stepper/MDApproval/MDApproval",
      },
      WF: 15,
      completion_id: null,
      tabs: [],
      level: 0,
    },
    {
      id: 145,
      name: "Offer",
      parent_id: 0,
      sequence: 8,
      depends_on: [144],
      form: {
        id: 6,
        form_name: "Send Offer",
        form_path: "Stepper/SendOffer/SendOffer",
      },
      WF: 15,
      completion_id: null,
      tabs: [],
      level: 0,
    },
  ],
  sequence: [[0, 0], [0, 1], [1, 0], [1, 1], [2], [3]],
};

export const session = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 0,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 3,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const configurablePrivilege = {
  session: [
    {
      id: 1,
      session_id: "Null",
      tab_id: 140,
      wf: 1,
      user_id: 1,
    },
    {
      id: 2,
      session_id: "ABC",
      tab_id: 139,
      wf: 1,
      user_id: 1,
    },
  ],
};

export const TamOptions = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "FSC Careers",
    value: "FSC_careers",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
];

export const RE2Options = [
  {
    label: "Naukri",
    value: "naukri",
  },
  {
    label: "Linkedin",
    value: "Linkedin",
  },
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Updaaz",
    value: "Updaaz",
  },
  {
    label: "Hirist",
    value: "Hirist",
  },
];

export const TypemasterData = {
  data: [
    {
      category: {
        category_name: "Department",
      },
      name: "Software S/W",
      value: "1",
    },
    {
      category: {
        category_name: "Department",
      },
      name: "Hardware",
      value: "2",
    },
  ],
};

export const Typemaster = {
  data: {
    Department: [
      {
        name: "Software S/W",
        value: "1",
      },
      {
        name: "Hardware",
        value: "2",
      },
    ],
    Job: [
      {
        name: "Engineer",
        value: "1",
      },
      {
        name: "QA",
        value: "2",
      },
    ],
  },
};

export const RCQuestions = [
  {
    label: "JD Keywords Not Matched with CV Keywords",
  },
  {
    label: "Lack of Relevant Work Experience",
  },
  {
    label: "Absence of Relevant Academic Validation",
  },
  {
    label: "Skills and Responsibilities Not Aligned with the JD",
  },
  {
    label: "Unfavorable Location",
  },
  {
    label: "CTC Not Within the Budget",
  },
];

export const TI = [
  {
    interview_id: 1,
    questions_data: [
      { question: "TI 4", score: "1", comments: "C1" },
      { question: "TI 3", score: "2", comments: "C2" },
      { question: "TI 2", score: "3", comments: "C3" },
    ],
    categories: [
      { category: "Communication", manualMarks: 2 },
      { category: "Coding", manualMarks: 4 },
    ],
    total_score: 6,
    feedback: "Feedback",
    candidate_status: "Draft",
    created_by_id: 3,
    updated_by_id: 3,
    workflow_session: {
      candidate_job_id: null,
      user: 3,
      created_by_id: 3,
      updated_by_id: 3,
    },
  },
];

export const MDApproval = [
  {
    candidate_job_id: 96,
    md_comment: "Comments",
    md_approval: "Draft",
    created_by_id: 41,
    updated_by_id: 41,
    workflow_session: {
      candidate_job_id: 96,
      tab_id: 288,
      workflow_id: 20,
      user: 41,
      created_by_id: 41,
      updated_by_id: 41,
    },
  },
];

export const Telephonic = [
  {
    requisition_id: null,
    candidate_job_id: null,
    interviewer_id: 3,
    interview_details: [
      {
        id: 1,
        question: "Six Days Working",
        answer: "Okay",
        anwser_status: true,
      },
      {
        id: 2,
        question: "Saturday & Sunday Rotational",
        answer: "Bad",
        anwser_status: true,
      },
      {
        id: 3,
        question: "Location Suitability",
        answer: "Bad",
        anwser_status: true,
      },
      { id: 4, question: "Notice Period", answer: 45, anwser_status: true },
      {
        id: 5,
        question: "Flexiable Notice Period",
        answer: "Good",
        anwser_status: true,
      },
      {
        id: 6,
        question: "Relevant vs Required Experience",
        answer: "2",
        anwser_status: true,
      },
      {
        id: 7,
        question: "Relevant Exp. Score",
        answer: "Okay",
        anwser_status: true,
      },
      {
        id: 8,
        question: "Technical Profile vs Skillsets",
        answer: "Good",
        anwser_status: true,
      },
      {
        id: 9,
        question: "Relevant Acedemic Validaion Score",
        answer: "Good",
        anwser_status: true,
      },
      {
        id: 10,
        question: "Skillset to Profile Appropriation score",
        answer: "Okay",
        anwser_status: true,
      },
      {
        id: 11,
        question: "Stability Score",
        answer: "Bad",
        anwser_status: true,
      },
      {
        id: 12,
        question: "Cordidates CTC vs Budget",
        answer: "500000",
        anwser_status: true,
      },
      {
        id: 13,
        question: "Reason to change the job",
        answer: "Over Workload",
        anwser_status: true,
      },
    ],
    created_by_id: 3,
    updated_by_id: 3,
    workflow_session: {
      candidate_job_id: null,
      user: 3,
      created_by_id: 3,
      updated_by_id: 3,
    },
  },
];

export const KYCDATA = [
  {
    id: 1,
    label: "AADHAR CARD",
    document: "done",
  },
  {
    id: 2,
    label: "PAN CARD",
    document: null,
  },
  {
    id: 3,
    label: "Appointment Letter/Offer Letter",
    document: null,
  },
  {
    id: 4,
    label: "Appraisal/Increment Letter",
    document: "done",
  },
  {
    id: 5,
    label: "Last three months salary slip",
    document: null,
  },
  {
    id: 6,
    label: "Last six months salary account bank statement",
    document: "done",
  },
  {
    id: 7,
    label: "Recent passport size photograph",
    document: "done",
  },
];
