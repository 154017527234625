import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { TagContext } from "../../Context/TagContext";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";

const Tag = () => {
  const [searchTag, setSearchTag] = useState("");
  console.log(searchTag);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawTag,
    getTag,
    tagEntries,
    tagPageCount,
    setTagEntries,
    tagCount,
    handleTagClick,
    isTagData,
    isTagLoading,
  } = useContext(TagContext);

  const handleEntries = (event) => {
    setTagEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchTag(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getTag(1, searchTag);
    } else {
      getTag(1);
      setSearchTag("");
    }
  };

  useEffect(() => {
    getTag(1, searchTag);
  }, [tagEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Tag</h3>
          {isTagLoading ? (
            <div class="spinner-border text-primary ms-2" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
          </div>
          {privileges.includes("tag_create") ? (
            <div className="nk-block-head-content">
              <div className="nk-block-head-content">
                <NavLink
                  to={"/createtag"}
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-createtag"
                >
                  <em className="icon ni ni-plus"></em>
                </NavLink>

                <NavLink
                  to={"/createtag"}
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-createtag"
                >
                  <em className="icon ni ni-plus"></em>
                  <span>Create Tag</span>
                </NavLink>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
        {/* <!-- .nk-block-between --> */}
      </div>
      {/* <!-- .nk-block-head --> */}
      {/* <!-- .nk-block --> */}
      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchTag}
                      className="form-control"
                      placeholder="Search by tag name, category, value and description..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Tag Display Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Tag Name</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Tag Category</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Description</span>
                      </div>

                      {privileges.includes("tag_update") ? (
                        <div className="nk-tb-col">
                          <span>Edit</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                    {rawTag.length > 0 ? (
                      rawTag.map((data, index) => (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data.tag_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">{data.value || "-"}</span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data.tag_category?.tag_category_name || "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {data.tag_category?.description || "-"}
                              </span>
                            </span>
                          </div>

                          {privileges.includes("tag_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/edittag"}
                                state={{ editdata: data }}
                                className="btn btn-sm btn-primary"
                                id="button-button-edittag"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isTagData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={tagPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={tagPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) => handleTagClick(data, searchTag)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {tagCount === null ? 0 : tagCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={tagEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tag;
