import React, { Suspense, useContext } from "react";
import DefaultDepends from "./DefaultDepends";
import DefaultForm from "./DefaultForm";
import { SequenceContext } from "./SequenceContextProvider";
import { WorkflowContext } from "../../Context/WorkflowContext";
import secureLocalStorage from "react-secure-storage";

const NestedStepperForm = (props) => {
  console.log(props.data);
  // const privilleges = ["Assign Assignment", "Test","Assignment Review"]

  // Context API hook for getting current state of sequence context
  // const sequenceState = useSelector((state) => state.sequence);
  const { currentSequence, sequenceList, setLevel } =
    useContext(SequenceContext);
  const privileges = secureLocalStorage.getItem("privileges");
  console.log("privileges: ", privileges);
  console.log("currentSequence:", currentSequence);
  console.log("sequenceList:", sequenceList);
  const { workflowSession } = useContext(WorkflowContext);
  console.log("workflowSession:", workflowSession);

  // const session_store = session; //Using static JSON data
  const session_store = workflowSession; //Using API data
  console.log(session_store);

  const configurablePrivilege = secureLocalStorage.getItem(
    "configurablePrivileges"
  );
  console.log("configurablePrivilege: ", configurablePrivilege);

  // const session_store = secureLocalStorage.getItem('tab_id')
  // Loading Forms dynamically using react lazy
  // parameter 'form' refers to component name
  const loadForm = (form) => {
    if (form.form !== null) {
      console.log("form:", form);
      const Form = React.lazy(() => import(`../${form.form.form_path}`));
      console.log(Form);
      return (props) => <Form {...props} formId={form} />;
    } else {
      console.log("No FOrm Added")
    }
  };
  console.log(props.data);
  console.log(props.level);

  return (
    <>
      <ul className="nav nav-tabs" id="nav-tab" role="tablist">
        {props.data.map((tab, i) => {
          let chengetab = -1;
          if (props.level === 0) {
            chengetab = sequenceList.findIndex(
              (sequence) => sequence[props.level] === i
            );
          } else {
            chengetab = sequenceList.findIndex((sequence) => {
              // Check if the sequence length is at least as long as the currentSequence
              if (sequence.length >= props.level + 1) {
                // Check if the elements of the sequence match the currentSequence up to targetIndex
                for (let i = 0; i < props.level; i++) {
                  if (sequence[i] !== currentSequence[i]) {
                    return false;
                  }
                }
                // Check if the element at targetIndex matches targetValue
                if (sequence[props.level] === i) {
                  return true;
                }
              }
              return false;
            });
          }
          return (
            <li
              key={i}
              className={`nav-item ${
                i === currentSequence[props.level] ? "active" : ""
              }`}
              id={`nav-${tab.id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#tab${tab.id}`}
              type="button"
              role="tab"
              disabled
            >
              <a
                className="nav-link"
                onClick={() => {
                  if (chengetab !== -1) {
                    setLevel(chengetab);
                    console.log(chengetab);
                  }
                }}
              >
                
                {tab.name}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab-content" id="nav-tabContent">
        {props.data.map((tab, i) => {
          console.log(tab);
          const Form = loadForm(tab);
          // should_render_form checks the form name available on privilleges or not.
          const shouldRenderForm = tab.form
            ? privileges.includes(tab.form.form_name)
            : true;
          console.log("shouldRenderForm: ", shouldRenderForm);

          // const dependsOn =  session_store.some(session => session.tab_id === tab.id);
          // const dependsOn = tab.depends_on.some((dependsOn) =>
          // workflowSession.some((session) => session.tab_id === dependsOn)
          // );
          // console.log(dependsOn);

          let dependsOn;
          {
            tab.depends_on.map((depend) => {
              console.log("depend:", depend);
              if (depend === 0) {
                dependsOn = true;
                console.log("Setting dependsOn to true.");
              } else {
                dependsOn = tab.depends_on.every((dependsOn) =>
                  session_store.some((session) => session.tab_id === dependsOn)
                );
                console.log("dependsOn value:", dependsOn);
              }
            });
          }

          console.log("dependsOn: ", dependsOn);

          return (
            <div
              key={i}
              className={`tab-pane fade ${
                // Open tab-content of currently active tab
                // That is why syntax is same as with current tab
                i === currentSequence[props.level] ? "show active" : ""
              }`}
              id={tab.name}
              role="tabpanel"
              tabIndex="0"
            >
              {/* Check if current tab having more tabs nested */}
              {tab?.tabs.length !== 0 ? (
                // If yes: pass the tabs of current tab recursively and increse level by one
                (console.log("Inside NestedStepperForm:", tab),
                (<NestedStepperForm data={tab.tabs} level={props.level + 1} />))
              ) : (
                // If not: Render the form for current component
                <Suspense fallback={<div>Loading...</div>}>
                  {shouldRenderForm ? (
                    dependsOn ? (
                      (console.log("Inside Form:", tab), (<Form tabs={tab} />))
                    ) : (
                      <DefaultDepends />
                    )
                  ) : (
                    <DefaultForm />
                  )}
                </Suspense>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default NestedStepperForm;
