import React, { useContext, useEffect } from "react";
import { CandidateDetailsContext } from "../../../Context/CandidateDetailsContext";
import secureLocalStorage from "react-secure-storage";

const EducationalDetails = () => {
  const { educationDetails, isEducationDataLoading, getEducationData } =
    useContext(CandidateDetailsContext);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");

  useEffect(() => {
    getEducationData(candidate_job_id);
  }, [candidate_job_id]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Candidate's Educational Details</h4>
            {isEducationDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isEducationDataLoading ? (
        <div></div>
      ) : (
        <div>
          {educationDetails == 0 ? (
            <div>No educational details found</div>
          ) : (
            <div className="row">
              {educationDetails?.map((edu, index) => (
                <div className="col-lg-12 col-md-12 col-sm-12" key={index}>
                  <div className="card">
                    <div className="card-aside-wrap">
                      <div className="card-content">
                        <div className="card-inner">
                          <div className="nk-block">
                            <div className="nk-block-head">
                              <h5 className="title">
                                {edu?.education_type || "-"}
                              </h5>
                            </div>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Board Name
                                  </span>
                                  <span className="profile-ud-value">
                                    {edu?.board_name || "-"}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Passing Year
                                  </span>
                                  <span className="profile-ud-value">
                                    {edu?.passing_year || "-"}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Percentage
                                  </span>
                                  <span className="profile-ud-value">
                                    {edu?.percentage || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Specialization
                                  </span>
                                  <span className="profile-ud-value">
                                    {edu?.specialization || "-"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">
                                    Additional Qualification
                                  </span>
                                  <span className="profile-ud-value">
                                    {edu?.additional_qualification || "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block">
                            <div className="nk-block-head nk-block-head-sm nk-block-between">
                              <h5 className="title">Comments</h5>
                            </div>
                            {/* <!-- .nk-block-head --> */}
                            <div className="bq-note">
                              <div className="bq-note-item">
                                <div className="bq-note-text">
                                  <p>{edu?.comments || "-"} </p>
                                </div>
                              </div>
                              {/* <!-- .bq-note-item --> */}
                            </div>
                            {/* <!-- .bq-note --> */}
                          </div>
                          <div className="nk-divider divider md"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EducationalDetails;
