import React, { useEffect, useState, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { ScheduleCandidateInterviewContext } from "../../Context/ScheduleCandidateInterviewContext";
import ReactPaginate from "react-paginate";
import ScheduleFreeSlots from "./ScheduleFreeSlots";
import { WorkflowContext } from "../../Context/WorkflowContext";
import { MDApprovalContext } from "../../Context/MDApprovalContext";

const ScheduleCandidateView = () => {
  let location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const [searchCandidate, setSearchCandidate] = useState("");
  console.log(searchCandidate);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawAcceptedCandidateDetails,
    getAcceptedCandidateDetails,
    candidateEntries,
    candidatePageCount,
    setCandidateEntries,
    candidateCount,
    handleAcceptCandidateClick,
    isCandidateData,
    isCandidateLoading,
  } = useContext(ScheduleCandidateInterviewContext);

  const { getCandidateStepperFlow, getWorkflowSession } =
    useContext(WorkflowContext);
  const { getAssignmentData, getCandidateExamData } =
    useContext(MDApprovalContext);

  console.log(rawAcceptedCandidateDetails);

  const handleEntries = (event) => {
    setCandidateEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchCandidate(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.requisition?.id,
        searchCandidate
      );
    } else {
      getAcceptedCandidateDetails(
        1,
        location?.state?.editpost?.requisition?.id
      );
      setSearchCandidate("");
    }
  };

  const handleViewStepper = (id) => {
    console.log(id);
    secureLocalStorage.setItem("RWFcandidate_job_id", id);
    secureLocalStorage.setItem(
      "RWFrequisition_id",
      location?.state?.editpost?.requisition?.id
    );
    secureLocalStorage.setItem(
      "WF_job_opening_id",
      location?.state?.editpost?.job_opening?.id
    );
    getCandidateStepperFlow(id);
    getAssignmentData(id);
    // getSendOfferData(id);
    getCandidateExamData(id);
    getWorkflowSession(0);
    console.log(id);
  };

  useEffect(() => {
    getAcceptedCandidateDetails(1, location?.state?.editpost?.requisition?.id);
  }, [candidateEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Candidate Interview</h3>
            {isCandidateLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content d-flex">
            <div class="nk-block-head-content me-3">
              <a
                id="back-button"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="back-button"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
            <div className="button-group">
              {privileges.includes("schedule_free_slots") ? (
                <button
                  className="btn btn-outline-primary btn-dim me-3"
                  id="button-button-book_free_slot"
                  data-bs-toggle="modal"
                  data-bs-target="#free_slot"
                >
                  Free slot
                </button>
              ) : (
                " "
              )}

              {privileges.includes("view_calendar") ? (
                <NavLink
                  to="/schedulecalendar"
                  state={{ editpost: location?.state?.editpost }}
                  className="btn btn-outline-success btn-dim"
                  id="button-button-calendar"
                >
                  Calendar
                </NavLink>
              ) : (
                " "
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchCandidate}
                      className="form-control"
                      placeholder="Search by candidate name, email, tag..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Candidate Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Email</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Mobile</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Resume</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Schedule Interview</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>View Scheduled Interview</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>View Progress</span>
                      </div>
                    </div>

                    {rawAcceptedCandidateDetails.length !== 0 ? (
                      rawAcceptedCandidateDetails.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {post?.candidate_name || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post?.email || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.mobile || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            {post?.resume ? (
                              <a
                                className="btn btn-primary"
                                id="button-button-viewresume"
                                href={post?.resume || "-"}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div className="nk-tb-col tb-col-md">
                            {privileges.includes("schedule_interview") ? (
                              <NavLink
                                to="/scheduleinterviewcreate"
                                className="btn btn-primary"
                                id="button-button-scheduleinterviewcreate"
                                state={{ CandidateData: post }}
                              >
                                <em
                                  className="icon ni ni-user-check-fill"
                                  style={{ color: "white" }}
                                ></em>
                              </NavLink>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="nk-tb-col tb-col-md">
                            {privileges.includes(
                              "schedule_interview_retrieve"
                            ) ? (
                              <NavLink
                                to="/scheduleinterviewdetails"
                                className="btn btn-primary"
                                id="button-button-scheduleinterviewdetails"
                                state={{ CandidateData: post }}
                              >
                                <em
                                  className="icon ni ni-clipboad-check"
                                  style={{ color: "white" }}
                                ></em>
                              </NavLink>
                            ) : (
                              ""
                            )}
                          </div>

                          {privileges.includes("stepper") ? (
                            <div className="nk-tb-col tb-col-md">
                              <NavLink
                                to={"/stepper"}
                                onClick={() => handleViewStepper(post.id)}
                                className="btn btn-primary"
                                id="button-button-viewstepper"
                              >
                                <em className="icon ni ni-eye"></em>
                              </NavLink>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))
                    ) : isCandidateData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={candidatePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={candidatePageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleAcceptCandidateClick(data, searchCandidate)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {candidateCount === null ? 0 : candidateCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={candidateEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScheduleFreeSlots requisitionData={location?.state?.editpost} />
    </>
  );
};

export default ScheduleCandidateView;
