import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";

const CandidateResumeCreate = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  const navigate = useNavigate();
  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();
  let location = useLocation();
  console.log(location.state);
  console.log(location.state?.editpost?.requisition?.id);
  console.log(location.state?.digital_marketing_platform);

  const AgencyNameDropdown = location.state?.editpost?.requisition?.re3_agency;
  console.log(AgencyNameDropdown);
  const CollegeNameDropdown =
    location.state?.editpost?.requisition?.re1_college;
  console.log(CollegeNameDropdown);

  const { getCandidateResume } = useContext(CandidateResumeContext);

  const [uploadType, setUploadType] = useState("singleupload");
  console.log(uploadType);

  const [selectedFiles, setSelectedFiles] = useState([]);
  console.log(selectedFiles);

  const handleResumeUploadType = (e) => {
    setUploadType(e.target.id);
  };

  const handleResumeChange = (files) => {
    console.log(files);
    setValue("resume", files);
    setSelectedFiles(files);
  };

  const onSubmit = (data) => {
    console.log(data);
    let user = new FormData();
    user.append(
      "requisition_id",
      location?.state?.editpost?.requisition?.id || null
    );
    user.append("college_name", data?.college_name?.value || null);
    user.append("agency_name", data?.agency_name?.value || null);
    user.append(
      "digital_marketing_platform",
      location.state?.digital_marketing_platform || null
    );
    user.append("candidate_name", data.candidate_name);
    user.append("email", data.email);
    user.append("mobile", data.mobile);
    for (let i = 0; i < selectedFiles.length; i++) {
      console.log(selectedFiles);
      console.log(selectedFiles[i]);
      user.append("resume", selectedFiles[i]);
    }
    user.append("created_by_id", userID);
    user.append("updated_by_id", userID);

    for (var pair of user.entries()) {
      console.log(pair);
    }

    const url = VARIABLES.url + "/api/candidateresume/";
    console.log(url);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      data: user,
    };

    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        getCandidateResume();
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Candidate resume successfully created",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to create candidate resume",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Add Resume pool </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-12">
                  <div className="custom-control custom-radio me-3">
                    <input
                      type="radio"
                      id="singleupload"
                      name="resumeupload"
                      className="custom-control-input"
                      checked={uploadType === "singleupload"}
                      onChange={(e) => handleResumeUploadType(e)}
                    />
                    <label className="custom-control-label" for="singleupload">
                      Single Upload
                    </label>
                  </div>
                  {/* <div className="custom-control custom-control custom-radio">
                    <input
                      type="radio"
                      id="bulkupload"
                      name="resumeupload"
                      className="custom-control-input"
                      checked={uploadType === "bulkupload"}
                      onChange={(e) => handleResumeUploadType(e)}
                    />
                    <label className="custom-control-label" for="bulkupload">
                      Bulk Upload
                    </label>
                  </div> */}
                </div>

                {uploadType === "singleupload" ? (
                  <>
                    {location.state?.re1Data == "re1Data" && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            College name
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={CollegeNameDropdown?.map((info) => ({
                                  value: info,
                                  label: info,
                                }))}
                                isClearable={true}
                                id="select-forms-college_name"
                              />
                            )}
                            defaultValue=""
                            rules={{ required: true }}
                            name="college_name"
                            control={control}
                          />
                          {errors.college_name && (
                            <p className="fs-8 text-danger">
                              College name is required
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    {location.state?.re3Data == "re3Data" && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            Name of Agency
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={AgencyNameDropdown?.map((info) => ({
                                  value: info.id,
                                  label: info.agency_name,
                                }))}
                                isClearable={true}
                                id="select-forms-agency_name"
                              />
                            )}
                            defaultValue=""
                            rules={{ required: true }}
                            name="agency_name"
                            control={control}
                          />
                          {errors.agency_name && (
                            <p className="fs-8 text-danger">
                              Name of agency is required
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Candidate Name
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text-forms-candidatename"
                        placeholder="Enter Candidate Name"
                        {...register("candidate_name", { required: true })}
                      />
                      {errors.candidate_name && (
                        <span className="fs-8 text-danger">
                          Candidate Name is required
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Email
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="text-forms-email"
                        placeholder="Enter Email"
                        {...register("email", { required: true })}
                      />
                      {errors.email && (
                        <span className="fs-8 text-danger">
                          Email is required
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Mobile
                        <span className="fs-8 text-danger"> *</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="text-forms-mobile"
                        placeholder="Enter mobile"
                        {...register("mobile", {
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                        })}
                      />
                      {errors.mobile && errors.mobile.type == "required" && (
                        <span className="fs-8 text-danger">
                          Mobile is required
                        </span>
                      )}
                      {errors.mobile && errors.mobile.type == "minLength" && (
                        <span className="fs-8 text-danger">
                          Number should be minimum 10 digits
                        </span>
                      )}
                      {errors.mobile && errors.mobile.type == "maxLength" && (
                        <span className="fs-8 text-danger">
                          Number should be maximum 10 digits
                        </span>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Resume <span className="fs-8 text-danger">*</span>
                      </label>
                      <Controller
                        name="resume"
                        control={control}
                        rules={{
                          required: true,
                          validate: (value) =>
                            value?.[0]?.type === "application/pdf",
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            className="form-control"
                            id="file-forms-resume"
                            type="file"
                            onChange={(e) => {
                              field.onChange(e.target.files);
                              handleResumeChange(e.target.files);
                            }}
                          />
                        )}
                      />
                      {errors.resume && errors.resume.type === "required" && (
                        <span className="fs-8 text-danger">
                          Resume is required
                        </span>
                      )}
                      {errors.resume && errors.resume.type === "validate" && (
                        <span className="fs-8 text-danger">
                          Please upload your document in pdf format
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {location.state?.re1Data == "re1Data" && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            College name
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={CollegeNameDropdown?.map((info) => ({
                                  value: info,
                                  label: info,
                                }))}
                                isClearable={true}
                                id="select-forms-college_name"
                              />
                            )}
                            defaultValue=""
                            rules={{ required: true }}
                            name="college_name"
                            control={control}
                          />
                          {errors.college_name && (
                            <p className="fs-8 text-danger">
                              College name is required
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    {location.state?.re3Data == "re3Data" && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            Name of Agency
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={AgencyNameDropdown?.map((info) => ({
                                  value: info.id,
                                  label: info.agency_name,
                                }))}
                                isClearable={true}
                                id="select-forms-agency_name"
                              />
                            )}
                            defaultValue=""
                            rules={{ required: true }}
                            name="agency_name"
                            control={control}
                          />
                          {errors.agency_name && (
                            <p className="fs-8 text-danger">
                              Name of agency is required
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Resume <span className="fs-8 text-danger"> *</span>
                      </label>
                      <Controller
                        name="resume"
                        control={control}
                        rules={{
                          required: true,
                          validate: (value) => {
                            console.log(value);
                            const filesArray = Array.from(value);
                            return filesArray.every(
                              (file) => file.type === "application/pdf"
                            );
                          },
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            className="form-control"
                            id="file-forms-resume"
                            type="file"
                            multiple
                            onChange={(e) => handleResumeChange(e.target.files)}
                          />
                        )}
                      />
                      {errors.resume && errors.resume.type === "required" && (
                        <span className="fs-8 text-danger">
                          Resume is required
                        </span>
                      )}
                      {errors.resume && errors.resume.type === "validate" && (
                        <span className="fs-8 text-danger">
                          Please upload your document in pdf format
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createcandidateresume"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateResumeCreate;
