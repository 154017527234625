export const VARIABLES = {
  // for developer
  // url : "http://64.226.76.196:8005",
  // rms_login : "rms-nxgp",
  // rms_id : 2

  //for prod
  url: "https://rms-api.fluidscapes.in",
  rms_login: "rms-fpcw",
  rms_id: 3,
};
