import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const MDApprovalContext = createContext(null);

function MDApprovalProvider({ children }) {
  const [assignment, setAssignment] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState([]);
  const [candidateExam, setCandidateExam] = useState([]);
  console.log(candidateExam);

  const getAssignmentData = async (candidate_job_id) => {
    setDashboardLoading(true);
    const url = VARIABLES.url + `/api/assignment/?candidate_job=${candidate_job_id}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setAssignment(res.data.data);
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  const getCandidateExamData = async (candidate_job_id) => {
    setDashboardLoading(true);
    const url =
      VARIABLES.url + `/api/candidateexam/?candidate_job=${candidate_job_id}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setCandidateExam(res.data.data);
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <MDApprovalContext.Provider
        value={{
          assignment,
          candidateExam,
          dashboardLoading,
          getCandidateExamData,
          getAssignmentData,
        }}
      >
        {children}
      </MDApprovalContext.Provider>
    </div>
  );
}

export default MDApprovalProvider;
