import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { TAMRE1RE2RE3Context } from "../../Context/TAMRE1RE2RE3Context";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const Tam = () => {
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    rawTamData,
    getTamData,
    tamEntries,
    tamPageCount,
    setTamEntries,
    tamCount,
    handleTamClick,
    isTamData,
    isTamLoading,
  } = useContext(TAMRE1RE2RE3Context);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const calculatePendingSince = (requisition_acceptance_on) => {
    const createdDate = new Date(requisition_acceptance_on);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - createdDate;
    const InMilliseconds = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / InMilliseconds
    );
    console.log(differenceInDays);
    return differenceInDays;
  };

  const handleEntries = (event) => {
    setTamEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getTamData(1, searchRequest);
    } else {
      getTamData(1);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    getTamData(1);
  }, [tamEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">TAM</h3>
            {isTamLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search by requisition id, job title..."
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        View all
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>

                      <div className="nk-tb-col">
                        <span>Pending Since (Days) for assigning to RE</span>
                      </div>

                      {privileges.includes("requisition_user_assign_update") ? (
                        <div className="nk-tb-col">
                          <span>Action - (Assign to REs)</span>
                        </div>
                      ) : (
                        " "
                      )}

                      {privileges.includes("job_opening_retrieve") ? (
                        <div className="nk-tb-col">
                          <span>View Requisition Details</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {rawTamData.length !== 0 ? (
                      rawTamData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name + post.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post.job_opening?.job_title || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post.job_opening?.department
                                ?.department_display_name || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {calculatePendingSince(
                                post.requisition_acceptance_on
                              )}{" "}
                              days
                            </span>
                          </div>

                          {privileges.includes(
                            "requisition_user_assign_update"
                          ) ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/assignedrc"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-associateuseredit"
                              >
                                <em class="icon ni ni-user-alt"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}

                          {privileges.includes("job_opening_retrieve") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/jobopeningdetails"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-requestedjobdetails"
                              >
                                <em className="icon ni ni-eye"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isTamData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">No Data Found</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={tamPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={tamPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) => handleTamClick(data, searchRequest)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {tamCount === null ? 0 : tamCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={tamEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tam;
