import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { SendOfferContext } from "../../../Context/SendOfferContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";

const MDApproval = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const { assignment } = useContext(MDApprovalContext);

  const { candidateStatus, getCandidateJobOfferData } =
    useContext(SendOfferContext);

  const { level, sequenceList, setSequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  console.log("assignment:", assignment); //store data of assignment submitted by candidate (used for showing results)
  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);
  console.log(interviewActionDropdownData);
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  //Submission of form
  const onSubmit = (data) => {
    console.log(data);
    var MDApproval = JSON.stringify({
      candidate_job_id: candidate_job_id,
      requisition_id: requisition_id,
      md_comments: data.comments,
      md_approval: data.is_selected,
      md_feedback: data.feedback,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_job_id,
        tab_id: formId.id,
        workflow_id: formId.WF,
        user: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    console.log(MDApproval);

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/candidatejob/${candidate_job_id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: MDApproval,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        // workflowSession();
        nextLevel();
        getWorkflowSession(1);
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "successfully approved",
        });
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed to approve",
        });
      });
  };

  useEffect(() => {
    getCandidateJobOfferData();
  }, []);

  // Pre-populting data
  useEffect(() => {
    const editedData = candidateStatus;
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      defaultValues.comments = editedData.md_comments;
      defaultValues.feedback = editedData.md_feedback;
      defaultValues.is_selected = editedData.md_approval
        ? editedData.md_approval
        : "Draft";

      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> MD Approval </h4>
          </div>
          {/* {formId.is_iterable && formId.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )} */}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                {/* <div className="profile-ud-list">
                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Test 1: </span>
                      {assignment?.map((info) => (
                        <span
                          className="profile-ud-value fw-bold align-start"
                          style={{
                            color:
                              info?.selection_status === "Reject"
                                ? "red"
                                : info?.selection_status === "Accept"
                                ? "green"
                                : "black",
                          }}
                        >
                          {info?.selection_status || "-"}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="profile-ud-item"></div>

                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Test 2: </span>
                      {candidateExam?.map((info) => (
                        <span
                          className="profile-ud-value fw-bold align-start"
                          style={{
                            color:
                              info?.selection_status === "Reject"
                                ? "red"
                                : info?.selection_status === "Accept"
                                ? "green"
                                : "black",
                          }}
                        >
                          {info?.selection_status || "-"}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="profile-ud-item"></div>

                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Test 3: </span>
                      {candidateInterview?.map((info) => (
                        <span
                          className="profile-ud-value fw-bold align-start"
                          style={{
                            color:
                              info?.interview_action === "Reject"
                                ? "red"
                                : info?.interview_action === "Accept"
                                ? "green"
                                : "black",
                          }}
                        >
                          {info?.interview_action || "-"}
                        </span>
                      ))}
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Comments
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="textarea-forms-comments"
                    placeholder="Enter Comments"
                    {...register("comments", { required: true })}
                  />
                  {errors.comments && (
                    <span className="fs-8 text-danger">
                      Comments are required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Feedback
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="textarea-forms-feedback"
                    placeholder="Enter feedback"
                    {...register("feedback", { required: true })}
                  />
                  {errors.feedback && (
                    <span className="fs-8 text-danger">
                      Feedback is required
                    </span>
                  )}
                </div>

                <div className="col-lg-12 col-md-6 col-sm-12">
                  <ul className="row">
                    {interviewActionDropdownData.map((action, index) => (
                      <li className="col-lg-2 col-md-2 col-sm-2" key={index}>
                        <div className="custom-control custom-radio me-3">
                          <input
                            type="radio"
                            id={`checkbox-form-is-selected-MD-${action.value.toLowerCase()}`}
                            name="resumeupload"
                            className="custom-control-input"
                            value={action.value}
                            {...register("is_selected", { required: true })}
                          />
                          <label
                            className="custom-control-label"
                            for={`checkbox-form-is-selected-MD-${action.value.toLowerCase()}`}
                          >
                            {action.name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {errors.is_selected && (
                    <span className="fs-8 text-danger">
                      Please select any one
                    </span>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-mdapproval"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-mdapproval"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default MDApproval;
