import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { KYCDATA } from "../../WorkflowStepperForm/StepperFormJson";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { KYCContext } from "../../../Context/KYCContext";
import Snackbar from "../../../Base/Snackbar";
import { SnackbarContext } from "../../../Context/SnackbarContext";

const KYC = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present

  const { KYCDocuments } = useContext(TypemasterDropdownContext);
  console.log(KYCDocuments);

  const { KYCDetails, isLoading, getKYCDetails } = useContext(KYCContext);
  console.log(KYCDetails);

  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  console.log(sequenceList);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const [submitLoading, setSubmitLoading] = useState(false);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  //Form submission
  const onSubmit = (data) => {
    if (KYCDocuments) {
      let workflow_session = {
        candidate_job_id: candidate_job_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user: userID,
        created_by_id: userID,
        updated_by_id: userID,
      };
      console.log(data);
      let user = new FormData();
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== "")
      );
      Object.keys(filteredData).map((key) => {
        user.append(key, filteredData[key]);
      });
      user.append("candidate_job_id", candidate_job_id);
      user.append("requisition_id", requisition_id);
      user.append("created_by_id", userID);
      user.append("updated_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      for (var pair of user.entries()) {
        console.log(pair);
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: VARIABLES.url + `/api/knowyourcandidate/`,
        data: data,
      };

      console.log(config);

      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          nextLevel();
          getWorkflowSession();
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Documents uploaded successfully",
          });
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to upload document",
          });
        });
    } else {
      let workflow_session = {
        candidate_job_id: candidate_job_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user: userID,
        created_by_id: userID,
        updated_by_id: userID,
      };
      console.log(data);
      let user = new FormData();
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== "")
      );
      Object.keys(filteredData).map((key) => {
        user.append(key, filteredData[key]);
      });
      user.append("candidate_job_id", candidate_job_id);
      user.append("requisition_id", requisition_id);
      user.append("created_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      for (var pair of user.entries()) {
        console.log(pair);
      }

      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: VARIABLES.url + `/api/knowyourcandidate/`, //API CHANGE
        data: data,
      };

      console.log(config);

      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          nextLevel();
          getWorkflowSession();
          setSubmitLoading(false);
          console.log(JSON.stringify(response.data));
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Documents updated successfully",
          });
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to update document",
          });
        });
    }
  };

  useEffect(() => {
    getKYCDetails(candidate_job_id);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Know Your Candidate </h4>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {formId.is_iterable && formId.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )} */}
        </div>
      </div>
      <div className="card">
        <div className="card-inner">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row g-4">
              {KYCDocuments?.map((info, index) => {
                const documentData = KYCDetails[1]?.documents?.find(
                  (doc) => doc?.document_name === info?.value
                );
                console.log(documentData);
                return (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="form-control fs-6 d-flex align-center justify-between">
                        <p className="m-0 p-0">{info.name}</p>
                        <div
                          className="d-flex align-center"
                          style={{ gap: "0.5rem" }}
                        >
                          <div>
                            <label
                              className="form-label m-0 p-0"
                              for={`file-upload-${index + 1}`}
                            >
                              <em
                                className="icon ni ni-link-v fs-4"
                                style={{ color: "blue" }}
                              ></em>
                            </label>
                            <Controller
                              name={info.value}
                              // name={`document-${index + 1}`}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <input
                                  className="form-control"
                                  id={`file-upload-${index + 1}`}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) =>
                                    field.onChange(e.target.files[0])
                                  }
                                />
                              )}
                            />
                          </div>
                          {documentData !== undefined &&
                          documentData?.document !== null ? (
                            <>
                              <div className="align-center">
                                <p className="m-0 p-0">
                                  <a
                                    href={documentData?.document || "-"}
                                    id="button-button-viewdocument"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "black" }}
                                  >
                                    view
                                  </a>
                                </p>
                              </div>
                              <div className="align-center">
                                <em
                                  className="icon ni ni-check-circle fs-4"
                                  style={{ color: "green" }}
                                ></em>
                              </div>
                            </>
                          ) : (
                            <div className="align-center">
                              <em
                                className="icon ni ni-alert-circle fs-4"
                                style={{ color: "red" }}
                              ></em>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="col-12">
                <div className="form-group">
                  {level !== 0 && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary me-3"
                      id="button-button-prevlevel"
                      onClick={() => prevLevel()}
                    >
                      Prev
                    </button>
                  )}
                  {submitLoading ? (
                    <button className="btn text-dark" disabled>
                      Loading...
                    </button>
                  ) : (
                    <>
                      {sequenceList.length - 2 > level ? (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-submitkyc"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-submitkyc"
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default KYC;
