import { useContext, useEffect, useState } from "react";
import { candidateFormWorkflowss } from "./StepperFormJson";
import { SequenceContext } from "./SequenceContextProvider";
import NestedStepperForm from "./NestedStepperForm";
import { WorkflowContext } from "../../Context/WorkflowContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
{
  /*

======================================================================================
                          CODE EXPLANATION
======================================================================================

  In the following code word 'sequence' refers array of arrays. Each array
  in list contains integers represent the active index for tab at each level.
  Indexing starts from '0' and increase in chronological order.

  Ex: [[0, 0], [0, 1], [1], [2, 0], [2, 1, 0]]

  In above example first array represent default active tab. The length of array
  represent number of tabs nested. In [0, 0] there are two tabs as:

  Tab 1
  Tab 1.1

======================================================================================
*/
}

const StepperForm = () => {
  let location = useLocation();
  const navigate = useNavigate();
  // console.log(location.state.editpost);

  // Context API hook for getting current state of sequence context
  const { level, sequenceList, setSequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  const { candidateFormWorkflow, getWorkflowSession } =
    useContext(WorkflowContext);
  // console.log(candidateFormWorkflowsss)
  // const candidateFormWorkflow = candidateFormWorkflowss
  console.log(typeof candidateFormWorkflow);
  console.log("candidateFormWorkflow:", candidateFormWorkflow);
  // Sequence state for storing sequence array
  const [sequence, setSequence] = useState(null);
  console.log("sequence: ", sequence);

  // Handle submit button
  const submitForm = () => {
    console.log("submited");
  };

  // Store sequence from json to sequence state
  useEffect(() => {
    console.log("Set Sequence HIT");
    console.log("candidateFormWorkflow length: ", candidateFormWorkflow);
    console.log("candidateFormWorkflow length: ", candidateFormWorkflow.length);
    if (candidateFormWorkflow.length !== 0) {
      setSequence(candidateFormWorkflow.sequence);
      console.log("Setting Sequence: ", candidateFormWorkflow);
      console.log("Setting Sequence: ", candidateFormWorkflow.length);
    }
  }, [candidateFormWorkflow]);

  // Set sequence state in current state of Sequence Context
  useEffect(() => {
    console.log("Set Sequence List HIT");
    if (sequence !== null && candidateFormWorkflow.length !== 0) {
      setSequenceList(sequence);
      console.log("Setting Sequence List: ", sequence);
    }
  }, [sequence]);

  // useEffect(()=>{
  //   getWorkflowSession(0)
  // },[])

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div class="card-tools me-n1 mt-n1">
            <div class="dropdown">
              <a
                class="dropdown-toggle btn btn-primary"
                data-bs-toggle="dropdown"
              >
                View Candidate Details
              </a>
              <div
                class="dropdown-menu dropdown-menu-sm dropdown-menu-end"
                style={{ maxWidth: "fit-content" }}
              >
                <ul class="link-list-opt no-bdr">
                  <li>
                    <NavLink to={"/"}>
                      <span>Personal Details</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>
                      <span>Educational Details</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>
                      <span>Professional Details</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-inner">
                {/* Render tabs here
      passing tabs array with initail level i.e 0 into Tab component */}
                {sequence == null && candidateFormWorkflow.length == 0 ? (
                  <div>Loading...</div>
                ) : (
                  ""
                )}
                {sequence !== null && sequence.length == 0 ? (
                  <div>No data Found</div>
                ) : (
                  ""
                )}
                {sequence?.length !== 0 &&
                  candidateFormWorkflow.length !== 0 &&
                  (console.log("first"),
                  (
                    <NestedStepperForm
                      data={candidateFormWorkflow.tabs}
                      level={0}
                    />
                  ))}

                {/* Navigation buttons */}
                {/* <div className="container-fluid mt-5"> */}
                {/* Render Prev button only if there are tabs before */}
                {/* {level !== 0 && (
                  <button className="btn btn-secondary me-3" id='button-button-prevlevel' onClick={() => prevLevel()}>
                    Prev
                  </button>
                )} */}

                {/* If there are more tabs then show next
         If it is the final tab then show submit button */}
                {/* {sequenceList.length - 2 > level ? (
                  <button className="btn btn-secondary" id='button-button-nextlevel' onClick={() => nextLevel()}>
                    Next
                  </button>
                ) : (
                  <button className="btn btn-secondary" id='submit-button-stepperform' onClick={submitForm}>
                    Submit
                  </button>
                )}
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StepperForm;
