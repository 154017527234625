import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constants";

export const JobAssignmentContext = createContext(null);

function JobAssignmentProvider({ children }) {
  // To store Assignment.
  const [isAssignmentData, setIsAssignmentData] = useState(false);
  const [rawAssignment, setRawAssignment] = useState([]);
  console.log(rawAssignment)
  const [assignmentEntries, setAssignmentEntries] = useState(5);
  const [assignmentCount, setAssignmentCount] = useState(null);
  const [assignmentPageCount, setAssignmentPageCount] = useState(0);
  const [isAssignmentLoading, setIsAssignmentLoading] = useState(false);

  // To store all jobs assignment data.
  const [isJobAssignmentLoading, setIsJobAssignmentLoading] = useState(false);
  const [jobAssignmentData, setjobAssignmentData] = useState([]);

  // "GetAssignment" API call to get Assignment data
  const getAssignmentData = async (page, searchAssignment) => {
    setIsAssignmentLoading(true);
    console.log(searchAssignment);
    let url;
    if (searchAssignment) {
      url =
        VARIABLES.url +
        `/api/jobassignment/?entries=${assignmentEntries}&page=${page}&event=${searchAssignment}`;
    } else {
      url =
        VARIABLES.url +
        `/api/jobassignment/?entries=${assignmentEntries}&page=${page}`;
    }
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsAssignmentLoading(false);
      if (response.length !== 0) {
        setIsAssignmentData(false);
        setRawAssignment(res.data.data);
      } else {
        setIsAssignmentData(true);
        setRawAssignment(res.data.data);
      }
      const total = res.data.count;
      setAssignmentCount(total);
      setAssignmentPageCount(Math.ceil(total / assignmentEntries));
    } catch (error) {
      console.log(error);
      setIsAssignmentLoading(false);
      setIsAssignmentData(true);
    }
  };

  const handleAssignmentClick = async (data, searchAssignment) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAssignmentData(
      currentPage,
      searchAssignment
    );
  };

  // "GetAssignment" API call to get Assignment data
  const getJobAssignmentData = async () => {
    setIsJobAssignmentLoading(true);
    let url;
    url = VARIABLES.url + `/api/jobassignment/`;
    console.log(url);
    try {
      const res = await axios.get(url);
      let response = res.data.data;
      setIsJobAssignmentLoading(false);
      if (response.length !== 0) {
        setjobAssignmentData(res.data.data);
      } else {
        setjobAssignmentData(res.data.data);
      }
    } catch (error) {
      console.log(error);
      setIsJobAssignmentLoading(false);
    }
  };

  return (
    <div>
      <JobAssignmentContext.Provider
        value={{
          rawAssignment,
          getAssignmentData,
          assignmentEntries,
          assignmentPageCount,
          setAssignmentEntries,
          assignmentCount,
          handleAssignmentClick,
          isAssignmentData,
          isAssignmentLoading,

          isJobAssignmentLoading,
          jobAssignmentData,
          getJobAssignmentData,
        }}
      >
        {children}
      </JobAssignmentContext.Provider>
    </div>
  );
}

export default JobAssignmentProvider;
