import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { useNavigate } from "react-router-dom";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";

const MettleTestReview = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const { candidateExam, getCandidateExamData } = useContext(MDApprovalContext);
  console.log(candidateExam); //stores the test data of the candidate
  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);
  console.log(interviewActionDropdownData); //Stores data for radio button (accept, reject, other, draft)

  const { level, sequenceList, setSequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false); //message after submitting data

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  //Submission of form
  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      candidate_job_id: candidate_job_id,
      requisition_id: requisition_id,
      comment: data.comments,
      test_conducted_user: userID,
      selection_status: data.is_selected,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_job_id,
        tab_id: formId.id,
        workflow_id: formId.WF,
        user: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/candidateexam/${candidateExam[0].id}/`, //API CHECK // CANDIDATEEXAM
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        // workflowSession();
        getCandidateExamData(candidate_job_id);
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Test successfully reviewed",
        });
        reset();
        getWorkflowSession(1);
        nextLevel();
        // setTimeout(() => {
        //   navigate('/')
        // }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed to review test",
        });
      });
  };

  useEffect(() => {
    setValue("is_selected", "Other"); //sets default value as OTHER
  }, []);

  //Pre-populting data
  useEffect(() => {
    const editedData = candidateExam[0];
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      defaultValues.comments = editedData.comments;
      defaultValues.is_selected = editedData.selection_status;

      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Psychometric Test Review </h4>
          </div>
          {/* {formId.is_iterable && formId.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )} */}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="profile-ud-item p-0">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">View Test Result :</span>
                    <span className="profile-ud-value align-start">
                      <a
                        className="btn btn-primary"
                        id="button-button-viewresume"
                        href={"http://www.google.com"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <em
                          className="icon ni ni-file-pdf"
                          style={{ color: "white" }}
                        ></em>
                      </a>
                    </span>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12"></div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Comments
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="textarea-forms-comments"
                    placeholder="Enter Comments"
                    {...register("comments", { required: true })}
                  />
                  {errors.comments && (
                    <span className="fs-8 text-danger">
                      Comments are required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12"></div>
                {/* <div className="col-lg-12 col-md-6 col-sm-12">
                  <label className="form-label">Selection Status</label>
                  <select
                    className="form-select"
                    {...register("is_selected", { required: true })}
                    defaultValue=""
                  >
                    <option value="" disabled hidden>
                      Choose an option
                    </option>
                    <option value="Accept">Accept</option>
                    <option value="Reject">Reject</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.is_selected && (
                    <span className="fs-8 text-danger">Please choose one</span>
                  )}
                </div> */}

                <div className="col-lg-12 col-md-6 col-sm-12">
                  <ul className="row g-3">
                    {interviewActionDropdownData.map((action, index) => (
                      <li className="col-lg-2 col-md-2 col-sm-2" key={index}>
                        <div className="custom-control custom-radio me-3">
                          <input
                            type="radio"
                            id={`checkbox-form-is-selected-mettle-${action.value.toLowerCase()}`}
                            name="resumeupload"
                            className="custom-control-input"
                            value={action.value}
                            {...register("is_selected", { required: true })}
                          />
                          <label
                            className="custom-control-label"
                            for={`checkbox-form-is-selected-mettle-${action.value.toLowerCase()}`}
                          >
                            {action.name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {errors.is_selected && (
                    <span className="fs-8 text-danger">
                      Please select any one
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="form-group">
                  {level !== 0 && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary me-3"
                      id="button-button-prevlevel"
                      onClick={() => prevLevel()}
                    >
                      Prev
                    </button>
                  )}
                  {submitLoading ? (
                    <button
                      id="button-loading"
                      className="btn text-dark"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <>
                      {sequenceList.length - 2 > level ? (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-mettletestreview"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-mettletestreview"
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default MettleTestReview;
