import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";

const HRInterviewRound = ({ formId }) => {
  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);
  console.log(interviewActionDropdownData);

  const { HRInterviewQuestion, getHRInterviewQuestions, isQuestionLoading } =
    useContext(InterviewQuestionContext);
  console.log(HRInterviewQuestion); //all interview questionnaire data

  const { getWorkflowSession } = useContext(WorkflowContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);
  const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");
  const job_opening_id = secureLocalStorage.getItem("WF_job_opening_id");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  //Filerting data with having interview type "HR"
  // const HRQuestions = allInterviewQuestion?.filter((info) =>
  //   info.interview_type?.includes("HR")
  // );
  // console.log(HRQuestions);

  // Extract all categories from the HR questions and store them into a single array
  const allCategories = HRInterviewQuestion.flatMap(
    (question) => question.category
  );
  console.log("allCategories: ", allCategories);

  //creating new set to remove duplicate values
  const uniqueCategories = [...new Set(allCategories)];
  console.log("uniqueCategories: ", uniqueCategories);

  const [manualMarks, setManualMarks] = useState(
    Array(uniqueCategories.length).fill("") //Create an array with a length equal to the number of unique categories and store with empty string
  );
  console.log(manualMarks);

  const [totalManualMarks, setTotalManualMarks] = useState(0); //stores the total of manual marks

  // Function to update the toggle status for a question
  const [toggleStatus, setToggleStatus] = useState({});

  const updateToggleStatus = (index, status) => {
    setToggleStatus((prevState) => ({
      ...prevState, //spreads previous state
      [index]: status, //update status with specified index
    }));
  };

  // State variable to hold row data
  const [rowData, setRowData] = useState([]);
  console.log("rowData:", rowData);
  // Update the row data array with the new score
  const handleScoreChange = (index, score, comments, info) => {
    const updatedRowData = [...rowData]; //create a copy of rowData
    updatedRowData[index] = {
      ...updatedRowData[index], //existing data
      score: score, //update score
      comments: comments, // update comments
      info: info, //update info
    };
    setRowData(updatedRowData); //update state with all above data
  };

  // State variable to store categoryStats
  const [categoryStats, setCategoryStats] = useState({});
  console.log("categoryStats : ", categoryStats);

  // To calculate average marks
  const calculateAverageMarks = (category) => {
    if (categoryStats[category]) {
      // checks if that category presents in categoryStats
      const { count, totalScore } = categoryStats[category]; //takes count and totalscore from categorystats
      console.log("count", count);
      console.log("totalScore", totalScore);
      const overallScore = count * 5; //Assuming each question has max 5 marks
      console.log("overallScore", overallScore);
      return totalScore / overallScore;
    }
    return 0; // if that category doesn't present in categoryStats returns 0
  };

  const handleManualMarksChange = (index, value) => {
    const updatedManualMarks = [...manualMarks]; //create a copy of manual marks
    updatedManualMarks[index] = parseInt(value); //convert the value to integer
    setManualMarks(updatedManualMarks); //update the array of manual marks
    const total = updatedManualMarks.reduce((acc, mark) => acc + mark, 0); //Calculating total manual marks by adding all marks in the updated array
    setTotalManualMarks(total); //updated the state with total marks
  };

  //form submission
  const onSubmitScores = (data) => {
    console.log(data);
    const question_data = rowData.filter(
      (obj) =>
        typeof obj !== "undefined" &&
        obj.hasOwnProperty("info") &&
        obj.info.hasOwnProperty("question")
    );
    console.log(question_data);
    var user = JSON.stringify({
      interview_id: 1, //Interview ID
      candidate_resume: candidate_job_id,
      requisition_id: requisition_id,
      question_status: true,
      questions: question_data?.map((info) => ({
        question: info?.info?.question,
        score: info?.score,
        comments: info?.comments,
      })),
      categories: uniqueCategories.map((category, index) => ({
        category: category,
        score: manualMarks[index] || 0,
      })),
      total_score: totalManualMarks,
      feedback: data.feedback,
      candidate_status: data.is_selected,
      created_by_id: userID,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_job_id,
        tab_id: formId.id,
        workflow_id: formId.WF,
        user: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    var config = {
      method: "post",
      url: VARIABLES.url + `/api/hrinterview/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        nextLevel();
        getWorkflowSession();
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Interview completed successfully",
        });
        reset();
        // setTimeout(() => {
        //   navigate('/')
        // }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Interview failed",
        });
      });
  };

  useEffect(() => {
    setValue("is_selected", "Draft");
  }, []);

  useEffect(() => {
    getHRInterviewQuestions(job_opening_id);
  }, []);

  useEffect(() => {
    //object to store the count of questions and total scores for each category
    const newCategoryStats = {};
    rowData.forEach((row) => {
      // Extract the category and score from the row
      const category = row.info.category[0];
      const score = parseInt(row.score);

      // If the category already exists in `newCategoryStats`
      // update count and total score
      if (newCategoryStats[category]) {
        newCategoryStats[category].count++;
        newCategoryStats[category].totalScore += score;
      } else {
        newCategoryStats[category] = { count: 1, totalScore: score }; //if not then , initialize it with count 1 and the current score
      }
    });
    setCategoryStats(newCategoryStats); //update the state with new stats
  }, [rowData]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">HR Interview Round</h4>
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      {isQuestionLoading ? (
        <div class="spinner-border text-primary ms-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <>
            {HRInterviewQuestion && HRInterviewQuestion.length !== 0 ? (
              <div className="card">
                <div className="card-inner">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Category name</th>
                          <th>Average of Questions</th>
                          <th>Manual Marks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniqueCategories?.map((category, index) => (
                          <tr key={index}>
                            <td>{category}</td>
                            <td>{calculateAverageMarks(category)}</td>
                            <td>
                              <input
                                type="number"
                                id={`number-forms-manualmarks[${index}]`}
                                className="form-control"
                                {...register(`manualmarks.${index}`)}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) =>
                                  handleManualMarksChange(index, e.target.value)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex justify-end">
                    <label className="w-25 p-2 fw-bold align-end">
                      Total Marks :
                    </label>
                    <div class="form-control w-25 me-4">{totalManualMarks}</div>
                  </div>
                </div>

                <div className="card-inner">
                  <form
                    onSubmit={handleSubmit(onSubmitScores)}
                    autoComplete="off"
                  >
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Question</th>
                            <th>Status</th>
                            <th>Score</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {HRInterviewQuestion?.map((info, index) => (
                            <tr key={index}>
                              <td>{info.question}</td>
                              <td>
                                <div className="nk-tb-col">
                                  <div className="custom-control custom-switch">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customSwitch${index}`}
                                      checked={toggleStatus[index] || false}
                                      onChange={(
                                        e //updates toggle
                                      ) =>
                                        updateToggleStatus(
                                          index,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      id="button-button-status"
                                      htmlFor={`customSwitch${index}`}
                                    ></label>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={`number-forms-score[${index}]`}
                                  {...register(`scores[${index}]`)}
                                  value={rowData[index]?.score || ""}
                                  onChange={(e) =>
                                    handleScoreChange(
                                      index,
                                      e.target.value,
                                      rowData[index]?.comments || "", // Previous comments for the row or empty string
                                      info // info of row
                                    )
                                  }
                                  disabled={!toggleStatus[index]} // Disables input field if toggleStatus for the current index is false
                                />
                              </td>
                              <td>
                                <textarea
                                  className="form-control"
                                  id={`textarea-forms-comments[${index}]`}
                                  {...register(`comments[${index}]`)}
                                  value={rowData[index]?.comments || ""}
                                  onChange={(e) =>
                                    handleScoreChange(
                                      index,
                                      rowData[index]?.score || "", // Previous score for the row or empty string
                                      e.target.value,
                                      info // info of row
                                    )
                                  }
                                  disabled={!toggleStatus[index]} // Disables input field if toggleStatus for the current index is false
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-6 mt-4 mb-4">
                      <div className="form-group">
                        <label className="form-label">Feedback</label>
                        <input
                          type="text"
                          className="form-control"
                          id="text-forms-feedback"
                          placeholder="Enter Feedback"
                          {...register("feedback")}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <ul className="row g-3">
                        {interviewActionDropdownData.map((action, index) => (
                          <li className="col-sm-4" key={index}>
                            <div className="custom-control custom-radio me-3">
                              <input
                                type="radio"
                                id={`checkbox-form-is-selected-HR-${action.value.toLowerCase()}`}
                                className="custom-control-input"
                                value={action.value}
                                {...register("is_selected", { required: true })}
                              />
                              <label
                                className="custom-control-label"
                                for={`checkbox-form-is-selected-HR-${action.value.toLowerCase()}`}
                              >
                                {action.name}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {errors.is_selected && (
                        <span className="fs-8 text-danger">
                          Please select any one
                        </span>
                      )}
                    </div>

                    <div className="col-12 d-flex mt-4">
                      <div className="form-group">
                        {level !== 0 && (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary me-3"
                            id="button-button-prevlevel"
                            onClick={() => prevLevel()}
                          >
                            Prev
                          </button>
                        )}
                      </div>
                      <div className="form-group">
                        {submitLoading ? (
                          <button className="btn text-dark" disabled>
                            Loading...
                          </button>
                        ) : (
                          <>
                            {sequenceList.length - 2 > level ? (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary"
                                id="submit-button-hrinterview"
                              >
                                Next
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary"
                                id="submit-button-hrinterview"
                              >
                                Submit
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div>No Questions found for HR interview round</div>
            )}
          </>
        </div>
      )}
      <Snackbar />
    </>
  );
};

export default HRInterviewRound;
